
.renew-password {
  margin-left: 10px;

  .email {
    padding-bottom: 30px;
  }

  [type=submit] {
    margin-left: 7px;
  }
}
