$grid-gutter-widths: (
        xl: 14px,
        lg: 14px,
        md: 14px,
        sm: 14px,
        xs: 14px
);

$container-max-widths: (
        xl: 1600px
);

$gray-darker: #232323;
$gray: #a7a8a8;
$gray-light: #f2f2f2;
$gray-lighter: #e8e8e8;
$brand-primary: #ed172f; // #006d74;
$gradient: linear-gradient(90deg, #006d75 0%,  #00a7b2 41%,#66d1d9 100%);

$brand-info: #f39d72;
$brand-new: #f5bb41;
$brand-secondary: #f39d72;
$brand-success: #278f16;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;

$label-color: $gray-darker;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;

$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$NotoSansPath: "~notosans-fontface/fonts";
$NotoSansVersion: "1.0.1";

$font-awesome: 'Font Awesome 5 Free';

$font-custom: 'Spartan', sans-serif; //'texgyreadventor Regular', 
$font-family: $font-custom;
$font-custom2: $font-custom;
$font-custom3: 'Open Sans', sans-serif;
$font-custom4: 'Open Sans', sans-serif;
$custom-color: $gradient;
$custom-color2: #212123; // #005157;
$custom-color3: black; // #006d74;
$custom-color4: #49ADE9;
$custom-color5: #d8183d; //search hover
$custom-color6: #ef1e46; //search
$custom-color7: #8a8a8a; //border search
$custom-color8: #e8e8e8;
$custom-color9: #939393; //gray discount
$gray-border: #e2e2e2;
$stock: #4ca47a;
$background-index: #f8f8f8;
$gray-category: #313131;
$base-color: $custom-color2;
$text-color: $custom-color2;
$font-icon: 'material-icons';
$screen-sm: '200px';

$sprites: '';