.top-menu {
    #lnk-oferte-speciale {
        a {
            font-weight: bold;
            color: #ff0000;
        }
    }

    .marca {
        border: 2px solid #595a5c;
        box-shadow: 0 0 5px #929292;
        background: #fff;
        width: 215px;
        height: 51px;
        // margin: -10px 0;
        position: relative;
        z-index: 111;

        @include media-breakpoint-down(md) {
            width: 145px;
            padding: 0 5px;
        }

        @include media-breakpoint-down(xs) {
            margin: 10px auto 0;
        }

        a {
            display: block;
            //font: 300 17px $font-custom;
            //color: #595a5c;
            text-align: center;
            padding: 1px 0;

            @include media-breakpoint-down(md) {
                //font-size: 14px;
                // padding: 13px 0;
            }

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }

    &[data-depth="1"] {
        margin: 0;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .all-categories {
            font-weight: 500;
        }

        >li {
            padding: 0 10px;

            @include media-breakpoint-down(xs) {
                padding: 0;
            }

            a {
                /*
        border-bottom: 1px solid #dddddd;
        */
                text-transform: none;
                font: 500 16px $font-custom2;
                padding: 5px 10px;
                color: #222222;
                display: flex;
                white-space: normal;

                @include media-breakpoint-down(sm) {
                    font: lighter 14px $font-custom2;
                }

                &[data-depth="2"] {
                    color: $custom-color2;
                    /*
          font-weight: bold;
          padding: 0;
          font-size: 13px;
          line-height: 18px;
          */
                    font-weight: 400;
                    font-size: 13px;
                    text-transform: none;
                    padding: 5px 0;
                    width: auto;

                    &:hover {
                        color: $custom-color5;
                    }
                }

                &[data-depth="3"] {
                    line-height: 16px;
                    font-size: 12px !important;

                    &:hover {
                        color: $custom-color5;
                    }
                }

                &[data-depth="4"],
                &[data-depth="5"] {
                    display: none;
                }

                &:hover {
                    color: $custom-color5;
                }
            }

            >a {
                .category-thumb {

                    /*
          min-width: 26px;
          height: 27px;
          */
                    // min-width: 60px;
                    // height: 60px;
                    // display: inline-flex;
                    // align-items: center;
                    // justify-content: center;
                    // content: " ";
                    // margin-right: 5px;
                    img {
                        // display: block;
                        display: none;
                        margin: 0 auto;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            >.collapse {

                /*
        @include media-breakpoint-only(xl) {
          width: 334%;
        }
        @include media-breakpoint-only(lg) {
          width: 308%;
        }
        @include media-breakpoint-only(md) {
          width: 221%;
        }
        */
                @include media-breakpoint-up(md) {
                    /*
          position: absolute;
          left: 288px;
          top: 0;
          background: #f3f3f3;
          */
                    display: block;
                    padding: 0 10px;
                    min-height: 100%;

                    //width: calc(100% - 306px);
                    &.js-sub-sub-menu {
                        display: none;
                    }

                    >ul {
                        >li {
                            /*
              width: 33%;
              */
                            display: block;
                            vertical-align: top;
                            border-bottom: none;

                            >a {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }

    a.dropdown-submenu {
        color: $gray-darker;
        text-transform: uppercase;
        font-weight: 600;
    }

    a[data-depth="0"] {
        font: 600 11px $font-custom;
        padding: 3px 0px;
        text-transform: initial;
        margin: 0;
        white-space: normal;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (max-width: 1240px) {
            font: 600 10px $font-custom;
            padding: 0;
        }

        // @media (max-width: 1269px){
        //   font: normal 13px $font-custom;
        // }
        // @include media-breakpoint-down(lg) {
        //   font: normal 12px $font-custom;      
        //   padding: 4px;
        // }
        // @media (max-width: 986px){
        //   font: normal 12px $font-custom;
        // }
        // @media (max-width: 924px){
        //   font: normal 11px $font-custom;
        // }
        // @media (max-width: 863px){
        //   font: normal 10px $font-custom;
        // }
        // @media (max-width: 802px){
        //   font: normal 9px $font-custom;
        // }
        @include media-breakpoint-down(sm) {
            border: none;

            &:hover {
                border: none;
            }
        }

        /* Mega menu level 0 hover */
        &:hover {
            // background-color: white;
            color: black;

            // .top-menu[data-depth="1"]{
            //   background-color: white;
            //   color: black;
            // }
            /*
      padding: 10px 13px 10px 13px;
      margin: 4px 0 4px 0;
      border-left:4px solid black;
      border-right:4px solid black;
      */
            #header & {
                color: $custom-color;
                text-decoration: none;
            }
        }

        position: relative;

        &::after {
            content: '';
            // background-image: url('../img/white-border.png');
            // background-image: url('../img/black-border.png');
            background-repeat: no-repeat;
            display: inline-block;
            width: 1px;
            height: 25px;
            position: absolute;
            top: 6px;
            right: 0px;

            @media (max-width: 803px) {
                top: 4px;
            }
        }

        @include media-breakpoint-down(sm) {
            &::after {
                content: '';
                background-image: unset;
            }
        }
    }

    .sub-menu {
        &.collapse {
            display: none;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        border: none;
        z-index: 18;
        display: none;

        /* Megamenu category block */
        ul[data-depth="1"]>li {
            display: block;
            width: 25%;
            float: left;
            // min-height: 100px;
            padding: 10px;

            &[data-depth="1"] {
                &.has-child {
                    >a::after {
                        content: "\25be";
                        margin-left: 10px;
                        font-size: 1.25rem;
                        margin-top: -3px;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }
            }

            &:hover>a {
                color: $custom-color5;
            }

            a[data-depth="1"] {
                font: 500 14px $font-custom;
                padding: 0px 9px 0px 15px !important;

                @include media-breakpoint-down(sm) {
                    color: #000;
                }
            }

            .top-menu[data-depth="2"]>li a[data-depth="2"] {
                padding: 1px 0;

                &:hover {
                    color: $custom-color2;
                }

                &::before {
                    content: ">";
                    margin-right: 2px;
                }
            }

            @media (max-width: 940px) {
                width: 50% !important;
            }

            @include media-breakpoint-down(sm) {
                width: 100% !important;
                padding: 5px 0px;
                min-height: 10px;
            }
        }

        ul[data-depth="3"] {
            padding-bottom: 15px;

            a {
                border-bottom: 0;
                font-size: 14px;
                padding: 3px 0;
            }
        }
    }

    .popover {
        max-width: inherit;
        border-radius: 0 0 7px 7px;
        background: white;
        // border:4px solid black;
        border: 1px solid $gray-border;
        // border-top:none;
        width: 100%;

        // &:after{
        //   height: 10px;
        //   width: 10px;
        //   position: absolute;
        //   content: '';
        //   background: #fff;
        //   border: 1px solid $gray-border;
        //   border-top-width: 0px;
        //   border-left-width: 0px;
        //   transform: rotate( 225deg);
        //   // left: calc(50% - 4px);
        //   left: 50px;
        //   top: -6px;
        // }
        @include media-breakpoint-down(sm) {
            border: none;
            // border-bottom: 1px solid black;
            // border-radius: 0;
            // background: white;   
        }
    }
}

// .sticky-menu{
//   display: inline-block !important;
//   cursor: pointer;
//   color: $gray-darker;
//   align-self: center;
//   margin-right: 10px;
//   &:hover {
//       color: darken(#fff, 20%);
//   }
// }
.header-top .open-menu,
.header-top .close-menu {
    display: none;
    cursor: pointer;
    color: $gray-darker;
    align-self: center;
    margin-right: 5px;
    color: #000;
    font-size: 35px;
    opacity: 1;

    &:hover {
        color: darken(#fff, 20%);
    }
}

.header-top .open-menu,
.header-top .close-menu {
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

#open-vertical-menu,
#open-vertical-menu {}

.header-top.fixed #open-vertical-menu {
    display: inline-block;

    @include media-breakpoint-down(md) {
        // margin-top: 10px;
    }
}

.header-top.fixed #open-vertical-menu,
.header-top.fixed #close-vertical-menu {
    @include media-breakpoint-down(md) {
        // margin-top: 10px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0px;
    }
}

.popover.bs-tether-element-attached-top {
    margin-top: 0;
}

// Mobile sticky menu
.header-top.fixed {
    #mobile_top_menu_wrapper {
        @media (max-width: 340px) {
            top: 43px;
        }
    }
}

#mobile_top_menu_wrapper {
    margin-top: 0;
    padding-bottom: 0.625rem;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 11;
    /*
  top: 80px;
  */
    top: 63px;
    display: none;
    height: calc(100vh - 65px);
    overflow: scroll;
    position: fixed;

    &:before {
        height: 1px;
        display: block;
        width: 100%;
        content: " ";
        background: $custom-color2;
        position: fixed;
        /*
    top: 80px;
    */
        top: 64px;
    }

    #top-menu {
        margin-bottom: 0;
        padding-top: 0;

        a {
            display: block;
        }

        >li {
            >a {
                span {
                    // display: none;
                }
            }

            >div {
                // display: block;
            }
        }
    }

    .top-menu {
        color: $gray-darker;

        .collapse-icons[aria-expanded="true"] {
            .add {
                display: none;
            }

            .remove {
                display: block;
            }
        }

        .collapse-icons .remove {
            display: none;
        }

        .navbar-toggler {
            display: inline-block;
            padding: 0;
            height: unset;
        }

        li[data-depth="0"] {
            background-color: #f9f9f9;
            text-transform: uppercase;
            border-bottom: 1px solid #d7d7d7;
            padding: 10px 0 10px 0;

            a {
                padding: 0 10px;
            }
        }

        li {
            a {
                .height-icon {
                    height: 24px;
                }
            }
        }

        a[data-depth="0"] {
            background: white !important;
            font: 300 12px/25px $font-custom;
            padding: 4px 10px;

            @include media-breakpoint-down(sm) {
                font: 400 14px $font-custom;
                background: #F9F9F9 !important;
                text-align: left;
            }
        }

        .collapse {
            display: block;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &.in {
                display: block;
            }
        }

        .sub-menu {
            &.collapse {
                display: none;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                &.in {
                    display: block;
                }
            }

            box-shadow: none;
            z-index: inherit;
            display: block;
            position: static;
            overflow: hidden;
            margin-left: 0;
            width: 100%;
            min-width: 100%;
            background: $gray-lighter;

            ul[data-depth="0"]>li {
                border-bottom: 1px solid $gray;
            }

            ul[data-depth="1"] {
                margin: 0;

                >li {
                    /*
          float: none;
          margin: 0;
          */
                    width: 33%;
                    float: left;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }

                    a {
                        text-transform: none;
                    }
                }

                @include media-breakpoint-down(sm) {
                    // border-top: 1px solid black;
                }
            }

            ul {
                padding: 0;
            }

            li>a {
                padding: 1px;
                /*
        border-bottom: 1px solid white;
        */
            }

            ul[data-depth="2"] li a {
                padding-left: 1.25rem;
                font-weight: 400;

                @include media-breakpoint-down(sm) {
                    padding-left: 16px;
                }

                &:hover {
                    color: $custom-color5;
                }
            }

            ul[data-depth="2"] li {
                padding-top: 4px;

                &:last-child {
                    padding-bottom: 5px;
                }
            }

            ul[data-depth="3"] li a {
                padding-left: 2.5rem;
            }

            ul[data-depth="4"] li a {
                padding-left: 3.75rem;
            }
        }
    }

    .js-top-menu-bottom {
        a {
            color: $gray;
        }

        .language-selector-wrapper {
            padding: 0.625rem;

            .language-selector {
                display: inline;
            }
        }

        .currency-selector {
            padding: 0.625rem;
        }

        #contact-link {
            padding: 0.625rem;
        }

        .user-info {
            padding: 0 0.625rem;

            a {
                padding: 0.625rem 0;
                display: block;
                width: 100%;
            }
        }
    }
}