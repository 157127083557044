.cart-grid {
    margin: 2rem 0 1rem;
}

.cart-items {
    margin-bottom: 0;
}

.cart-item {
    padding: 1rem 0;
    .js-cart-line-product-quantity {
        width: 4rem !important;
        @include media-breakpoint-down(sm){
            width: 2.7rem !important;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.cart-summary-line {
    @include clearfix;
    clear: both;
    .label {
        padding-left: 0;
        font-weight: 300;
        white-space: inherit;
    }
    .value {
        color: $gray-darker;
        float: right;
    }
    &.cart-summary-subtotals {
        .label,
        .value {
            font-weight: normal;
        }
    }
}


/** CART BODY **/

.cart-grid-body {
    a.label {
        &:hover {
            color: $custom-color2;
        }
    }
    .card-block {
        padding: 1rem;
        h1 {
            margin-bottom: 0;
        }
    }
    .cart-overview {
        padding: 1rem;
    }
    margin-bottom: 0.75rem;
}


/** CART RIGHT **/

.cart-grid-right {
    .promo-discounts {
        margin-bottom: 0;
        .cart-summary-line {
            .label {
                color: $gray;
                .code {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.block-promo {
    .promo-code {
        padding: 1.6rem;
        background: $gray-light;
        .alert-danger {
            position: relative;
            margin-top: 1.25rem;
            background: $brand-danger;
            color: white;
            display: none;
            &::after {
                bottom: 100%;
                left: 10%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: $brand-danger;
                border-width: 10px;
                margin-left: -10px;
            }
        }
    }
    .promo-input {
        color: $gray-darker;
        border: $gray 1px solid;
        height: 40px;
        text-indent: 5px;
        width: calc(100% - 90px);
        +button {
            text-transform: capitalize;
            height: 40px;
            padding: 0.5rem 0.5rem;
            width: 85px;
            text-align: center;
        }
    }
    .cart-summary-line .label,
    .promo-name {
        color: $brand-warning;
        font-weight: 600;
        a {
            font-weight: normal;
            color: $gray-darker;
            display: inline-block;
        }
    }
    .promo-code-button {
        padding-left: 1.25rem;
        margin-bottom: 1.25rem;
        display: inline-block;
    }
    &.promo-highlighted {
        padding: 1.25rem;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}


/** CONTENT LEFT **/

.product-line-grid-left {
    img {
        max-width: 100%;
    }
}


/** CONTENT BODY **/

.product-line-grid-body {
    >.product-line-info {
        .product-title {
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 0.5rem;
            display: block;
        }
        .current-price {
            color: #222;
            font: 500 18px $font-custom2;
        }
        >.label {
            padding: 0;
            line-height: inherit;
            text-align: left;
            white-space: inherit;
        }
        >.out-of-stock {
            color: red;
        }
        >.available {
            color: $brand-success;
        }
        >.unit-price-cart {
            padding-left: 0.3125rem;
            font-size: 0.875rem;
            color: $gray;
        }
        .product-discount {
            margin-left: 10px;
            font-size: 14px;
        }
        &.custom-attributes {
            font-size: 14px;
            font-weight: 300;
        }
    }
}


/** CONTENT LEFT **/

.product-line-grid-right {
    .cart-line-product-actions,
    .product-price {
        color: $gray-darker;
        line-height: 36px;
        .remove-from-cart {
            color: $gray-darker;
            display: inline-block;
            margin-top: 0.3125rem;
        }
    }
}


/*** Responsive part ***/

@media (max-width: 600px) {
    .product-line-grid {
        position: relative;
        &.row-eq-height {
            flex-wrap: wrap;
            justify-content: space-between;
            display: flex;
        }
    }
    .product-line-grid-right {
        display: inline-block;
        width: 100%;
        float: none;
        flex-basis: 100%;
        margin-top: 20px;
        position: static;
        //.cart-line-product-actions {
        //  i {
        //    font-size: 16px;
        //  }
        //}
    }
}

@include media-breakpoint-down(xs) {
    .cart-items {
        padding: 1rem 0;
    }
    .cart-item {
        border-bottom: 1px solid $gray-light;
        &:last-child {
            border-bottom: 0;
        }
    }
    .cart-grid-body {
        .cart-overview {
            padding: 0;
        }
        .no-items {
            padding: 1rem;
            display: inline-block;
        }
    }
    .product-line-grid-left {
        padding-right: 0 !important;
    }
}

.cart-padd-summary-products,
.fav-summary-products {
    // max-height: 250px;
    overflow: hidden;
    padding: 15px 0 15px 15px;
    &.no-products {
        color: #000;
        font: bold 14px/16px $font-custom;
        text-align: left;
        background: #fff;
    }
    .nano {
        height: 145px;
        position: relative;
    }
    .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
    }
    .nano>.nano-pane {
        background: #cbffd8;
        position: absolute;
        width: 7px;
        right: 7px;
        top: 0;
        bottom: 0;
        opacity: 0.4;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        display: block;
    }
    .nano>.nano-pane>.nano-slider {
        background: $custom-color2;
        opacity: 0.8;
        position: relative;
        margin: 0 1px;
        @include border-radius(3px);
        display: block;
    }
    table {
        width: calc(100% - 15px);
        color: #32394d;
        text-align: left;
        padding: 10px;
        th {
            font: bold 10px/12px $font-custom;
            color: #909095;
            text-transform: uppercase;
            padding-bottom: 10px;
        }
        td {
            font: bold 11px/13px $font-custom;
            border-bottom: 1px solid #ddd;
            padding: 5px 0;
            &.product-image {
                width: 50px;
                img {
                    width: 45px;
                }
            }
            &.product-name {
                overflow: hidden;
                width: 110px;
                div,
                >a {
                    max-width: 110px;
                    white-space: pre-wrap;
                    /* css-3 */
                    white-space: -moz-pre-wrap;
                    /* Mozilla, since 1999 */
                    white-space: -pre-wrap;
                    /* Opera 4-6 */
                    white-space: -o-pre-wrap;
                    /* Opera 7 */
                    word-wrap: break-word;
                    /* Internet Explorer 5.5+ */
                    font: 400 11px/13px $font-custom !important;
                    color: #32394d !important;
                }
            }
            &.cart-quantity {
                padding: 5px;
            }
        }
    }
}

.cart-expanded,
.fav-expanded {
    position: absolute;
    right: 0;
    top: 30px;
    width: 300px;
    z-index: 99;
    display: none;
    .arrow-up {
        width: 100%;
        margin-top: -17px;
        &:before {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 10px solid #fff;
            content: " ";
            display: block;
            top: 0;
            right: 42px;
            position: absolute;
            @include media-breakpoint-down(md) {
                right: 0;
            }
        }
    }
    .my-cart-padd,
    .my-fav-padd {
        margin-top: 26px;
        background: white;
        @include border-radius(8px);
        @include box-shadow;
        a {
            color: #fff !important;
            font: 400 12px/14px $font-custom !important;
            &:after {
                display: none !important;
            }
        }
        .btn {
            @include border-radius(8px);
        }
    }
    .my-fav-padd {
        #button-fav {
            padding: 0px 15px 7px 15px;
            .btn {
                padding: 0.5rem 1rem;
            }
        }
    }
    .cart-total {
        background: #eff1f7;
        padding: 15px;
        text-align: left;
        p {
            margin-bottom: 0.4rem;
        }
        .label {
            text-transform: uppercase;
            font: 900 12px/14px $font-custom;
            float: left;
            &.totals {
                color: #32394d;
            }
        }
        .value {
            color: #32394d;
            font: 900 12px/14px $font-custom;
            &.totals {
                color: #086cf4;
            }
            &.shipping {
                text-align: left;
            }
        }
        .btn {
            width: 100%;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}

.cart-expanded {
    .arrow-up {
        &:before {
            right: 16px !important;
        }
    }
}

.fav-expanded {
    right: 100px;
    @include media-breakpoint-down(md) {
        right: 0;
    }
    .arrow-up {
        &:before {
            left: 130px;
            right: auto;
            @include media-breakpoint-down(md) {
                left: 174px;
            }
        }
    }
    table td.product-name>a {
        max-width: 100%;
    }
}

.account-expanded {
    position: absolute;
    left: 0;
    top: 25px;
    width: 220px;
    z-index: 99;
    display: none;
    .arrow-up {
        width: 100%;
        margin-top: -17px;
        &:before {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 10px solid #fff;
            content: " ";
            display: block;
            top: 0;
            left: 66px;
            position: absolute;
        }
    }
    .my-account-padd {
        margin-top: 26px;
        background: white;
        @include border-radius(8px);
        @include box-shadow;
        ul {
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;
            li {
                a {
                    text-align: left;
                    display: block;
                    padding: 7px 15px;
                    font: 300 17px/24px $font-custom;
                    text-transform: uppercase;
                    &:hover {
                        color: $custom-color2 !important;
                    }
                    i {
                        width: 25px;
                        text-align: center;
                        display: none;
                    }
                }
            }
        }
    }
    .account-button {
        padding: 0px 15px 7px 15px;
        .btn {
            color: #fff !important;
            // background: $custom-color2;
            padding: 0.5rem 1rem;
            font: 400 12px/14px $font-custom !important;
            border: none;
        }
    }
}

#_desktop_user_info {
    &:hover {
        .account-expanded {
            display: block;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}

.cart-expanded {
    .my-cart-padd {
        p {
            display: none;
        }
        .cart-total {
            div a {
                padding: 0.5rem 1rem;
            }
        }
    }
    .nano>.nano-pane>.nano-slider {
        background: $custom-color2;
    }
    .nano>.nano-pane {
        background: #ddd;
    }
}
#cart, #checkout {
    #free_shipping_alert{
        padding: 25px 0 0 0;
        h4{
            color: $brand-primary;
            font-size: 15px;
            line-height: 1.7;
        }
    }
}