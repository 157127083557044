.extracontent{
  margin: 0;
  box-shadow: 0px 3px 11px 6px rgba(0,0,0,0.06);
  margin-top: 37px;
}
#block-reassurance {
  margin-top: 2rem;
  img {
    width: 1.563rem;
    margin-right: 0.625rem;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  //li .block-reassurance-item{
  //  padding: 1rem 1.5rem;
  //}
  #product & {
    span {
      font: 500 14px $font-custom2;
      color: #313131;
    }
  }
}
#cart,
#checkout {
  #block-reassurance {
    ul {
      display: block;
      li {
        display: block;
        padding: 10px 0;
      }
    }
  }
  .regular-price{
    font: 500 18px $font-family;
  }
}

.blockreassurance {
  // margin: 35px 0;
  @include media-breakpoint-down(sm){
    margin-bottom: 0;
    padding-top: 0;
  }
  .row {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }
  }
  .block {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    flex-grow: 4;
    position: relative;
    // border-left: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:575px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    &:first-child {
      border-left: none;
    }
    .block-content {
      display: none;
      position: absolute;
      left: 15px;
      top: 100%;
      right: 15px;
      background-color: #fff;
      text-align: left;
      -webkit-box-shadow: 0px -8px 5px 0px #efefef;
      -moz-box-shadow: 0px -8px 5px 0px #efefef;
      box-shadow: 0px -8px 5px 0px #efefef;
      padding: 10px;
      z-index: 2;
      p {
        font: 400 13px/19px $font-custom2;
        margin-bottom: 0;
      }
      &:before {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 12px solid #fff;
        content: " ";
        display: block;
        top: -12px;
        left: calc(50% - 15px);
        position: absolute;
      }
      .link {
        font: 400 10px/12px $font-custom2;
        color: #006d75;
      }
    }
    &:hover {
      .block-content {
        display: block;
      }
    }
  }
  .block-icon {
    background-color: $gray-light;
    border-radius: 50%;
    width: 78px;
    height: 78px !important;
    display: flex !important;
    margin-right: 25px;
    @media (max-width: 1300px){
      margin-right: 1px;
    }
    img{
      height: unset !important;
      margin: auto;
    }
  }
  .block-title,
  .block-icon {
    display: inline-block;
    vertical-align: middle;
  }
  @media (max-width:1060px){
    .block-icon {
      display: block;
      min-height: 45px;
    }
  }
  .block-icon{
    @media (max-width:1060px){
        display: block;
        min-height: 45px;
    }
    @media (max-width:575px) {
      background-color: unset;
      margin: 0 !important;
    }
  }
  .block-title {
    margin-left: 8px;
    font: 600 13px $font-custom2;
    color: #212123;
    @include media-breakpoint-down(lg){
      display: none;
    }
    @media (max-width:575px) {
      display: none !important;
    }
  }
}

@include media-breakpoint-down(md) {
  #block-reassurance {
    margin-top: 15px;
    ul {
      display: inline-block;
      margin-bottom: 0;
      li {
        display: block;
        padding: 10px 0;
      }
    }
  }
}
