
.forgotten-password {
  padding: 4px;
  .form-fields {
    .center-email-fields {
      display: flex;
      justify-content: center;
      @media (max-width: 520px) {
        display: block;
        button{
          margin: 0 auto;
          display: block;
        }
      }
    }
    .email {
      padding-left: 0;
      padding-right: 0;
      width: 430px;
      @media (max-width: 520px) {
        width: 100%;
      }
      input {
        height: 38px;
        @media (max-width: 520px) {
          margin-bottom: 10px;
        }
      }
    }
    label.required {
      width: 130px;
      @media (max-width: 520px) {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.send-renew-password-link {
  padding-left: 10px;
  padding-right: 10px;
}
