.product-page-info{
    .slick-slider{
        border-right: unset;
        
        .slick-track{
            border: unset !important;
        }
    }
}
.slick-slider{
    border-right: 1px solid $gray-border;
    .slick-track{
        border: 1px solid $gray-border;
        .slick-slide{
            padding: 0;
        }
        .product{
            background-color: #fff;
        }
    }
}
#products .product-thumbnail img,
 .featured-products .product-thumbnail img,
  .product-miniature .product-thumbnail img{
    border-radius: 0;
    padding: 7px 22px 0 22px;
}

article.product-miniature{
    .product-flags{
        position: absolute;
        top: 9px;
        left: 0;
        width: 100%;
        display: flex;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 41px;
            margin-top: 2px;
        }
        .product-flag.discount{
            span{
                background-image: url('../img/discount-ellipse.png');
                background-repeat: no-repeat;
                // width: 51px;
                width: 41px;
                height: 41px;
                color: #fff;
                font: 400 11px $font-family;
                margin-left: 9px;
            }
        }
        .product-flag.new{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            span{
                background-image: url('../img/new-ellipse.png');
                background-repeat: no-repeat;
                width: 41px;
                height: 41px;
                color: #fff;
                text-transform: uppercase;
                font: 400 11px $font-family;
                margin-right: 9px;
            }
        }
        .product-flag.pack{
            display: none;
        }
        
        .product-flag.out_of_stock{
            display: none;
        }
    }
}