@font-face {
  font-family: 'Noto Sans';
  src: url(~notosans-fontface/fonts/NotoSans-Regular.eot);
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
    url(~notosans-fontface/fonts/NotoSans-Regular.eot) format('embedded-opentype'),
    url(~notosans-fontface/fonts/NotoSans-Regular.woff2) format('woff2'),
    url(~notosans-fontface/fonts/NotoSans-Regular.woff) format('woff');
    //url(~notosans-fontface/fonts/NotoSans-Regular.ttf) format('truetype'), url(~notosans-fontface/fonts/NotoSans-Regular.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(~notosans-fontface/fonts/NotoSans-Bold.eot);
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url(~notosans-fontface/fonts/NotoSans-Bold.eot) format('embedded-opentype'),
    url(~notosans-fontface/fonts/NotoSans-Bold.woff2) format('woff2'),
    url(~notosans-fontface/fonts/NotoSans-Bold.woff) format('woff');
    //url(~notosans-fontface/fonts/NotoSans-Bold.ttf) format('truetype'), url(~notosans-fontface/fonts/NotoSans-Bold.svg) format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(~notosans-fontface/fonts/NotoSans-Italic.eot);
  src: local('Noto Sans Italic'), local('NotoSans-Italic'),
    url(~notosans-fontface/fonts/NotoSans-Italic.eot) format('embedded-opentype'),
    url(~notosans-fontface/fonts/NotoSans-Italic.woff2) format('woff2'),
    url(~notosans-fontface/fonts/NotoSans-Italic.woff) format('woff');
    //url(~notosans-fontface/fonts/NotoSans-Italic.ttf) format('truetype'), url(~notosans-fontface/fonts/NotoSans-Italic.svg) format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(~notosans-fontface/fonts/NotoSans-BoldItalic.eot);
  src: local('Noto Sans BoldItalic'), local('NotoSans-BoldItalic'),
    url(~notosans-fontface/fonts/NotoSans-BoldItalic.eot) format('embedded-opentype'),
    url(~notosans-fontface/fonts/NotoSans-BoldItalic.woff2) format('woff2'),
    url(~notosans-fontface/fonts/NotoSans-BoldItalic.woff) format('woff');
    //url(~notosans-fontface/fonts/NotoSans-BoldItalic.ttf) format('truetype'), url(~notosans-fontface/fonts/NotoSans-BoldItalic.svg) format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff');
    //url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

//fonturi
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url(./font/Lato-Light.eot);
  src: local("Lato Light"), local("Lato-Light"), 
    url(./font/Lato-Light.eot) format("embedded-opentype"),
    url(./font/Lato-Light.woff2) format("woff2"), 
    url(./font/Lato-Light.woff) format("woff");
    //url(./font/Lato-Light.ttf) format("truetype"), url(./font/Lato-Light.svg) format("svg");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(./font/Lato-Regular.eot);
  src: local("Lato Regular"), local("Lato-Regular"), 
    url(./font/Lato-Regular.eot) format("embedded-opentype"),
    url(./font/Lato-Regular.woff2) format("woff2"), 
    url(./font/Lato-Regular.woff) format("woff");
    //url(./font/Lato-Regular.ttf) format("truetype"), url(./font/Lato-Regular.svg) format("svg");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url(./font/Lato-Bold.eot);
  src: local("Lato Bold"), local("Lato-Bold"), 
    url(./font/Lato-Bold.eot) format("embedded-opentype"),
    url(./font/Lato-Bold.woff2) format("woff2"), 
    url(./font/Lato-Bold.woff) format("woff");
    //url(./font/Lato-Bold.ttf) format("truetype"), url(./font/Lato-Bold.svg) format("svg");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url(./font/Lato-Black.eot);
  src: local("Lato Black"), local("Lato-Black"), 
    url(./font/Lato-Black.eot) format("embedded-opentype"),
    url(./font/Lato-Black.woff2) format("woff2"), 
    url(./font/Lato-Black.woff) format("woff");
    //url(./font/Lato-Black.ttf) format("truetype"), url(./font/Lato-Black.svg) format("svg");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  src: url(./font/NunitoSans-ExtraLight.eot);
  src: local("Nunito Sans ExtraLight"), local("NunitoSans-ExtraLight"),
    url(./font/NunitoSans-ExtraLight.eot) format("embedded-opentype"),
    url(./font/NunitoSans-ExtraLight.woff2) format("woff2"), 
    url(./font/NunitoSans-ExtraLight.woff) format("woff");
    //url(./font/NunitoSans-ExtraLight.ttf) format("truetype"), url(./font/NunitoSans-ExtraLight.svg) format("svg");
}

@font-face {
  font-family: "Cookie Regular";
  font-style: normal;
  font-weight: 400;
  src: url(./font/Cookie-Regular.eot);
  src: local("Cookie Regular"), local("Cookie-Regular"), 
    url(./font/Cookie-Regular.eot) format("embedded-opentype"),
    url(./font/Cookie-Regular.woff2) format("woff2"), 
    url(./font/Cookie-Regular.woff) format("woff");
    //url(./font/Cookie-Regular.ttf) format("truetype"), url(./font/Cookie-Regular.svg) format("svg");
}

@font-face {
  font-family: "texgyreadventor Regular";
  font-style: normal;
  font-weight: 400;
  src: url(./font/texgyreadventor-regular-webfont.eot);
  src: local("texgyreadventor Regular"), local("texgyreadventor-Regular"), 
    url(./font/texgyreadventor-regular-webfont.eot) format("embedded-opentype"),
    url(./font/texgyreadventor-regular-webfont.woff) format("woff");    
}

@font-face {
  font-family: "texgyreadventor Bold";
  font-style: bold;
  font-weight: 600;
  src: url(./font/texgyreadventor-bolditalic-webfont.eot);
  src: local("texgyreadventor Bold"), local("texgyreadventor-Bold"), 
    url(./font/texgyreadventor-bolditalic-webfont.eot) format("embedded-opentype"),
    url(./font/texgyreadventor-bolditalic-webfont.woff) format("woff");    
}