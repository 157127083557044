// .comments_note {
//     margin-bottom: 5px;
// }

/* pop-in add grade/advice ********************************************************************* */
.post-product-comment{
    &:hover{
        background-color: #000 !important;
    }
    &:focus, &:focus-visible{
        outline: unset;
    }
}
.grade-stars .star-content{
    top: 0 !important;
}

#post-product-comment-modal{
    #post-product-comment-form{
        .product-flags{
            margin: 0;
        }
    }
    #criterions_list{
        .star-content{
            margin: 0;
        }
    }
    .post-comment-buttons{
        .btn:hover{
            background-color: #000 !important;
        }
    }

}
.post-comment-buttons{
    .btn:hover{
        background-color: #000 !important;
    }
}
#product-comments-list-footer, #empty-product-comment{
    .post-product-comment{
        border-radius: 50px;
    }
}
#fancybox-wrap {
    width: 585px;
}

#fancybox-content {
    width: 585px;
    border-width: 0;
}

#new_comment_form {
    /*width: 585px;*/
    color: #333;
    text-align: left;
}

#new_comment_form .title {
    padding: 10px;
    font-size: 13px;
    text-transform: uppercase;
    background: #333;
}

#new_comment_form ul.grade_content {
    list-style-type: none;
}

#new_comment_form .grade_content li {
    width: 50%;
}

#new_comment_form .product {
    padding: 15px;
}

#new_comment_form .product img {
    float: left;
    border: 1px solid #ccc;
}

#new_comment_form .product .product_desc {
    float: left;
    margin-left: 15px;
    width: 300px;
    line-height: 18px;
    color: #666;
}

#new_comment_form .product .product_desc .product_name {
    padding-bottom: 5px;
    font-size: 13px;
    color: #000;
}

#new_comment_form .grade_content {
    margin: 0 0 20px 0;
}

#new_comment_form .grade_content span,
#new_comment_form .grade_content span {
    display: inline-block;
    padding: 0 10px;
    width: 150px; /* 160 */
    font-weight: bold;
}

#new_comment_form .grade_content .cancel {
    margin-right: 5px;
}

.new_comment_form_content {
    /* padding: 15px; */
    /* background: #f6f6f6 */
}

.new_comment_form_content .intro_form {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
}

#new_comment_form label {
    display: block;
    margin: 0px 0 4px 0;
    font: 400 18px $font-family;
    color: $brand-primary;
    text-align: left;
    /* padding-left: 10px; */
}
.comments_note {
    line-height: 0;
    .reviewCount{
        font: 400 20px $font-custom3;
        color: #878787;
    }
    > div {
        cursor: pointer;
        width: auto;
        margin: 3px auto 0;
        display: inline-block;

        vertical-align: sub;
        margin-right: 5px;
    }
}

#new_comment_form input {
    padding: 5px 15px;
    /*height: 28px;*/
    height: 40px;
    width: 100%;
    //border:none;
    border: 1px solid $gray-light;
    //border-bottom: 1px solid #cbc9c9;
    margin-bottom: 15px;
    font: 400 14px $font-family;
    color: #545454;
    background-color: #fff;
}

#new_comment_form input:focus {
    outline: none;
}

#new_comment_form textarea {
    padding: 5px 15px;
    height: 80px;
    width: 100%;
    max-width: 100% !important;
    border: 1px solid $gray-light;
    background-color: transparent;
    font-size: 14px;
    background-color: #fff;
}

#new_comment_form textarea:focus {
    outline: none;
}

#new_comment_form .submit {
    margin-top: 20px;
    padding: 0;
    font-size: 13px;
    text-align: right;
}

#new_comment_form #criterions_list {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-top: 6px;
}

#new_comment_form #criterions_list .star_content {
    padding-top: 4px;
}

/* #new_comment_form #criterions_list li {
    margin-bottom: 10px;
} */

#new_comment_form #criterions_list label {
    display: inline;
    float: left;
    margin: 6px 10px 0 60px;
    text-align: right;
    min-width: 150px;
}

div.star,
#productCommentsBlock div.star {
    display: inline-block;
    float: left;
    width: 15px;
    height: 15px;
}

div.star_on,
#productCommentsBlock div.star_hover,
#productCommentsBlock div.star_on {
    background: url(../img/star_on.png) no-repeat scroll 0 0 transparent !important;
}

div.star,
#productCommentsBlock div.star,
#productCommentsBlock div.star_on {
    background: url(../img/star-no.png) no-repeat scroll 0 0 transparent;
}

#new_comment_form div.star {
    display: inline-block;
    float: left;
    width: 18px;
    height: 18px;
}

#new_comment_form div.star_hover,
#new_comment_form div.star_on {
    background: url(../img/star_on.png) no-repeat scroll 0 0 transparent !important;
}

#new_comment_form div.star,
#new_comment_form div.star_on {
    background: url(../img/star-no.png) no-repeat scroll 0 0 transparent;
}

#new_comment_form,
#new_comment_form_footer {
    font-size: 14px;
    text-align: center;
    padding-right: 0px !important;
}

#new_comment_form_footer button {
    /* margin-top:9px; */
}

#new_comment_form_footer button:focus {
    outline: none;
}

#new_comment_form div.star a,
#productCommentsBlock .star a {
    display: none;
}

#new_comment_form #new_comment_form_error {
    font: 400 16px $font-family;
    color: red;
    overflow: hidden;
    clear: both;
}

#comment_title {
    margin-bottom: 0 !important;
}

/* TAB COMMENTS ******************************************************************************** */
#product_comments_block_tab {
    /* margin: 0 0 20px 0; */
    /* background-color: #f5f5f5; */
    /* padding-left: 20px;  */
    /* padding-top: 10px; */
}

#product_comments_block_tab div.comment {
    /* margin: 0 0 10px 0; */
    padding: 0 0 7px;
    border-bottom: 1px dotted #cbc9c9;
}

#product_comments_block_tab div.comment div.comment_author {
    float: left;
    padding-right: 25px;
    width: 240px;
    line-height: 18px;
}

#product_comments_block_tab .comment_author span {
    margin-top: 6px;
}

#product_comments_block_tab div.comment div.comment_author span {
    font-weight: bold;
}

#product_comments_block_tab div.comment div.comment_author span,
#product_comments_block_tab div.comment .star_content {
    float: left;
}

#product_comments_block_tab div.comment .star_content {
    margin: 0;
    display: inline-block;
    width: 100%;
}

#product_comments_block_tab .comment_author_infos {
    clear: both;
    padding-top: 10px;
    /* padding-left: 10px; */
    font: 400 16px $font-family;
    color: #7d7b7b;
}
#product_comments_block_tab .comment_author_infos p.auth-name {
    color: $gray-darker;
    margin-bottom: 5px;
}
#product_comments_block_tab .comment_author_infos p.date-added {
    color: #7d7b7b;
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0px;
}

#product_comments_block_tab div.comment div.comment_details {
    float: left;
    overflow: hidden;
    width: calc(100% - 250px);
    padding-top: 10px;
    padding-right: 10px;
}

#product_comments_block_tab div.comment_details .title_block,
#product_comments_block_tab div.comment_details h4 {
    margin-bottom: 0;
    font: 500 16px $font-family;
    color: $gray-darker;
}

#product_comments_block_tab div.comment_details p {
    padding-bottom: 10px;
    margin: 0;
    font: 400 16px $font-family;
}

#product_comments_block_tab div.comment_details ul {
    list-style-type: none;
    margin: 0;
}

#product_comments_block_tab div.comment_details li {
    padding: 2px 0 2px 12px;
    //background: url(../img/bg_li.png) no-repeat 1px 45% #fff
}

#product_comments_block_tab a {
    text-decoration: none;
    font-weight: bold;
}

#product_comments_block_tab a:hover {
    text-decoration: underline;
}

#product_comments_block_tab button.usefulness_btn {
    cursor: pointer;
    margin: 0 0 0 5px;
    display: inline-block;
    padding: 0 2px;
    border: 1px solid #c0c0c0;
    border-radius: 3px 3px 3px 3px;
    color: #000;
    font-weight: bold;
}

#product_comments_block_tab button.usefulness_btn:hover {
    background-position: left -50px;
}

#product_comments_block_tab button.usefulness_btn:active {
    background-position: left -100px;
}

#product_comments_block_tab span.report_btn {
    cursor: pointer;
}

#product_comments_block_tab span.report_btn:hover {
    text-decoration: underline;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.open-comment-form {
    margin-bottom: 20px;
}

#newCommentForm {
    display: none;
    background: #fff;
}

#new_comment_form {
    #new_comment_form_footer {
        // margin-top: 10px;
    }
}

#product_comments_block_tab .comment_details ul li {
    font-size: 12px;
}

#new_comment_form #content {
    font: 400 14px $font-family;
    color: $gray-darker;
    margin-top: 10px;
    //border: none;
    /* border-right: 1px solid #c0c0c0 !important; */
    //border-bottom: 1px solid #c0c0c0 !important;
    /* border-left: 1px solid #c0c0c0 !important; */
}

#new_comment_form_ok {
    overflow: hidden;
    clear: both;
}

.rating-list {
    display: flex;
    // display: inherit;
}

.rating-title {
    padding-right: 10px;
    font: 400 18px $font-family;
    color: $brand-primary;
}

.comments-button {
    text-align: right;
    padding-right: 0px !important;
}

#submitNewMessage {
    box-shadow: none;
    background: $custom-color2;
    text-transform: none;
    font: 400 16px $font-family;
}
@include media-breakpoint-down(sm) {
    #product {
        #product_comments_block_tab div.comment div.comment_author {
            width: 100%;
            padding-right: 0;
        }
        #product_comments_block_tab div.comment div.comment_details {
            width: 100%;
            padding-right: 0;
            padding-top: 0;
        }
        #product_comments_block_tab .comment_author_infos p.date-added {
            margin-bottom: 0;
        }
    }
}
@include media-breakpoint-down(xs) {
    #product {
        #new_comment_form_footer {
            padding-left: 0;
            .comments-button {
                text-align: center;
            }
        }
    }
}
