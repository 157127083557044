#custom-text {
  margin-top: 45px;
  padding-right: 130px;
  background: white;
  @media (max-width:1420px){
    padding-right: 30px;
  }
  @media (max-width: 813px){
    padding-right: 5px;
  }
  @include media-breakpoint-down(sm){
    margin-top: 10px;
    padding-right: 0;
  }
}

.page-content.page-cms {
  background: white;
  padding: 1.25rem;
  text-align: justify;

  p, ul {
    line-height: 1.5em;
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    list-style-type: disc;
    padding-left: 15px;
  }

  .page-heading {
    font: 600 18px/22px $font-custom;
    color: #555454;
    text-transform: uppercase;
    padding: 0 0 17px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #d6d4d4;
    overflow: hidden;
  }

  .page-subheading {
    margin-top: 18px;
    font-family: $font-custom;
    font-weight: 700;
    text-transform: uppercase;
    color: #30a6b1;
    border: none;
    background-color: transparent;
    font-size: 18px;
    padding: 0 0 15px;
    line-height: normal;
    margin-bottom: 12px;
  }

  .cms-box {
    img {
      max-width: 100%;
    }
  }
  h1,
  h2,
  h4,
  h5 {
   
    font-weight: 300;
    text-transform: uppercase;
  }
  h3 {
    font-size: 16px;
    border-bottom: none;
    margin: 0;
    padding: 0 0 17px 0;
  }
  p {
    color: #000;
  }
}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
