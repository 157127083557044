.categorysliders_block{
    .nav-title-wrapper{
        margin-bottom: 43px;
    }
    .row{
        margin-right: -86px;
        margin-left: -42px;
        @media (max-width: 1588px){
            margin-right: -7px;
            margin-left: -7px;
        }
    }
    .category-slider{
        width: 20%;
        padding: 10px 86px 10px 42px;
        margin-bottom: 50px;
        @media (max-width: 1588px){
            padding: 10px 57px 10px 42px;
        }
        @media (max-width: 1469px){
            padding: 10px 35px 10px 35px;
        }
        @media (max-width: 1378px){
            padding: 10px 32px 10px 25px;
        }
        @media (max-width: 1233px){
            padding: 10px 18px 10px 7px;
        }
        @include media-breakpoint-down(lg){
            padding: 10px 7px 10px 7px;
            width: 25%;
        }
        @include media-breakpoint-down(md){
            width: 33.3333333%;
        }
        @include media-breakpoint-down(sm){
            width: 50%;
        }
        &:nth-last-child(-n+5) {
            margin-bottom: 15px;
            @include media-breakpoint-down(md){
                margin-bottom: 50px;
            }
        }
        .category-image{
            text-align: center;
            img{
                border-radius: 50%;
                background-color: #fff;
                width: 214px;
                height: 214px;
                @include media-breakpoint-down(xs){
                    width: 150px;
                    height: 150px;
                }
            }
        }
        .category-name{
            text-align: center;
            margin-top: 20px;
            font: 600 18px $font-family;
            height: 41px;
            a{
                color: #212123;
            }
        }
        &:hover{
            .category-image{
                img{
                    box-shadow: 0px 3px 11px 6px rgba(0, 0, 0, 0.06);
                }
            }
        }
    }
}

