@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/productcomments";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/product-slider";
@import "components/homepagecategories";
@import "~slick-carousel/slick/slick.scss";
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
body {
    overflow-x: hidden;
}


/*** HEADER ***/

#header {
    color: $gray;
    .logo {
        max-width: 100%;
        width: 100%;
    }
    .menu-right {
        margin-bottom: 0;
        text-align: right;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 13px;
            &.sale-link {
                a {
                    font: 400 16px $font-custom3;
                    color: #ff0000;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                }
                img {
                    margin-right: 5px;
                }
                @media (max-width: 1230px) {
                    margin-left: 0;
                }
            }
            &.phone-link {
                margin-right: 0;
                img,
                div {
                    display: inline-block;
                    vertical-align: middle;
                }
                a {
                    font: 300 14px $font-custom2;
                    color: #222222;
                    display: block;
                }
            }
        }
    }
    #menu-icon {
        vertical-align: middle;
        cursor: pointer;
        background-image: url("../img/hamburger_icon.png");
        background-repeat: no-repeat;
        width: 29px;
        height: 25px;
        display: inline-block;
        content: " ";
        background-size: auto 20px;
    }
    #menu-icon.active {
        vertical-align: middle;
        cursor: pointer;
        background-image: url("../img/cancel.png");
        background-repeat: no-repeat;
        width: 29px;
        height: 25px;
        display: inline-block;
        content: " ";
        background-size: auto 20px;
    }
    .header-nav {
        border-bottom: 1px solid $gray-lighter;
        padding: 12px 0 8px 0;
        @include media-breakpoint-down(sm) {
            padding: 0;
            margin-bottom: 0 !important;
        }
        .delivery {
            .delivery-info {
                font: 400 12px $font-custom3;
                color: $brand-primary;
                @include media-breakpoint-down(md) {
                    font: 400 11px $font-custom3;
                }
            }
            .delivery-truck {
                img {
                    @include media-breakpoint-down(md) {
                        width: 30px;
                    }
                }
            }
        }
        .right-nav {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
            p.h3 {
                display: none;
            }
            .links .collapse {
                display: flex;
                margin-bottom: 0;
                li {
                    margin-right: 20px;
                    font-size: 0;
                    @include media-breakpoint-down(md) {
                        margin-right: 10px;
                    }
                    a {
                        font: 400 11px $font-family;
                        color: #000;
                        &:hover {
                            -webkit-text-stroke-width: 0.5px;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .links {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
            .wrapper {
                width: 100%;
            }
        }
        .currency-selector {
            margin-top: 0.9375rem;
            margin-left: 0.9375rem;
            white-space: nowrap;
        }
        .user-info {
            margin-left: 2.5rem;
            margin-top: 0.9375rem;
            text-align: right;
            white-space: nowrap;
            .account {
                margin-left: $small-space;
            }
        }
        .language-selector {
            margin-top: 0.9375rem;
            white-space: nowrap;
        }
        .cart-preview {
            &.active {
                background: $custom-color;
                a,
                i {
                    color: white;
                }
            }
            .shopping-cart {
                vertical-align: middle;
                color: $gray;
            }
            .body {
                display: none;
            }
        }
        .blockcart {
            background: $gray-light;
            height: 3rem;
            padding: 0.75rem;
            margin-left: 0.9375rem;
            text-align: center;
            white-space: nowrap;
            a {
                color: $gray;
                &:hover {
                    color: $custom-color2;
                }
            }
            &.active {
                a:hover {
                    color: white;
                }
            }
            .header {
                margin-top: 0.125rem;
            }
        }
        #contact-link {
            margin-top: 0.9375rem;
        }
        .material-icons {
            line-height: inherit;
            &.expand-more {
                margin-left: -0.375rem;
            }
        }
    }
    .header-top {
        >.container {
            position: relative;
            .align-items-center {
                padding: 22px 0;
                display: flex;
                .header-left {
                    display: flex;
                    #_desktop_logo {
                        display: flex;
                        align-items: center;
                    }
                }
                .header-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            .right-header {
                float: right;
            }
        }
        .stickey {
            background-color: $gray-light;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            .for-stickey {
                display: none;
            }
        }
        // Mega menu
        .menu {
            width: 100%;
            color: #000;
            text-transform: uppercase;
            border-radius: 7px 7px 0 0;
            >ul {
                margin-bottom: 0;
                @media (max-width: 1565px) {
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(10, minmax(63px, 1fr));
                    text-align: center;
                    margin-bottom: 0;
                }
                >li {
                    border-right: 1px solid white;
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    padding: 11px 20px;
                    @media (max-width: 1581px) {
                        padding: 11px 19px;
                    }
                    @media (max-width: 1565px) {
                        padding: 11px 11px;
                    }
                    @media (max-width: 1348px) {
                        padding: 11px 5px;
                    }
                    @media (max-width: 1240px) {
                        padding: 5px 2px;
                    }
                    >a {
                        height: 100%;
                    }
                    &:first-child {
                        padding: 11px 23px;
                        @media (max-width: 1587px) {
                            padding: 11px 20px;
                        }
                        @media (max-width: 1565px) {
                            padding: 11px 11px;
                        }
                        @media (max-width: 1348px) {
                            padding: 11px 5px;
                        }
                        @media (max-width: 1240px) {
                            padding: 5px 2px;
                        }
                        a[data-depth="0"] {
                            &::before {
                                content: "";
                                background-repeat: no-repeat;
                                display: inline-block;
                                width: 1px;
                                height: 25px;
                                position: absolute;
                                top: 6px;
                                left: 0px;
                                @media (max-width: 803px) {
                                    top: 4px;
                                }
                            }
                        }
                    }
                    &:last-child {
                        border-right: unset;
                        @include media-breakpoint-down(lg) {}
                    }
                }
                &:last-child {
                    float: right;
                }
            }
        }
        .position-static {
            position: static;
        }
        #_desktop_user_discount {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            a,
            span {
                font: 700 11px $font-custom2;
                color: $brand-primary;
                text-transform: uppercase;
                vertical-align: text-top;
            }
        }
        #_desktop_user_favorites,
        #_desktop_cart {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            a,
            span {
                font: 700 11px $font-custom2;
                color: #000;
            }
            a {
                display: block;
                &:after {
                    background-image: url(../img/arrow-point-to-down.png);
                    background-repeat: no-repeat;
                    width: 9px;
                    height: 5px;
                    display: inline-block;
                    content: " ";
                    vertical-align: middle;
                    margin-left: 2px;
                    @media (max-width: 1041px) {
                        display: none;
                    }
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
        #_desktop_user_info {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            a,
            span {
                font: 700 11px $font-custom2;
                color: #000;
            }
            a {
                display: block;
                &.account:after {
                    background-image: url(../img/arrow-point-to-down.png);
                    background-repeat: no-repeat;
                    width: 9px;
                    height: 5px;
                    display: inline-block;
                    content: " ";
                    vertical-align: middle;
                    margin-left: 2px;
                    @media (max-width: 1041px) {
                        display: none;
                    }
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
        #_desktop_user_info,
        #_desktop_user_favorites,
        #_desktop_user_discount,
        #_desktop_cart {
            padding-left: 31px;
            @media (max-width: 1563px) {
                padding-left: 23px;
            }
            @media (max-width: 1467px) {
                padding-left: 15px;
            }
            @media (max-width: 1371px) {
                padding-left: 7px;
            }
            @media (max-width: 1275px) {
                padding-left: 4px;
            }
            @media (max-width: 1239px) {
                padding-left: 2px;
            }
            @media (max-width: 1215px) {
                padding-left: 0px;
            }
            @media (max-width: 1199px) {
                padding-left: 5px;
            }
            @media (max-width: 1111px) {
                padding-left: 7px;
            }
            @media (max-width: 1079px) {
                padding-left: 4px;
            }
            @media (max-width: 1050px) {
                padding-left: 3px;
            }
            @media (max-width: 1041px) {
                padding-left: 31px;
            }
            @media (max-width: 810px) {
                padding-left: 25px;
            }
        }
        .store-text,
        .favorite-text,
        .account-text,
        .cart-text {
            @media (max-width: 1041px) {
                display: none;
            }
        }
        @include media-breakpoint-down(md) {
            #_search_mobile {
                display: inline-block;
                padding-left: 25px;
                padding-top: 18px;
                padding-bottom: 10px;
                vertical-align: middle;
                cursor: pointer;
                .search-info {
                    &:before {
                        background-image: url(../img/search-256.png);
                        width: 20px;
                        height: 21px;
                        display: inline-block;
                        content: " ";
                        vertical-align: middle;
                    }
                    i {
                        font-size: 24px;
                    }
                }
            }
        }
        @include media-breakpoint-down(xs) {
            #_search_mobile {
                padding-right: 5px;
            }
        }
    }
    .top-menu-link {
        margin-left: $medium-space;
    }
    #menu-icon-stickey {
        display: inline-flex;
        cursor: pointer;
        .material-icons {
            font-size: 42px;
            color: #222;
        }
    }
    // Sticky header
    .stickey.fixed,
    .header-top.fixed {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0px;
        background: #fff;
        z-index: 51;
        border-bottom: 1px solid #000;
        >.container {
            padding-bottom: 0px;
            .logo {
                margin-top: 5px;
                @include media-breakpoint-down(sm) {
                    margin-top: 0px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            right: 0;
        }
        .for-stickey {
            .logo-container {
                display: inline-flex;
            }
        }
        #_desktop_top_menu {
            display: none;
        }
        // Hide first menu entry for sticky version
        .menu>ul {
            display: flex;
            position: absolute;
            background-color: $gray-light;
            top: 2px;
            left: -8px;
            width: 100%;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
            @include media-breakpoint-down(lg) {
                left: 0;
            }
            >li {
                @media (max-width: 1240px) {
                    padding: 5px 7px;
                }
                &:first-child {
                    @media (max-width: 1240px) {
                        padding: 5px 7px;
                    }
                }
            }
            li[data-depth="0"] {
                .popover {
                    background-color: #fff;
                    min-height: 100%;
                    position: absolute;
                    border-radius: 0 0 0 0;
                    z-index: 9999;
                    display: none;
                    position: absolute;
                }
            }
        }
    }
}

button.btn {
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: none;
    }
}

.mini-menu {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px;
    border-top: 1px solid black;
    li {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin: 10px 0 10px 0;
        a {
            display: block;
            color: black;
            font: 300 12px/25px $font-custom;
            padding: 0 10px;
            margin: 10px 0 10px 0;
            &:hover {
                color: $custom-color5;
            }
        }
        &:after {
            height: 31px;
            width: 1px;
            content: " ";
            background: #ddd;
            position: absolute;
            right: -1px;
            top: 0;
        }
        &:last-child:after {
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        display: block;
        padding-top: 0;
        li {
            display: block;
            &:first-child {
                background: white;
                a {
                    color: black;
                }
            }
            &:after {
                display: none;
            }
            a {
                color: black;
            }
        }
        li.link-menu-sale {
            a {
                color: black;
                background-color: white;
                border: none;
                &:hover {
                    background-color: black;
                    color: white;
                }
            }
        }
    }
    .mini-menu-link {
        p.h3,
        .title {
            display: none;
        }
        .collapse {
            display: block !important;
        }
    }
}

.popover {
    font-family: inherit;
}

.page-index {
    background: #fff;
    .menu {
        >ul {
            z-index: 3;
        }
    }
}

.discount-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    &:before {
        background-image: url(../img/discount.png);
        background-repeat: no-repeat;
        width: 17px;
        height: 26px;
        display: block;
        content: " ";
    }
}

.account-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    &:before {
        background-image: url(../img/user-header.png);
        background-repeat: no-repeat;
        width: 22px;
        height: 29px;
        display: block;
        content: " ";
    }
}

.favorite-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    &:before {
        background-image: url(../img/heart-header.png);
        background-repeat: no-repeat;
        width: 25px;
        height: 22px;
        display: block;
        content: " ";
    }
    .fav-count {
        text-align: center;
        position: absolute;
        top: -7px;
        right: -7px;
        height: 16px;
        min-width: 16px;
        @include border-radius(50%);
        background-color: $custom-color2;
        display: block;
        font: 600 10px/19px $font-custom2 !important;
        color: #fff !important;
    }
}

.shopping-cart-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @media (max-width: 368px) {
        width: 26px;
    }
    i {
        margin-right: 10px;
    }
    &:before {
        background-image: url(../img/cart-header.png);
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        display: block;
        content: " ";
    }
    .cart-products-count {
        text-align: center;
        position: absolute;
        top: -5px;
        right: -4px;
        height: 15px;
        min-width: 15px;
        @include border-radius(50%);
        background: $custom-color6;
        display: block;
        font: 600 10px $font-custom2 !important;
        color: #fff !important;
        padding-top: 2.9px;
    }
}

.page-manufacturer {
    .brand {
        .brand-item {
            border: 1px solid $custom-color7;
            margin-bottom: 15px;
        }
        text-align: center;
        .brand-infos {
            a {
                color: #000;
                font: 400 14px/16px $font-custom;
            }
        }
        .brand-products {
            a {
                color: #000;
                font: 600 12px/18px $font-custom;
                display: block;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
}

.page-manufacturer {
    @media (max-width: 767px) {
        #left-column, #content-wrapper {
            width: 100%;
        }
    }
    #wrapper {
        margin: 2rem 0 0;
    }
    h3 a {
        color: #000;
    }
    .brand-container {
        display: block;
        height: 100%;
        box-shadow: none;
        background: white;
        padding: 1rem;
        border: 1px solid #a7a8a8;
        margin-bottom: 1rem;
        text-align: center;
        .brand-infos {
            height: 41px;
            overflow: hidden;
        }
        &:hover {
            border-color: $custom-color2;
        }
    }
    .brand-products {
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 10px;
    }
}


/*** WRAPPER ***/

#wrapper {
    .banner {
        display: block;
        img {
            width: 100%;
        }
    }
    .breadcrumb {
        background: transparent;
        padding: 0;
        margin: 25px 0;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        &[data-depth="1"] {
            display: none;
        }
        ol {
            padding-left: 0;
            margin-bottom: 0;
        }
        li {
            display: inline;
            font: 500 14px $font-custom2;
            color: black;
            &::after {
                content: "/";
                color: $gray-category;
                margin: 0.3125rem;
            }
            a {
                color: inherit;
            }
            &:last-child {
                font-weight: 600;
                content: ">";
                color: black;
                margin: 0;
                &::after {
                    content: "";
                }
                a {
                    color: inherit;
                }
            }
        }
    }
}

.page-product {
    h1 {
        line-height: 1.1 !important;
    }
    #wrapper .breadcrumb {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        margin: 25px 0;
    }
    .back-to-default {
        background: $custom-color3;
        @include border-radius(8px);
        color: #fff;
        padding: 5px 10px;
        margin: 7px auto 15px;
        font-size: 14px;
        display: inline-block;
    }
    #wrapper .breadcrumb li:last-child {
        color: black;
    }
}


/*** MAIN ***/

#main {
    .page-header {
        margin-bottom: 1.563rem;
    }
    .page-content {
        margin-bottom: 1.563rem;
        h6 {
            margin-bottom: 1.125rem;
        }
        #notifications {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .page-footer {
        margin-bottom: 1.563rem;
    }
}

#notifications {
    ul {
        margin-bottom: 0;
    }
}

.footer-container {
    margin-top: 40px;
}


/*** FOOTER ***/


/*** Responsive part ***/

@include media-breakpoint-down(sm) {
    #wrapper {
        box-shadow: none;
    }
    #checkout-cart-summary {
        float: none;
        width: 100%;
        margin-top: 1rem;
    }
    #header {
        background: $gray-light;
        .header-nav {
            background: white;
            margin-bottom: 0.625rem;
            color: $gray-darker;
            .top-logo {
                line-height: 50px;
                vertical-align: middle;
                width: 200px;
                margin: 0 auto;
                a {
                    img {
                        max-height: 50px;
                        max-width: 100%;
                    }
                }
            }
            .right-nav {
                flex-direction: column;
            }
            .user-info {
                text-align: left;
                margin-left: 0;
                .logged {
                    color: $custom-color2;
                }
            }
            .blockcart {
                margin-left: 0;
                background: inherit;
                &.active {
                    margin-left: 0.5rem;
                }
                &.inactive {
                    .cart-products-count {
                        display: none;
                    }
                }
            }
        }
        .header-top {
            background: $gray-light;
            padding-bottom: 0;
            a[data-depth="0"] {
                color: $gray-darker;
            }
        }
    }
    section.checkout-step {
        width: 100%;
    }
    .default-input {
        min-width: 100%;
    }
    label {
        clear: both;
    }
    #products .product-miniature,
    .featured-products .product-miniature {
        margin: 0 auto;
    }
    .block-contact {
        padding-left: 0.9375rem;
        border: none;
    }
    .menu,
    .dropdown-item {
        padding-left: 0;
    }
    #footer {
        padding-top: 0;
    }
}

.page-contact {
    .block-contact {
        line-height: 1.5em;
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
}

#header .logo-container {
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        margin: 0 auto;
        width: calc(100% - 51px);
    }
}

@include media-breakpoint-down(sm) {
    #header .header-top {
        #_search_mobile,
        #_desktop_user_info,
        #_desktop_user_discount,
        #_desktop_user_favorites,
        #_desktop_cart {
            padding-top: 3px;
            padding-bottom: 0;
            padding-left: 5px;
            color: black;
        }
    }
    .container {
        max-width: 100%;
    }
    #header .logo {
        width: auto;
        max-height: 45px;
    }
    #products .product-miniature,
    .featured-products .product-miniature {
        margin: 0 auto;
    }
    .sub-menu {
        left: 0;
        min-width: 100%;
    }
    #blockcart-modal .product-image {
        width: 100%;
        display: block;
        max-width: 250px;
        margin: 0 auto 0.9375rem;
    }
    #blockcart-modal .cart-content {
        padding-left: 0;
    }
    #blockcart-modal .product-name,
    #product-availability {
        margin-top: $small-space;
    }
    #search_filters .facet .facet-label {
        text-align: left;
    }
    .block-category .category-cover {
        position: relative;
        text-align: center;
    }
    .block-category {
        padding-bottom: 0;
    }
}

.row-eq-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.align-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#content2 {
    margin-top: 56px;
    background-color: $background-index;
}

.slider-title {
    font: 600 19px $font-custom2;
    color: #000;
    text-transform: none;
    width: 100%;
    text-align: center;
    margin-top: 49px;
    margin-bottom: 4px;
}

.banners {
    img {
        @include border-radius(6px);
    }
    .banner {
        margin-right: -10px;
        margin-left: -10px;
        .banner-image {
            padding-right: 5px;
            padding-left: 5px;
            @include media-breakpoint-down(xs) {
                margin-bottom: 5px;
            }
        }
    }
}

.productsliders_block {
    .h3 {
        font: 600 19px $font-custom2;
        color: #000;
        text-transform: none;
        width: 100%;
    }
    .nav-tabs {
        text-align: end;
        border-bottom: none;
        li {
            display: inline-block;
        }
        li.active a {
            color: #415668;
            font-weight: 600;
        }
    }
    ul.nav-tabs li:not(:last-child):after {
        content: "";
        border-right: 1px solid #d0d0d0;
        display: inline-block;
        height: 16px;
        width: 10px;
        margin-right: 10px;
    }
}

.productsliders_block {
    padding-top: 7px;
    .nav-title-wrapper {
        margin-bottom: 5px;
    }
    .tab-content {
        margin-right: -20px;
        visibility: hidden;
        height: 435px;
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }
    .view-all {
        position: absolute;
        top: 0;
        right: 0;
        a {
            font: 400 16px/18px $font-custom2;
            color: #000;
            &:hover {
                color: $custom-color2;
            }
        }
        @include media-breakpoint-down(sm) {
            position: unset;
            display: block;
            padding: 0;
        }
    }
    .productsliders-top {
        border-bottom: 1px solid $custom-color;
        margin-bottom: 30px;
        .title {
            font: bold 22px/24px $font-custom;
            color: #646567;
            text-transform: uppercase;
            padding-bottom: 15px;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
    &.cele_mai_noi_produse {
        background: #f5f5f5;
        .banner-home {
            position: relative;
            .banner {
                position: relative;
                z-index: 4;
            }
            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50%;
                width: 100%;
                background-color: #fff;
                z-index: 1;
                display: block;
                content: " ";
            }
        }
    }
    button {
        &.slick-prev,
        &.slick-next {
            position: absolute;
            top: 47%;
            width: 43px;
            height: 33px;
            z-index: 5;
            cursor: pointer;
            right: -31px;
            background: #ededed;
            border-radius: 50%;
            padding: 30px 30px;
            opacity: 0.7;
            &:before {
                color: #000;
                content: " ";
                text-indent: 0;
                background: url("../img/next-arrow.png") no-repeat;
                height: 28px;
                width: 16px;
                display: block;
                margin: auto;
                position: absolute;
                top: 26%;
                left: 21px;
                opacity: 1;
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
        &.slick-prev {
            right: auto;
            left: -26px;
            &:before {
                float: none;
                background: url("../img/prev-arrow.png") no-repeat;
                left: 18px;
            }
        }
    }
    @media (max-width: 991px) {
        .product-action .add-remove-favorites ul li a span {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .slider-group .nav-tabs {
        li {
            display: none;
            float: none;
            text-align: left;
            &.active {
                display: block;
                position: relative;
                cursor: pointer;
                a {
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 16px;
                    &:after {
                        content: " ";
                        display: inline-block;
                        border-bottom: 2px solid #415668;
                        border-right: 2px solid #415668;
                        height: 10px;
                        width: 10px;
                        transform: rotate(405deg);
                        vertical-align: middle;
                        margin-left: 5px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        li:not(:last-child):after {
            display: none !important;
        }
    }
}

.nav-tabs.select {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    display: block;
    width: 100%;
    li {
        position: relative;
        top: 150px;
        margin: 0 auto;
        float: none;
        min-width: 300px;
        background-color: #fff;
        padding: 5px 0;
        z-index: 1;
        display: block;
        a {
            display: block;
            padding: 5px 0;
            font-size: 20px !important;
            color: $gray !important;
            padding-left: 15px;
        }
        &.active {
            a {
                color: $gray !important;
                &:after {
                    display: none;
                }
            }
            &.active {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.order-message-form select.form-control.form-control-select {
    padding-right: 24px !important;
}

#pre-comanda {
    scroll-margin-top: 100px;
}

.slick-prev,
.slick-next {
    border: unset !important;
    margin-top: 0;
}

.brands-carousel {
    .row-brands-carousel {
        position: relative;
        z-index: 0;
        border-left: 1px solid $gray-border;
        border-right: 1px solid $gray-border;
    }
    .swiper-container {
        margin: 0;
        .swiper-wrapper {
            .swiper-slide {
                border-right: 1px solid $gray-border;
                border-top: 1px solid $gray-border;
                border-bottom: 1px solid $gray-border;
            }
        }
    }
    a {
        position: relative;
        display: block;
        background-color: white;
    }
    .swiper-slide-prev {
        a:after {
            display: none !important;
        }
    }
    .brand {
        margin: 10px 10px 10px 0;
        a {
            display: block;
            position: relative;
        }
    }
    .brands-buttons {
        position: unset;
    }
    .swiper-button-prev-brands,
    .swiper-button-next-brands {
        background-image: none !important;
        position: absolute;
        z-index: 5;
        cursor: pointer;
        right: -29px;
        background: #ededed;
        @include border-radius(50%);
        padding: 30px 30px;
        opacity: 0.7;
        &:before {
            color: #000;
            content: " ";
            text-indent: 0;
            background: url("../img/next-arrow.png") no-repeat;
            height: 29px;
            width: 16px;
            display: block;
            position: absolute;
            top: 27%;
            left: 25px;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
    .swiper-button-prev-brands {
        right: auto;
        left: -27px;
        &:before {
            float: none;
            background: url("../img/prev-arrow.png") no-repeat;
            position: absolute;
            top: 29%;
            left: 20px;
        }
    }
    @include media-breakpoint-down(md) {
        a:after {
            display: none !important;
        }
        a {
            border-bottom: none !important;
        }
    }
    .manufacturerslider {
        padding: 0;
        .manufacturer.slick-slide {
            border-right: 1px solid $gray-border;
            img {
                max-width: 263px;
                max-height: 175px;
                width: 100%;
                height: auto;
            }
        }
        .slick-prev.slick-arrow,
        .slick-next.slick-arrow {
            background-image: none !important;
            position: absolute;
            z-index: 5;
            cursor: pointer;
            right: -29px;
            background: #ededed;
            @include border-radius(50%);
            padding: 30px 30px;
            opacity: 0.7;
            margin-top: 0;
            border: unset;
            &:before {
                color: #000;
                content: " ";
                text-indent: 0;
                background: url("../img/next-arrow.png") no-repeat;
                height: 29px;
                width: 16px;
                display: block;
                position: absolute;
                top: 27%;
                left: 25px;
            }
        }
        .slick-prev.slick-arrow {
            right: auto;
            left: -27px;
            &:before {
                float: none;
                background: url("../img/prev-arrow.png") no-repeat;
                position: absolute;
                top: 29%;
                left: 20px;
            }
        }
    }
}

.viewed-products {
    margin-top: 4rem;
    button {
        &.slick-prev,
        &.slick-next {
            position: absolute;
            top: 47%;
            width: 43px;
            height: 33px;
            z-index: 5;
            cursor: pointer;
            border: 1px solid $gray;
            right: -13px;
            @include border-radius(8px);
            &:before {
                color: #000;
                content: " ";
                text-indent: 0;
                background: url("../img/next-arrow.png") no-repeat;
                height: 14px;
                width: 8px;
                display: block;
                margin: auto;
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
        &.slick-prev {
            right: auto;
            left: -9px;
            @media (max-width: 1300px) {
                left: -20px;
            }
            &:before {
                float: none;
                background: url("../img/prev-arrow.png") no-repeat;
            }
        }
    }
    .viewed-products-slick.row {
        margin-right: -16px;
        margin-left: -16px;
        article {
            padding-right: 16px;
            padding-left: 16px;
        }
        @media (max-width: 430px) {
            margin-right: -9px;
            margin-left: -9px;
            article {
                padding-right: 9px;
                padding-left: 9px;
            }
        }
        @media (max-width: 390px) {
            margin-right: -4px;
            margin-left: -4px;
            article {
                padding-right: 4px;
                padding-left: 4px;
            }
        }
    }
    .h3.slider-title {
        font: 600 19px $font-custom2;
        color: #000;
        text-transform: none;
        width: 100%;
        span {
            background: $gray-lighter;
            padding: 0 40px;
            @media (max-width: 428px) {
                line-height: 1;
                padding: 0 0;
            }
        }
    }
    .product-thumbnail {
        @include border-radius(8px);
        display: block;
        overflow: hidden;
        text-align: center;
        background-color: #fff;
        img {
            height: 90px;
            width: auto;
        }
    }
    .product-viewed {
        display: inline-block;
        width: 14.2%;
        @include media-breakpoint-down(sm) {
            width: 50%;
            margin-bottom: 10px;
        }
    }
}

html,
body {
    overflow-x: hidden;
}


/* compare responsive */

.compare-container {
    bottom: 68px !important;
    .bt_compare span {
        display: none;
    }
    li {
        height: 36px !important;
    }
    @media (max-width: 1300px) {
        position: static !important;
        text-align: center;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        li {
            display: inline-block;
            vertical-align: top;
            height: 36px !important;
            .bt_compare_widget {
                background: #40bf80 !important;
                margin-top: 7px !important;
                margin-left: 7px !important;
                width: auto;
                padding: 3px 9px;
                span {
                    display: inline-block;
                }
            }
        }
    }
}

.compare-content {
    @include media-breakpoint-down(md) {
        overflow-x: scroll;
    }
}

table#product_comparison {
    background: #fff;
    tbody tr td {
        font: 13px $font-custom;
        color: #666;
        &.product-block {
            position: relative;
            .btn.cmp_remove {
                font-size: 12px;
                padding: 5px 10px;
                @include border-radius(2px);
                display: inline-block;
            }
        }
        .prices-container {
            margin-bottom: 10px;
            font-weight: bold;
            color: #828181;
            .has-discount {
                color: $custom-color6;
                margin-right: 7px;
            }
            .regular-price {
                text-decoration: line-through;
                font-weight: 600;
                color: $custom-color9;
            }
        }
    }
    h5 {
        a {
            color: #5c5d60;
            font: 15px $font-custom;
            height: 53px;
            overflow: hidden;
            display: block;
        }
    }
}

.allcategories {
    .category-image {
        height: 180px;
    }
    a {
        display: block;
        font: 400 14px/16px $font-custom2;
        color: #222;
        &.category-name {
            height: 32px;
            overflow: hidden;
            margin-top: 10px;
        }
        img {
            height: 180px;
            width: auto;
        }
    }
}

#call-price-modal {
    width: 400px;
    @media (max-width: 320px) {
        width: 300px;
    }
    .modal-header {
        padding: 40px 15px;
        height: auto;
        position: relative;
        .close {
            position: absolute;
            right: 15px;
            top: 15px;
            color: #fff;
            opacity: 0.8;
        }
    }
    .modal-title {
        color: #fff;
        font: 300 23px/20px $font-custom2;
        margin-top: 0;
    }
    #modalTitle {
        font-size: 14px;
        line-height: 15px;
        margin-top: 10px;
    }
    .input-group-addon {
        padding: 0.4rem 0.75rem;
        width: 55px;
    }
    .input-group {
        .form-control {
            width: 100%;
            height: 40px;
        }
    }
    .form-inline .input-group {
        width: 100%;
    }
    .modal {
        z-index: 9999;
    }
    .modal-backdrop {
        z-index: 9998;
    }
    .modal-backdrop.in {
        opacity: 0.5 !important;
    }
    .modal-header {
        background: #1d4f53;
        color: white;
        text-align: center;
    }
    .input-group-addon {
        background-color: #fff;
        color: grey;
    }
    .input-group {
        margin-bottom: 15px;
    }
    .input-group,
    .input-group .form-control {
        height: 40px;
    }
    .form-control {
        line-height: 40px;
    }
    .contact-msg {
        text-align: center;
        margin-top: 10px;
    }
    .custom-control {
        height: 100px !important;
        line-height: 20px;
    }
    #modcfp_captcha {
        margin: 0 auto;
        width: 304px;
    }
    @media only screen and (max-width: 320px) {
        #modcfp_captcha {
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
    #messageSent {
        margin-bottom: 25px;
        .material-icons {
            font-size: 50px;
        }
        h3 {
            font: 300 20px $font-custom;
        }
    }
}

.product-actions {
    .call-for-price {
        background: $custom-color2;
        padding: 14px 64px;
        @include border-radius(8px);
        border: none;
        color: #fff;
        font: 300 15px/22px $font-custom2;
    }
}

.commercial-offers-list {
    .exclusive {
        position: relative;
        display: flex;
        align-items: center;
        padding: 5px 7px;
        color: #fff;
        background: $gray;
        border: none;
        cursor: pointer;
        white-space: normal;
        text-align: left;
        &:hover {
            background: $brand-primary;
        }
    }
}

.offer-details {
    font: 300 0.9375rem/22px $font-custom2;
}

.left-colum-customoffers {
    display: none;
}

#content-wrapper.right-colum-customoffers {
    width: 100%;
    max-width: 100% !important;
}

.commercial-offers-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.height-equal {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    margin-bottom: 20px;
    .link.clearfix {
        position: absolute;
        bottom: 21px;
        right: 0;
    }
    .offer-time {
        margin-bottom: 40px;
    }
}

.equal-height {
    margin-bottom: 20px;
}

// Landing PAGE CAMPAIN
#module-landingpagecampaigns-campaign {
    html,
    body {
        height: 100%;
        width: 100%;
        overflow: auto;
    }
    .top-navbar.fixed {
        padding-top: 0;
        left: 0;
    }
    .list-container .subcategory-container h2 {
        font: 600 24px/26px $font-custom !important;
    }
    .btn-default:not(.choose_variant) {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.42857;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid transparent;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        color: #333;
        background-color: #fff;
        border-color: #ccc !important;
        border-radius: 0;
    }
    #menu .nav-item {
        margin-right: 5px;
        margin-bottom: 5px;
        margin-left: 0;
        padding: 0;
        .nav-link {
            padding-top: 9px;
            padding-bottom: 9px;
            font-size: 13px;
            font-weight: 300;
        }
    }
    #subcategory_list {
        .navbar {
            padding: 0;
            @include border-radius(3px);
            overflow: hidden;
            li {
                a {
                    font-weight: 300;
                    font-size: 13px;
                }
            }
        }
    }
    .btn-read-more {
        margin: 0 auto;
        display: block;
    }
    @include media-breakpoint-down(sm) {
        .btn-back {
            margin: 0 20px 20px;
            display: block;
        }
    }
    .top-navbar.fixed .col-lg-12 {
        width: 1210px;
    }
}

//PRODUCTSLIDERS - VIEW PAGE
#module-productsliders-view {
    .h2 {
        padding-top: 35px;
        font: 600 20px $font-family;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
    .top-inftomations {
        margin-bottom: 10px;
    }
    .custom-page {
        display: flex;
        justify-content: flex-end;
        padding-right: 7px;
        @include media-breakpoint-down(sm) {
            justify-content: center;
            margin-bottom: 10px;
            .sort-by-row {
                width: 50%;
            }
            .filter-button {
                display: none;
            }
        }
        .nbr-products {
            text-align: right;
            display: inline-block;
            font: 400 14px/18px "Spartan", sans-serif;
            color: #222222;
            display: inline-block;
            vertical-align: top;
            padding-right: 7px;
            #af_nb_items {
                color: #232323;
                background: none;
                cursor: pointer;
                border: 1px solid #d7d7d7;
                @include border-radius(5px);
                font: 300 14px/16px "Spartan", sans-serif;
                margin-left: 0;
                width: 55px;
                padding-left: 8px;
            }
        }
    }
}

#module-productsliders-view {
    #js-product-list {
        .sort-by-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #000;
            vertical-align: top;
            padding-top: 7px;
            margin-bottom: 15px;
            .sort-by {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                white-space: normal;
                font: 400 12px $font-family;
                color: black;
            }
            .products-sort-order {
                display: flex;
                justify-content: flex-end;
                max-width: 182px;
                padding-right: 0;
                .select-title {
                    display: inline-block;
                    width: 100%;
                    min-width: 137px;
                    max-width: 175px;
                    color: black;
                    padding: 6px 0px 1px 10px;
                    cursor: pointer;
                    border: 1px solid #d7d7d7;
                    border-radius: unset;
                    font: 400 12px $font-family;
                }
            }
        }
    }
}

//ps_emailalerts - account page
#module-ps_emailalerts-account {
    .box-product-alert {
        position: relative;
        background-color: #fff;
        position: relative;
        margin-bottom: 1.9rem;
        @include box-shadow;
        border-radius: 6px;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            padding-top: 40px;
            border-radius: 6px 6px 0 0;
        }
        p {
            text-align: center;
            height: 65px;
        }
        a.js-remove-email-alert {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.add-to-alert-modal {
    display: inline-flex;
    float: left;
    .modal-header {
        h4.modal-title {
            float: left;
        }
    }
    .modal-body {
        .tabs {
            padding-top: 0;
        }
        .box_alert {
            display: flex;
            justify-content: center;
            .is_alert_set {
                text-align: center;
                font: 700 18px $font-family;
                background: #000;
                color: #fff;
                padding: 5px 15px;
                border-radius: 8px;
            }
        }
    }
    .add-to-alert {
        margin: 0;
        background: $custom-color5;
        padding: 12px 48px;
        margin-top: 1px;
        border-radius: 8px;
        border: none;
        span {
            display: inline-block;
            vertical-align: top;
            font: 300 18px $font-family;
            text-transform: uppercase;
            color: #fff;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            background: #d21e25;
        }
        @media (max-width: 793px) {
            padding: 12px 40px;
        }
        @include media-breakpoint-down(sm) {
            padding: 12px 48px;
        }
        @media (max-width: 475px) {
            padding: 12px 40px;
        }
        @media (max-width: 436px) {
            padding: 12px 35px;
        }
        @media (max-width: 426px) {
            padding: 12px 25px;
        }
        @media (max-width: 406px) {
            padding: 12px 20px;
        }
        @media (max-width: 387px) {
            padding: 14px 20px;
        }
    }
}

//ss_mailalerts - account page
.product-out-of-stock {
    padding: 15px 0;
    h4 {
        font-family: $font-family;
    }
    a,
    span {
        font: 600 1.125rem $font-family;
    }
}

#module-ss_mailalerts-account {
    #mailalerts_block_account {
        h1 {
            font: 600 24px/26px $font-family;
            text-transform: none;
            padding-top: 0.625rem;
        }
        .products-block {
            li {
                position: relative;
                margin-bottom: 1.9rem;
                background: white;
                @include box-shadow;
                border-radius: 6px;
                a {
                    display: block;
                    img {
                        padding-top: 40px;
                        display: block;
                        margin: 0 auto;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .product-content {
                    height: 65px;
                    .remove {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        cursor: pointer;
                        background: transparent;
                        border: none;
                    }
                    h5 {
                        margin: 22px;
                        padding-bottom: 1px;
                        text-align: left;
                        a.product-name {
                            display: block;
                            text-align: left !important;
                            text-transform: capitalize;
                            color: black;
                            font: lighter 14px $font-family;
                            text-transform: none;
                            align-items: flex-start;
                            text-align: left !important;
                            outline: 0;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}

//customblog - slider
.blog-carousel {
    .slider-title {
        margin-top: 60px;
        margin-bottom: 10px;
        @media (max-width: 360px) {
            border-bottom: none;
            span {
                line-height: 1;
                padding: 0 0;
            }
        }
    }
    .blog-carousel-slick {
        margin-right: -16px;
        margin-left: -16px;
        background-color: unset;
        border: unset;
        .slick-track {
            border: none;
        }
        .blog-item {
            margin-right: 16px;
            margin-left: 16px;
            margin-top: 20px;
            margin-bottom: 10px;
            padding: 0;
            .article-title {
                margin-top: 23px;
                height: 34px;
                margin-bottom: 10px;
                p,
                a {
                    color: #000;
                    text-transform: uppercase;
                    font: 600 14px $font-family;
                    text-align: left;
                }
            }
            img {
                width: 100%;
                max-height: 237px;
                max-width: 355px;
                margin-left: auto;
                margin-right: auto;
            }
            .article-description {
                overflow: hidden;
                p {
                    color: #000;
                    font: 400 13px $font-family;
                    line-height: 1.3;
                    text-align: left;
                    margin-right: 30px;
                }
            }
        }
        button {
            &.slick-prev,
            &.slick-next {
                position: absolute;
                top: 47%;
                width: 43px;
                height: 33px;
                z-index: 5;
                cursor: pointer;
                right: -13px;
                border-radius: 50%;
                background: #ededed;
                padding: 30px;
                @include border-radius(50%);
                &:before {
                    color: #000;
                    content: " ";
                    text-indent: 0;
                    background: url("../img/next-arrow.png") no-repeat;
                    height: 29px;
                    width: 16px;
                    display: block;
                    margin: auto;
                    position: absolute;
                    top: 15px;
                    left: 26px;
                }
                &.swiper-button-disabled {
                    display: none;
                }
            }
            &.slick-prev {
                right: auto;
                left: -11px;
                @media (max-width: 1300px) {
                    left: -20px;
                }
                &:before {
                    left: 18px;
                    float: none;
                    background: url("../img/prev-arrow.png") no-repeat;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .mt-3 {
        margin-top: 1em !important;
    }
}

//compareproducts page
table#product_comparison {
    h5 {
        a {
            height: 66px !important;
        }
    }
    .product-image-block .product_image img {
        max-width: unset;
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        tbody tr td.product-block {
            padding: 14px 3px 19px !important;
        }
    }
}

//ss_gdpr
#modssgdpr_cookie_bar p {
    font: 400 14px $font-family !important;
    .btn-primary {
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;
    }
}

.favoriteproduct {
    .fav-container {
        position: relative;
        margin-bottom: 1.9rem;
        background: white;
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin-right: 3px;
        margin-left: 2px;
        padding-bottom: 15px;
    }
    .product_img_link {
        display: block;
    }
    img {
        width: 150px;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        padding: 45px 0 15px 0;
    }
    .favorite-title {
        text-align: center;
        height: 40px;
        overflow: hidden;
        a {
            font: 700 14px $font-family;
            color: #000;
        }
    }
    .remove-favorite {
        text-align: center;
        button {
            border-radius: 5px;
            font: 400 11px $font-family;
            box-shadow: none;
            padding: 7px 15px;
            text-transform: none;
            margin-top: 5px;
            text-align: center;
            cursor: pointer;
            background-color: $brand-primary;
            border: none;
            @include media-breakpoint-down(sm) {
                padding: 7px 13px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    #module-favoriteproducts17-account {
        #left-column,
        #content-wrapper {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }
}

.fav-pg-title {
    margin-bottom: 1.563rem;
    margin-top: 25px;
}

#favoriteproducts_block_account {
    .warning {
        display: inline-block;
        width: 100%;
        padding-left: 15px;
    }
}

#prices-drop {
    .footer-container {
        margin-top: 40px;
    }
    .viewedproduct_slider .slick-prev,
    .viewedproduct_slider .slick-next {
        top: 25%;
    }
}

body#checkout section.checkout-step {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
    padding: 0.9375rem;
}

#checkout {
    #content {
        margin-top: 25px;
    }
}

.slick-dots li.slick-active button:before {
    color: #000 !important;
}

.slick-dots li button:before {
    font-size: 20px !important;
}

#pagenotfound,
#prices-drop {
    #content-wrapper {
        #search_widget {
            button {
                padding: 0;
                border: 1px solid $gray;
                padding: 2px 5px;
                border: 1px solid #a7a8a8;
                vertical-align: bottom;
                i.search {
                    background-image: url("../img/search-256.png");
                    width: 20px;
                    height: 21px;
                    background-repeat: no-repeat;
                    display: block;
                }
            }
            .searchresult-wrap {
                display: none !important;
            }
        }
    }
}

//cart loader ajax (13-09-2022)
.ajax-cart-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 99;
    display: none;
    &.show {
        display: block !important;
    }
    .inner-ajax {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
            width: 100px;
        }
    }
}