@mixin search-box {
  form {
    position: relative;
    
    input[type=text] {
      &:focus {
        outline: 0;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $custom-color2;
        }
      }

      &::-ms-clear {
        display: none;
      }
      
      padding: 10px;
      min-width: 255px;
      color: $gray;
      border: $input-btn-border-width solid $input-border-color;
    }

    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 0.125rem;
      color: $gray;
      .search:hover {
        color: $custom-color2;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.1);
}

@mixin box-shadow-light {
  box-shadow: none;
}

a {
  &:focus,
  &:visited,
  &:active {
    outline: 0;
    text-decoration: none;
  }
}
