.carousel {
  @extend .col-md-12;
  // &:hover{
  //   .carousel-control {
  //     .icon-next,
  //     .icon-prev {
  //       display: block;
  //     }
  //   }
  // }

  @include media-breakpoint-down(md) {
    width: 100%;
    height: auto;
  }

  .direction {
    z-index: auto;
  }

  .carousel-inner {
    margin-bottom: 0;
    figure, a {
      margin-bottom: 0;
      display: block;
    }

    img {
      width: 100%;
      max-height: 484px;
      margin: 0 auto;
      @include media-breakpoint-down(md) {
        height: auto;
      }
    }

    .caption {
      position: absolute;
      bottom: 28px;
      left: 90px;
      color: white;
      max-width: 340px;
      .caption-description p {
        color: white;
      }
    }
  }

  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      // display: none;
      position: absolute;
      top: 52%;
      width: 43px;
      height: 33px;
      z-index: 2;
      cursor: pointer;
      border: 1px solid $gray;
      right: -8px;
      @include border-radius(8px);
      &:before {
        color: #000;
        content: " ";
        text-indent: 0;
        background: url("../img/prev-arrow.png") no-repeat;
        height: 14px;
        width: 8px;
        display: block;
        margin: auto;
        margin-top: 8px;
      }

      &.swiper-button-disabled {
        display: none;
      }
    }

    .icon-prev {
      left: 2.5rem;
      @include media-breakpoint-down(sm){
        display: none;
      }
    }

    .icon-next {
      right: 2.5rem;
      @include media-breakpoint-down(sm){
        display: none;
      }

      &:before{
        background: url('../img/next-arrow.png') no-repeat;
      }
    }

    &.left,
    &.right {
      background: none;
    }
  }
}