#_desktop_search{
  // padding: 15px 2px;
  @include media-breakpoint-down(sm){
    padding: 0;
  }
}

.sec_search_widget {
  width: 79.5%;
  float: right;
  @include media-breakpoint-down(md) {
    width: 89.5%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f1f1f1;
    z-index: 1;
    padding-top: 10px;
  }
  form {
    //max-width: 500px;
    position: relative;
    // border: 1px $custom-color7;
    // border-style:solid none solid solid;
    background-color: #fff;
    display: block;
    margin: 0 auto;
    @include border-radius(50px);
    @include media-breakpoint-down(sm){
      margin: 0 10px;
    }
 
    input[type=search] {
      height: 38px;
      // border: none;
      border: 1px $custom-color7;
      border-style:solid none solid solid;
      @include border-radius(50px 0 0 50px);
      width: calc(100% - 47px);
      // padding: 10px !important;
      padding: 10px 0 10px 20px;
      font: 400 15px $font-custom;
      /*
      background: #fff;
      */
      color:#000;
      &::-webkit-input-placeholder {
        color: $custom-color2;
        font: 400 12px/22px $font-custom3;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $custom-color2;
        font: 400 12px/22px $font-custom3;
        opacity: 1;
    }
    &::-ms-placeholder {
        color: $custom-color2;
        font: 400 12px/22px $font-custom3;
        opacity: 1;
    }
    &::placeholder {
        color: $custom-color2;
        font: 400 12px/22px $font-custom3;
        opacity: 1;
    }
      &::-ms-clear {
        display: none;
      }
    }

    button[type=submit] {
      position: absolute;
      margin:0 auto;
      // border: none;
      border: 1px $custom-color6;
      border-style:solid none solid solid;
      bottom: 0;
      right: 0px;
      color: white;
      height: 38px;
      width: 47px;
      background: $custom-color6;
      padding: 10px !important;
      @include border-radius(0 50px 50px 0);

      &:hover {
       // background-color: darken($custom-color, 10%);
        cursor: pointer;
        background-color: $custom-color5;
      }
      &:focus{
        outline: unset;
      }

      .search {
        background-image: url(../img/search.png);
        background-repeat: no-repeat;
        width: 22px;
        height: 24px;
        content: " ";
        display: block;
        margin: 0 auto;
      }
    }
  }
}

// #checkout {
//   #search_widget {
//     display: none; /* Not ideal solution by allows to reuse same hooks/templates */
//   }
// }

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.page-not-found {
  .sec_search_widget  {
    border: 1px solid #222;
    form {
      display: block;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      form {
        // margin: 0 auto;
        max-width: 100%;
        input[type="text"] {
          min-width: inherit;
          background: white;
        }
      }
    }
  }
  .header-linnks{
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      height: 0;
    }
  }
}

/* search */

.ui-autocomplete {
  z-index: 9999999;
  border: none;
}

.ui-autocomplete.ui-front {
  background: #fff;
  border: none;
  li {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      .product-info a {
        // color: $custom-color2;
      }
    }
    .product-image {
      display: block;
      float: left;
      padding: 0;
      width: 98px;
      &:hover,
      &.ui-state-focus {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        img {
          border: none;
        }

      }
    }
    .product-info {
      display: block;
      float: left;
      padding: 20px;
      width: calc(100% - 120px);
      &:hover {
        border: none;
      }
      a {
        display: block;
        // font: 300 16px/21px $font-custom;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #878789;
        padding: 0;
      }
      span {
        // font-family:  $font-custom;
        font-size: 14px;
        color: #e82b2b;
        font-weight: bold;
        margin-right: 0;
        &.no-reduction {
          color: #828181;
          font-size: 14px;
        }
        &.old-price {
          color: #c1c0c0;
          margin-left: 5px;
          text-decoration: line-through;
        }
      }
    }
  }
}

#search_block_top #search_query_top.no-round-bottom {
  @include border-radius(32px 32px 0 0);
}

#ui-id-1 .ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 0;
}

.searchresult-wrap {
  // position: absolute;
  // left: 0;
  // right: 0;
}
// nano-content
/** initial setup **/
.searchresult {
  position: relative;
  width: 500px;
  max-height: 373px;
  overflow: hidden;
  margin: 0 auto;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  z-index: 11;
  // max-width: calc(100% - 15px);
  left: 0 !important;
  max-width: 100%;
  // border: 1px solid $custom-color7;
}

.searchresult > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: -17px;
  bottom: 0;
  left: 0;
  padding: 0;
}

.searchresult > .nano-content:focus {
  outline: thin dotted;
}

.searchresult > .nano-content::-webkit-scrollbar {
  display: none;
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}

.searchresult > .nano-pane {
  background: #c2d8a6;
  position: absolute;
  width: 7px;
  height: 364px;
  right: -1px;
  top: 4px;
  bottom: 0;
  visibility: hidden \9
; /* Target only IE7 and IE8 with this hack */
  opacity: .4;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  //-moz-border-radius: 5px;
  //-webkit-border-radius: 5px;
  //border-radius: 5px;

  z-index: 9999999;
}

.searchresult > .nano-pane > .nano-slider {
  background: $custom-color2;
  opacity: .8;
  position: relative;
  margin: 0 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.searchresult:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility: visible \9
; /* Target only IE7 and IE8 with this hack */
  opacity: 0.99;
}

.ui-helper-hidden-accessible {
  display: none;
}


//new search
#_desktop_search{
  // z-index: 1;
}
.white-overflow {
  background: rgba(0, 0, 0, 0.4);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 18;
  cursor: pointer;
  @include media-breakpoint-down(sm){
    z-index: 0;
    display: none !important;
  }
}
.search-widget {
  width: 79.5%;
  float: right;
  @include media-breakpoint-down(md) {
    width: 89.5%;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#_search {
  @include media-breakpoint-down(sm) {
    color: #333333;
    cursor: pointer;
    i {
      line-height: 32px !important;
      font-size: 35px;
    }
  }
}

.header-top {
  .search-widget {
    form {
      //max-width: 500px;
      position: relative;
      // border: 1px $custom-color7;
      // border-style:solid none solid solid;
      background-color: #fff;
      display: block;
      margin: 0 auto;
      @include border-radius(50px);
      @include media-breakpoint-down(sm){
        margin: 12px 10px;
      }
   
      input[type=text] {
        height: 38px;
        // border: none;
        border: 1px $custom-color7;
        border-style:solid none solid solid;
        @include border-radius(50px 0 0 50px);
        width: calc(100% - 47px);
        // padding: 10px !important;
        padding: 10px 0 10px 20px;
        font: 400 15px $font-custom;
        /*
        background: #fff;
        */
        color:#000;
        &::-webkit-input-placeholder {
          color: $custom-color2;
          font: 400 12px/22px $font-custom3;
          opacity: 1;
      }
      &::-moz-placeholder {
          color: $custom-color2;
          font: 400 12px/22px $font-custom3;
          opacity: 1;
      }
      &::-ms-placeholder {
          color: $custom-color2;
          font: 400 12px/22px $font-custom3;
          opacity: 1;
      }
      &::placeholder {
          color: $custom-color2;
          font: 400 12px/22px $font-custom3;
          opacity: 1;
      }
        &::-ms-clear {
          display: none;
        }
      }
  
      button[type=submit] {
        position: absolute;
        margin:0 auto;
        // border: none;
        border: 1px $custom-color6;
        border-style:solid none solid solid;
        bottom: 0;
        right: 0px;
        color: white;
        height: 38px;
        width: 47px;
        background: $custom-color6;
        padding: 10px !important;
        @include border-radius(0 50px 50px 0);
  
        &:hover {
         // background-color: darken($custom-color, 10%);
          cursor: pointer;
          background-color: $custom-color5;
        }
        &:focus{
          outline: unset;
        }
  
        .search {
          background-image: url(../img/search.png);
          background-repeat: no-repeat;
          width: 22px;
          height: 24px;
          content: " ";
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

// #checkout {
//   #search_widget {
//     display: none; /* Not ideal solution by allows to reuse same hooks/templates */
//   }
// }

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.page-not-found {
  .search-widget {
    form {
      display: inline-block;
    }
  }
}

.searchresult-wrap {
  position: absolute;
  top: 38px;
  right: 7px;
  width: 98%;
  display: none;
  background: #ffffff;
  @include border-radius(5px);
  z-index: 100;
  overflow: hidden;
  // border: 1px solid $gray-light;
  @include media-breakpoint-down(sm) {
    top: 90px;
  }
  .suggestions,
  .popular {
    .category {
      margin-bottom: 5px;
    }
  }
  @include media-breakpoint-up(sm){
    .suggestions {
      min-height: 121px;
    }
  }

  .results {
    padding: 10px 0 1rem;
    display: none;
  }
  ul {
    li {
      z-index: 2;
      position: relative;
      list-style-image: none !important;
      .category a, .product {
        cursor: pointer;
      }
    }
  }
  .product-image {
    background: #fff;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      display: none;
    }
    img {
      max-height: 120px;
      width: auto;
    }
  }
  .loading {
    color: $brand-primary;
    font: bold 14px $font-custom;
    padding: 20px 15px;
    display: none;
    .load,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}



#header .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content.ui-corner-all, .searchresult-wrap ul {
  padding: 0;
  position: static !important;
  border: none;
  display: none;
  width: 100% !important;
  @media (max-width: 768px) {
    &.ui-autocomplete {
      display: block !important;
    }
  }
  li {
    a {
      font-size: 13px;
      font-weight: bold;
      font-family: $font-custom;
      color: #252833;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      // padding: 10px;
      .search-name {
        width: calc(100% - 250px);
        margin-right: 2px;
        overflow: hidden;
        @media (max-width: 1024px) {
          width: calc(100% - 145px);
        }
        @media (max-width: 768px) {
          width: calc(100% - 100px);
        }
        @include media-breakpoint-down(sm) {
          width: calc(100% - 80px);
        }
      }
      .stock-text {
        margin-left: auto;
        text-transform: uppercase;
        font: 600 10px/30px $font-custom;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      @include media-breakpoint-down(md) {
        width: calc(100% - 10px);
      }
      @include border-radius(0);
      @media (max-width: 910px) {
        font-size: 10px;
      }
      &.ui-state-focus {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
      }
      .product:hover {
        //background: rgba(255, 105, 0, .2);
        i {
          color: $brand-primary;
        }
        // &:before {
        //   background: rgba(255, 105, 0, .2);
        //   content: " ";
        //   display: inline-block;
        //   position: absolute;
        //   top: 0;
        //   width: calc(100% - 155px);
        //   left:0;
        //   bottom: 0;
        //   @include media-breakpoint-down(sm) {
        //     display: none;
        //   }
        // }
        // &:after {
        //   border-top: 16px solid transparent;
        //   border-bottom: 16px solid transparent;
        //   border-left: 14px solid rgba(255, 105, 0, .2);
        //   content: " ";
        //   display: inline-block;
        //   position: absolute;
        //   top: 0;
        //   left: calc(100% - 155px);
        //   @media (min-width: 768px) and (max-width: 910px) {
        //     border-top: 11px solid transparent;
        //     border-bottom: 11px solid transparent;
        //     border-left: 11px solid rgba(255, 105, 0, .2);
        //     right: -11px;
        //   }
        //   @include media-breakpoint-down(sm) {
        //     display: none;
        //   }
        // }
      }

    }
    .category {
      margin-top: 10px;
      padding: 0 10px 0 15px;
      line-height: 30px;
      font: bold 13px $font-custom;
      color: $brand-primary;
      i {
        font-size: 21px;
        margin-right: 10px;
        color: #ccc;
        &.arrow {
          margin-right: 0;
        }
      }
      a {
        color: $brand-primary;
      }
      span {
        color: #a1a5b5;
      }
    }
    .product {
      padding: 0 10px 0 15px;
      display: flex;
      position: relative;
      width: calc(100% - 1px);
      font-weight: 400;
      line-height: 30px;
      i {
        font-size: 21px;
        line-height: 30px;
        margin-right: 10px;
        color: #ccc;
      }
      &.sugg {
        color: #a1a5b5;
        .highlight {
          color: #252833;
        }
      }
      .price {
        font: bold 13px/30px $font-custom;
        color: $brand-primary;
        float: right;
        background: #fff;
        @include media-breakpoint-down(sm) {
          position: absolute;
          right: 0;
          top: 0;
          background: #fff;
          padding-left: 10px;
          font-size: 12px;
        }
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .image {
      display: none;
    }
  }
    > li.ui-menu-item {
      &:first-of-type {
        &:before {
          background: #eee;
          left: 10px;
          right: 10px;
          top: -10px;
          height: 1px;
          position: absolute;
          content: " ";
          display: block;
        }
      }
    }
}


/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: scroll;
      height: 100%;
      background: $gray-light;
      z-index: 4;
      padding: 0 20px;
      .search-close {
        // color: #fff;
        // background: #ff7311;
        cursor: pointer;
        margin: auto;
        padding: 15px 20px;
        width: 100%;
        > i {
          float: right;
          // font-size: 32px;
          // margin-right: 5px;
        }
      }
      form {
        input[type="text"] {
          background: #fff;
          border: none;
        }
        button[type=submit] {
          top: 0;
          right: 0;
          background: $brand-primary;
          margin: 0;
          // @include border-radius(0 3px 3px 0);
        }
      }
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes hideElement {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes hideElement {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

@keyframes showElement {
  to {
    width: 0;
    height: 0;
    //overflow:visible;
    visibility: visible;
  }
}

@-webkit-keyframes showElement {
  to {
    width: 0;
    height: 0;
    visibility: visible;
  }
}

// #header {
//   #_desktop_top_menu > ul > li > a {
//     -webkit-transition: opacity 0.8s ease-in-out;
//     -moz-transition: opacity 0.8s ease-in-out;
//     -ms-transition: opacity 0.8s ease-in-out;
//     -o-transition: opacity 0.8s ease-in-out;
//     opacity: 1;
//   }
// }

#header.search-open {
  // .header-nav {
  //   display: none;
  // }
  // #_desktop_top_menu > ul > li > a {
  //   opacity: 0;
  //   animation-delay: 1s;
  // }
  #search_widget {
    // margin-bottom: 9px;
    // margin-left: 0;
    // width: 74%;
    // -webkit-transition: width 0.8s linear;
    // -moz-transition: width 0.8s linear;
    // -o-transition: width 0.8s linear;
    // -ms-transition: width 0.8s linear;
    // transition: width 0.8s linear;
    // animation-delay: 1s;
  }
  form input[type=text]{
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.false-search {
  position: relative;
  cursor: pointer;
  ::-webkit-input-placeholder {
    color: #32394d;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #32394d;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #32394d;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #32394d;
  }
  .falses {
    background: #eff1f7;
    width: 100%;
    border: 0;
    font: 700 12px $font-custom;
    border-radius: 3px;
    color: #32394d;
    padding: 8px 30px 8px 15px;
  }
  .false-search-btn {
    position: absolute;
    top: 4px;
    right: 6px;
    i {
      color: #ff7311;
    }
  }
}