#products {
  // color: $gray;
  // margin:0 -5px 0 -5px;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .up {
    margin-bottom: 1rem;
    .btn-secondary {
      color: $gray;
      text-transform: inherit;
      .material-icons {
        margin-right: 0;
      }
    }
  }
}
#categories_block_left ul li {
  a {
    text-transform: uppercase !important;
  }

  ul {
    li {
      a {
        text-transform: initial !important;
      }
    }
  }
}
#categories_block_left {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .title_block {
    font: 600 18px $font-custom;
    margin-bottom: 0;
    margin-top: 5px !important;
  }
  ul {
    li {
      a {
        // text-transform: uppercase !important;
        color: #000;
      }
    }
  }
  ul {
    li {
      padding: 10px 0 10px 0;
      ul {
        li.open {
          ul {
            li.open {
              a {
                font-weight: 400;
              }
            }
          }
        }
      }
      > a {
        font-weight: unset;
        margin-bottom: 15px;
        // text-transform: initial !important;
      }
    }
    li.open > a {
      font-weight: 600;
    }
    li.open > a.selected {
      color: #ed1c24 !important;
    }
  }
}
#categories_block_left .block_content ul li ul li {
  padding: 8px 0 0 0 !important;
}

.block-category {
  position: relative;
  // margin-top: 40px;
  border: none;
  padding: 0;
  box-shadow: none;
  margin: 24px 0 35px 0;
  .h1 {
    display: none;
    padding: 6px 0 5px 3px;
  }
  .category-info {
    font: 600 20px $font-custom2;
    color: $custom-color6;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .category-name {
    font: 600 16px $font-custom2;
    color: $custom-color3;
  }
  #category-description,
  #category-description-short {
    font: 400 14px $font-custom2;
    color: $custom-color7;

    p strong {
      font-weight: 600;
      color: $custom-color7;
    }
    p {
      font: 400 14px $font-family;
      margin: 0 0 1rem !important;
      // padding: 0px;
      text-align: unset !important;
      font-family: $font-family !important;
      font-size: 14px !important;
      background-color: #ffffff;
      line-height: 1.7;
      // margin: 0 0 1rem !important;
      // padding: 0px !important;
      // text-align: justify !important;
      // font-family: $font-family !important;
      // font-size: 14px !important;
      // background-color: #ffffff !important;

      // &:last-child {
      //   background: linear-gradient(to top,#eee, #000 );
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   .read-more, .read-less {
      //     font: 600 14px $font-custom2;
      //     color: #004db1;
      //     margin-left: 7px;
      //     cursor: pointer;
      //     background: linear-gradient( to top, #004db1, #004db1 );
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }
      // }
    }
    .read-more,
    .read-less {
      font: 600 14px $font-custom2;
      color: $custom-color6;
      margin-left: 7px;
      cursor: pointer;
      .down-angle {
        &:before {
          content: "";
          display: inline-block;
          background-image: url("../img/read-more-arrow-down.png");
          background-repeat: no-repeat;
          width: 10px;
          height: 7px;
        }
      }
    }
    .read-less {
      margin-left: 0px;
    }
  }

  #category-description-short {
    // .read-more, .read-less {
    //   font: 600 14px $font-custom2;
    //   color: #004db1;
    //   margin-left: 7px;
    //   cursor: pointer;
    // }
  }

  h1 {
    padding-left: 3px;
    font-size: 500 25px $font-custom2;
  }

  .category-cover {
    //margin:0;
    padding: 0;

    img {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 0;
    }
  }
  /*
  .category-cover {
    position: absolute;
    right: 0.75rem;
    bottom: 0;
    
    img {
      width: 141px;
      height: 180px;
    }
    
  }
  */
}

.products-selection {
  .sort-right {
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(sm) {
      display: block;
      display: inline-block;
      padding-top: 0;
      vertical-align: middle;
      width: 51% !important;
      // padding: 0;
      .products-sort-order .select-title {
        min-width: 100%;
        max-width: 100%;
      }
    }
    @include media-breakpoint-down(xs) {
      width: 49% !important;
      padding-left: 0;
    }
  }
  .sort-by-row {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .products-sort-order {
      display: flex;
      justify-content: flex-end;
      max-width: 182px;
      @include media-breakpoint-down(sm) {
        display: block;
        max-width: 100%;
      }
    }
  }
  .sort-by-row,
  .nbr-products {
    // text-align: left;
    // display: inline-block;
    // font: 400 12px $font-custom2;
    // color: #222222;
    // display: inline-block;
    // vertical-align: top;
    // //padding-top: 7px;
    // margin-right: 20px;

    label {
      // font: 400 12px $font-custom2;
      // color: #222222;
      // margin-right:8px;
    }
  }

  .nbr-products {
    margin-right: 0;
    label {
      font: 400 12px $font-family;
    }
  }

  .sort-by {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    white-space: normal;
    font: 400 12px $font-custom2;
    color: black;
    // width: 100%;
  }

  @include media-breakpoint-down(sm) {
    .sort-by-row,
    .filter-button {
      display: inline-block;
      padding-top: 0;
      vertical-align: middle;
      width: 100%;
      // margin-right:0;
      .products-sort-order {
        padding: 0;
      }
    }
  }

  #af_nb_items {
    color: black;
    background: none;
    cursor: pointer;
    border: 1px solid #d7d7d7;
    border-radius: unset;
    font: 400 12px $font-custom2;
    margin-left: 0;
    // width: 53px;
    width: 51px;
    padding: 5px 3px 3px 6px;
  }

  .colums-type {
    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
      text-align: center;
    }
    @media (max-width: 500px) {
      display: none;
    }

    label {
      font: 400 16px/18px $font-custom;
      color: #595a5c;
    }

    .show_grid {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray;
      @include border-radius(5px);
      width: 33px;
      height: 33px;
      margin-right: 5px;
      cursor: pointer;

      &:before {
        background: url("../img/grid-products.png") no-repeat;
        width: 15px;
        height: 16px;
        content: "";
        display: inline-block;
      }

      &:hover,
      &.active {
        border-color: $custom-color;
        &:before {
          background-image: url("../img/grid-products-active.png");
        }
      }
    }
    .show_list {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray;
      @include border-radius(5px);
      width: 33px;
      height: 33px;
      margin-right: 5px;
      cursor: pointer;
      &:before {
        background: url("../img/list-products.png") no-repeat;
        width: 15px;
        height: 14px;
        margin-top: 2px;
        content: "";
        display: inline-block;
      }
      &:hover,
      &.active {
        border-color: $custom-color;
        &:before {
          background-image: url("../img/list-products-active.png");
        }
      }
    }
  }
}
.products-selection2 {
  padding-bottom: 8px;
  h1 {
    padding-top: 35px;
    font: 600 20px $font-custom2;
    text-transform: uppercase;
    color: $custom-color6;
  }
}

#af_nb_items {
  color: #232323;
  background: none;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  font: 400 12px $font-custom2;
  margin-left: 0;
  width: 55px;
  padding: 3px 0px 3px 10px;
}

.products-sort-order {
  color: $gray;
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  .select-title {
    display: inline-block;
    width: 100%;
    min-width: 137px;
    max-width: 175px;
    color: black;
    padding: 6px 0px 1px 10px;
    cursor: pointer;
    border: 1px solid #d7d7d7;
    border-radius: unset;
    font: 400 12px $font-custom2;

    i {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      font-size: 18px;
      line-height: 0.7;
    }

    @include media-breakpoint-down(sm) {
      padding: 9px 7px 6px;
    }
    @include media-breakpoint-down(xs) {
      padding: 9px 4px 6px;
    }
  }

  .select-list {
    display: block;
    color: $gray-darker;
    padding: $small-space $medium-space;
    &:hover {
      background: $custom-color2;
      color: white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    top: 102%;
    left: auto;
    width: 16.88rem;
    background: $gray-lighter;
    border: none;
    border-radius: 0;
    @include box-shadow;
    margin: 0;
    font-size: 12px;
  }
}

.facet-dropdown {
  color: $gray;
  padding-left: 0;
  padding-right: 0;
  background: $gray-light;
  border: 3px solid transparent;
  box-sizing: border-box;
  @include box-shadow;
  &.open {
    border: 0;
    > .select-title {
      border: 3px solid $custom-color2;
      background: $gray-lighter;
    }
  }
  .select-title {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
    color: $gray-darker;
    background: $gray-light;
    padding: $small-space $small-space $small-space $medium-space;
    cursor: pointer;
    > i {
      margin-left: auto;
    }
  }
  .select-list {
    display: block;
    color: $gray-darker;
    background: $gray-lighter;
    padding: $small-space $medium-space;
    &:hover {
      background: $custom-color2;
      color: white;
      text-decoration: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    left: auto;
    width: 100%;
    background: $gray-lighter;
    border: none;
    @include box-shadow-light;
  }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  @include box-shadow;
  margin-bottom: 1.563rem;
  background: white;
  padding: 1.563rem 0;
  .facet {
    .collapse {
      display: block;
    }
    padding-top: $small-space;
    .facet-title {
      color: $gray;
    }
    .facet-label {
      margin-bottom: 0;
      a {
        margin-top: 0.4375rem;
        color: $gray-darker;
        display: inline-block;
        font-size: $font-size-lg;
      }
    }
  }
}

#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;
    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  display: flex;
  margin-top: 12px;
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
  .previous-pagination {
    &::before {
      content: "";
      background-image: url("../img/prev-pagination.png");
      background-repeat: no-repeat;
      width: 4px;
      height: 8px;
      display: block;
    }
  }
  .next-pagination {
    &::before {
      content: "";
      background-image: url("../img/next-pagination.png");
      background-repeat: no-repeat;
      width: 4px;
      height: 8px;
      display: block;
    }
  }
  .count-products {
    font: 300 14px/16px $font-custom2;
    color: #222222;
    span {
      font-weight: 600;
    }
  }

  > div:first-child {
    display: flex;
    align-items: center;
    font: 400 12px $font-family;
  }

  .page-list {
    padding: 0;
    margin-bottom: 0;
    float: right;
    @include box-shadow-light;

    li {
      display: inline-block;
      vertical-align: middle;
      @include media-breakpoint-down(xs) {
        margin: 0 2px;
      }
      .spacer {
        margin: 0 5px;
        @include media-breakpoint-down(xs) {
          margin: 0;
        }
      }
    }
  }

  a {
    color: #222222;
    font: lighter 16px $font-custom2;
    display: block;
    padding: 7px 0;
    width: 32px;
    height: 32px;
    border: 1px solid #efefef;
    @include media-breakpoint-down(xs) {
      // font-size: 12px;
      // line-height: 12px;
      // padding: 6px 11px;
      font-size: 12px;
      padding: 9px 10px;
      // .next, .previous{
      //   i{
      //     margin-top: -6px;
      //     margin-left: -5px;
      //   }
      // }
    }

    &:not(.previous):not(.next) {
      // border: 1px solid $gray;
      // @include border-radius(5px);
    }

    &.next,
    &.previous {
      i {
        margin-top: -3px;
        @include media-breakpoint-down(xs) {
          margin-top: -6px;
          margin-left: -5px;
        }
      }
    }
  }

  i.next {
    &:before {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #595a5c;
      content: "";
      display: block;
      margin-top: 5px;
    }
  }

  i.prev {
    &:before {
      margin-top: 5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #595a5c;
      content: "";
      display: block;
    }
  }

  .disabled {
    color: $gray;
  }

  .current a {
    background: $custom-color6;
    color: white;
    // @include border-radius(5px);
  }
}

.active_filters {
  background: #dededd;
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }
  ul {
    display: inline;
  }

  .filter-block {
    @include box-shadow;
    color: $gray-darker;
    margin-right: $small-space;
    margin-bottom: $small-space;
    background: white;
    padding: 0.625rem;
    display: inline-block;
    font-size: $font-size-xs;

    .close {
      color: $gray-darker;
      //font-size: $font-size-lg;
      opacity: 1;
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
    }
  }
}

.block-categories {
  /*
  @include box-shadow;
  @include border-radius(5px);
  background-color: #fff;
  margin-bottom: 1.563rem;
  */
  padding: 0;
  margin-bottom: 0;

  .category-contents {
    padding-bottom: 0;
  }

  .category-top-menu {
    margin-bottom: 0;
  }

  .category-sub-menu {
    .category-sub-link {
      font-size: 12px;
      border-bottom: unset;
    }

    li {
      position: relative;
      &[data-depth="1"] {
        padding-left: 10px;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 5px;
        }
      }
    }

    li[data-depth="0"] > a {
      width: 100%;
      display: inline-block;
      padding: 15px 0 13px 0;
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;
      &::before {
        content: "-";
        margin-right: 0.3125rem;
      }
    }
  }

  .category-top-menu {
    h6 {
      font: 600 13px $font-custom;
      border-bottom: 1px solid #d7d7d7;
      padding: 40px 0 12px 0;
      margin-bottom: 0;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }

    > li:first-child {
      padding: 0;
    }
  }

  a {
    color: black;
    font: 400 12px $font-custom2;
    padding-left: 20px;
    border-bottom: 1px solid #d7d7d7;

    &:hover {
      color: black;
    }

    &.h6 {
      color: $custom-color3;
      border-bottom: 1px solid #d7d7d7;
      font: 500 13px $font-custom2;
      text-transform: none;
      display: block;
      padding-left: 0;
      padding-bottom: 15px;
      margin-bottom: 0;
      margin-top: 35px;
    }
  }

  .collapse-icons {
    position: absolute;
    right: 0;
    top: 7px;
    padding: 0;
    cursor: pointer;

    &[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $custom-color2;
      }
    }
    .remove {
      display: none;
    }
  }

  .arrows {
    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;
      &:hover {
        color: $custom-color2;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }
      .arrow-down {
        display: inline-block;
      }
    }
  }
}

.page-search {
  #wrapper {
    margin-top: 25px;
  }
}

.page-category #content-wrapper,
.page-search #content-wrapper,
.page-manufacturer #content-wrapper,
.page-new-products #content-wrapper,
.page-prices-drop #content-wrapper {
  .product_show_list {
    width: 100%;
    .product-thumbnail img {
      margin-left: 0;
    }
  }
}
.af-total-count:before,
.count:before,
.count:after,
.af-total-count:after {
  display: none;
}

#advanced_layered_navigation_filter {
  .af_filter {
    margin-bottom: 0;
    border-bottom: none;
    border: none;
    margin: 8px 0;
    /*
    margin-bottom: 20px;
    background: #fff;
    @include border-radius(5px);
    */

    .af_subtitle_heading {
      /*
      padding: 5px 15px 0;
      */
      padding: 0;
    }

    .af_subtitle {
      //color: $custom-color3;
      //font: 400 18px $font-custom2;
      color: black;
      font: 600 13px $font-custom2;
      text-transform: none;
      display: block;
      margin: 0;
      text-align: left;
      border-bottom: 1px solid #d7d7d7;
      padding: 12px 0;
      margin-bottom: 14px;
      text-transform: uppercase;
      &:before {
        display: none;
        top: 27px !important;
      }
    }

    .af_filter_content {
      .name {
        // padding-left: 3px;
        // padding-top: 3px;
        font: 400 12px $font-custom2;
        color: black; // #222222;
        display: inline-block;
        vertical-align: middle;
      }
      .count-number {
        .parenthesis {
          font: 400 12px $font-family;
        }
        .count {
          font: 400 12px $font-custom2;
          display: inline-block;
          color: black;
        }
      }
      .custom-checkbox {
        font-size: 12px;
        input[type="checkbox"] {
          margin-top: 0;
          + span {
            display: inline-block;
            width: 10px;
            height: 10px;
            vertical-align: middle;
            cursor: pointer;
            border: 1px #d7d7d7 solid;
            .checkbox-checked {
              display: none;
              margin: 0;
              font-size: 0.5rem;
              color: $custom-color3;
              background: $custom-color3;
            }
          }

          &:checked + span {
            .checkbox-checked {
              display: block;
            }
          }

          &:focus + span {
            border-color: $gray;
          }
        }

        label {
          text-align: left;
        }
      }
    }

    label {
      margin-bottom: 0;
      display: flex;

      .af-toggle-child {
        padding: 0 5px;
        flex-grow: 2;
        text-align: end;
      }
    }

    .toggle-cut-off {
      bottom: 0;

      span {
        font: 300 14px $font-custom !important;
      }
    }

    &.full .toggle-cut-off {
      bottom: -0.5em;
    }
    li {
      padding: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 7px;
  }
}

.af_filter.type-1 .af_filter_content,
.af_filter.type-2 .af_filter_content {
  max-height: 173px !important;
  overflow-y: auto;
}
.af_filter.full.type-1 .af_filter_content,
.af_filter.full.type-2 .af_filter_content {
  max-height: none !important;
  margin-bottom: 15px;
}
.af_filter.expandable:not(.full) .af_filter_content {
  margin-bottom: 25px !important;
}
.banner-category {
  margin-bottom: 35px;
  .banners {
    .banner {
      margin-right: -7px;
      margin-left: -7px;
      .banner-image {
        padding-right: 7px;
        padding-left: 7px;
        img {
          border-radius: unset;
        }
      }
    }
  }
}
.banner-category.top-category {
  img {
    margin-top: 24px;
    width: 100%;
  }
}

.banner-left {
  display: block;
  margin-bottom: 70px;
  @include media-breakpoint-up(lg) {
    img {
      width: 275px;
    }
  }
  img {
    max-width: 100%;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.selectedFilters {
  background-color: #fff;
  // padding: 10px;
  /*
  @include box-shadow;
  */
  @include border-radius(6px);

  @include media-breakpoint-down(sm) {
    border: none;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0;
    background: transparent;
    @include border-radius(0);
  }

  a {
    color: #222222;
  }

  .af_title {
    color: $custom-color3;
    font: 600 14px $font-custom2;
    text-transform: none;
    display: block;
    margin: 0;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  > div {
    padding: 0 !important;
    background-color: #fff !important;
  }

  .clearAll {
    @include media-breakpoint-down(sm) {
      display: inline-block;
      border: 1px solid #ddd;
      @include border-radius(6px);
      margin-right: 7px;
      background-color: #eee !important;
    }

    > a {
      position: static !important;
      display: inline-block;
      padding: 7px;
      font: 400 11px $font-custom2;
      background-color: #545454;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 5px;

      i {
        font-size: 14px;
        // margin-left: 6px;
        // margin-top: 3px;
        // float:right;
      }

      @include media-breakpoint-down(sm) {
        text-align: center;
        padding: 4px;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;

        i {
          font-size: 14px;
          color: #b7b2b2;
        }
      }
    }
  }

  .cf {
    font: lighter 14px $font-custom2;
    color: #222;
    padding: 5px 0px !important;

    a,
    i {
      font-size: 14px;
      font-weight: normal;
      margin-left: 6px;
      margin-top: 3px;
    }

    @include media-breakpoint-down(sm) {
      display: inline-block;
      // margin: 7px 7px 0 0;
      padding: 4px !important;
      font-size: 14px;
      border: 1px solid #ddd;
      // @include border-radius(6px);
      a.icon-times.close {
        margin-top: 0;
        position: unset;
        padding: 0;
      }
    }
  }
}

#js-product-list-top {
  @include media-breakpoint-down(md) {
    /*
    padding: 0;
    */
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 1rem;
    &.fixed {
      position: fixed;
      background: #fff;
      z-index: 1;
      border-bottom: 1px solid $custom-color3;
      padding: 10px 0;
      left: 0;
      right: 0;
      margin-bottom: 0;
      top: 75px;

      .h1 {
        display: none;
      }
      @media (max-width: 340px) {
        top: 45px;
      }
    }
  }
  // @include media-breakpoint-down(xs) {
  //   &.fixed {
  //     top: 90px;
  //   }
  // }
}

#js-product-list {
  hr {
    margin-bottom: 0;
    margin-top: 9px;
  }
  > .products.row {
    padding: 0 7px;
    // margin-right: -16px;
    // margin-left: -16px;
    // @include media-breakpoint-down(md){
    //   margin-right: -3px;
    //   margin-left: -3px;
    // }
    // @media (max-width: 430px){
    //   margin-left: -9px;
    //   margin-right: -9px;
    // }
    // @media (max-width: 390px){
    //   margin-left: -4px;
    //   margin-right: -4px;
    // }
    .product {
      margin-bottom: 14px;
      max-width: 100%;
      width: 20%;
      padding: 0;
      border-top: 1px solid $gray-border;
      border-bottom: 1px solid $gray-border;
      border-left: 1px solid $gray-border;
      // &:nth-child(5n){
      border-right: 0.5px solid $gray-border;
      // }
      @include media-breakpoint-down(lg) {
        width: 25%;
      }
      @include media-breakpoint-down(md) {
        width: 33.33333%;
      }
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
      @media (max-width: 359px) {
        width: 100%;
      }
    }
  }
  article {
    // border: 1px solid $gray-border;
    // border-top: 1px solid $gray-border;
    // border-bottom: 1px solid $gray-border;
    // border-left: 1px solid $gray-border;
    // padding-left: 16px;
    // padding-right: 16px;
    // @include media-breakpoint-down(md){
    //   padding-left: 3px;
    //   padding-right: 3px;
    // }
    // @media (max-width: 430px){
    //   padding-left: 9px;
    //   padding-right: 9px;
    // }
    // @media (max-width: 390px){
    //   padding-left: 4px;
    //   padding-right:4px;
    // }
    // @media (max-width: 359px){
    //   width: 100%;
    // }
  }
  .back-to-top {
    border-radius: unset;
    padding: 8px;
    font-size: 12px;
    i {
      font-size: 20px;
    }
  }
}

// @include media-breakpoint-up(md){
//   #js-product-list {
//     > .row {
//       margin-right: -3px;
//       margin-left: -3px;
//     }
//     article {
//       /*
//       padding-left: 3px;
//       padding-right: 3px;
//       */
//     }
//   }
// }

.facets-title {
  color: $gray-darker;
}

.products-selection {
  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

// .layout-left-column,
// #module-allcategories-view,
// #module-customoffers-view,
// #module-customoffers-list {

//   #content-wrapper {
//     /*
//     max-width: 890px;
//     */
//     max-width: 1000px;
//     @include media-breakpoint-up(lg) {
//       float: right;
//     }

//     @include media-breakpoint-down(sm) {
//       width: 100%;
//     }
//   }
// }

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #search_filter_toggler-top {
    /*
    margin: 10px;
    display: block;
    */
    display: inline;
    margin: 0 !important;
  }
  #category,
  #search {
    #left-column {
      width: 100%;
      padding: 0;
    }
    #content-wrapper {
      width: 100%;
    }
  }
  #category {
    #left-column {
      width: 100%;
      padding: 0;

      #search_filters_wrapper {
        /*
        margin-left: -30px;
        margin-right: -30px;
        */
        margin: 0 !important;
        padding: 0px 7px;
        .view-filter,
        .close-filter {
          border-radius: unset;
          padding: 3px 6px;
        }
      }

      #search_filters_wrapper .mobile-filters {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0 0 0;
      }

      #search_filters_wrapper .mobile-filters .btn-primary {
        padding: 6px 10px;
        margin: 0;
      }

      #search_filters_wrapper .mobile-filters .close-filter {
        background: #818181 !important;
      }

      #search_filters_wrapper .mobile-filters .btn-primary i {
        margin: 0 !important;
      }

      #search_filter_controls {
        text-align: center;

        button {
          margin: 0 0.5rem;
        }

        margin-bottom: 1rem;
      }

      #search_filters {
        margin-bottom: 0;
        box-shadow: none;
        padding: 0;
        border-top: 1px solid $gray-lighter;
        .facet {
          .title {
            cursor: pointer;
            .collapse-icons .remove {
              display: none;
            }
          }
          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }
              .remove {
                display: block;
              }
            }
          }
          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;
          .facet-title {
            color: $gray-darker;
            text-transform: uppercase;
          }

          .h6 {
            margin-bottom: 0;
            padding: 0.625rem;
            display: inline-block;
          }
          .navbar-toggler {
            display: inline-block;
            padding: 0.625rem 0.625rem 0 0;
          }
          .collapse {
            display: none;
            &.in {
              display: block;
            }
          }
          .facet-label {
            a {
              margin-top: 0;
            }
          }
          ul {
            margin-bottom: 0;
            li {
              border-top: 1px solid $gray-lighter;
              padding: 0.625rem;
            }
          }
        }
      }
    }
    #content-wrapper {
      width: 100%;
    }
  }
  .products-sort-order {
    width: 150px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .select-title {
      margin-left: 0;
    }
  }
  .products-selection2 {
    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: 1rem;
    }
    .showing {
      padding-top: 1rem;
    }
  }
  #prices-drop #content-wrapper,
  #new-products #content-wrapper,
  #best-sales #content-wrapper {
    width: 100%;
  }
}
.filter-button {
  width: 49% !important;
}
#search_filter_toggler {
  font: 400 12px $font-custom;
  // display: inline-block;
  // width: 100%;
  // color: #818181;
  //padding: 6px 0.625rem;
  padding: 9px;
  // cursor: pointer;
  border: 1px solid #d7d7d7;
  // border-radius: 5px;
  text-align: left;
}

@include media-breakpoint-down(md) {
  #category {
    #left-column {
      max-width: 100%;
    }
  }
}
@include media-breakpoint-down(xs) {
  //.products-selection {
  //  .filter-button {
  //    padding-left: 0;
  //  }
  //}
  // #category {
  //   #left-column {
  //     max-width: 100%;

  //     #search_filters_wrapper {
  //       margin-left: -15px;
  //       margin-right: -15px;
  //     }

  //     #search_filters_wrapper btn {
  //       width:50%;

  //     }
  //   }
  // }
}

#category,
#search,
#prices-drop {
  #products .thumbnail-container,
  .featured-products .thumbnail-container,
  .product-miniature .thumbnail-container {
    margin-left: 0;
    margin-right: 0;
    border-right: unset;
  }
  #content-wrapper {
    padding-left: 77px;
    @media (max-width: 1440px) {
      padding-left: 30px;
    }
    @media (max-width: 1384px) {
      padding-left: 7px;
    }
    article {
      .product-thumbnail {
        padding: 0;
      }
      .product-flag.discount {
        // width: 53px;
        // @media (max-width: 1305px){
        //   width: 56px;
        // }
        // @media (max-width: 396px){
        //   width: 60px;
        // }
        // @include media-breakpoint-down(lg){
        //   width: 56px;
        // }
      }
    }
  }
  .viewedproduct_slider {
    .products-recently-viewed {
      // box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
    }
    .thumbnail-container {
      border-right: 1px solid #e2e2e2;
      .product-thumbnail {
        padding-top: 0;
        img {
          padding: 4px 22px 4px 22px;
        }
      }
    }
  }
  .choose_variant {
    @media (max-width: 922px) {
      width: 110px;
    }
  }
}
