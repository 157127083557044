body#checkout {
  color: $gray-darker;

  #header .header-nav {
    max-height: none;
    padding: 1rem 0;
    border: none;
    margin-bottom: 0;
    @include box-shadow;
    .logo {
      max-height: 4.375rem;
      width: auto;
    }
  }

  .nav-inline .nav-item + .nav-item {
    @include media-breakpoint-down(sm){
      margin-left: .5rem;
    }
    @media (max-width: 660px) {
      margin-left: 0;
    }
  }

  .store-informations {
    border: none;
    text-align: right;
    width: 100%;
    margin: 0px;
    padding: 0;
    .email-store,
    .phone-store {
      margin-left: 20px;
      float: none;
      width: auto;
      text-align: left;
      .icon {
        display: inline-block;
        @media (max-width: 660px) {
          display: none;
        }
      }
    }
  }

  .order-options {
    label {
      text-align: left;
    }
  }

  .custom-checkbox {
    display: flex;
    span {
      flex: 0 0 20px;
      margin-top: 0.1875rem;
    }
  }

  a:hover {
    color: #1a8196;
  }

  section#content {
    margin-bottom: 1.563rem;
  }

  .container {
    min-height: 100%;
  }

  section.checkout-step {
    /*
    @include box-shadow;
    background-color: white;
    */
    @include border-radius(8px);
    padding: 0.9375rem;

    &:last-child {
      border: 0;
    }

    .step-title {
      text-transform: uppercase;
      cursor: pointer;
      margin-bottom: 0;
      font-family: $font-custom;
      font-weight: 300;

      .done {
        padding: 0;
        margin: -5px .2rem 0;
      }
    }

    .content {
      padding: 0 2.15rem;
      font-family: $font-custom;
      p {
        font-weight: 300;
        color: #5f6062;
      }
    }

    .step-edit {
      text-transform: lowercase;
      font-weight: normal;
      .edit {
        font-size: $base-font-size;
      }
    }

    .not-allowed {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .content,
    .done,
    .step-edit {
      display: none;
    }

    &.-current.-reachable.-complete {
      .done,
      .step-edit {
        /*
        display: none;
        */
      }
      .step-number {
        display: inline-block;
      }
      .content {
        display: block;
      }
    }

    &.-reachable.-complete.-unreachable {
      border: 0;
      background: none;
      box-shadow: none;

      h1 {
        .done {
          display: inline-block;
        }

        .step-number {
          display: none;
        }

        .step-edit {
          cursor: pointer;
          display: block;
          float: right;
          margin-right: 0.125rem;
          color: $gray;
        }        
      }
      .content {
        display: none;
      }
    }

    &.-current {
      @include box-shadow;
      background: white;
      
      .content {
        display: block;
      }
    }    

    small {
      color: $gray;
    }
    .default-input {
      min-width: 40%;
      &[name=address1],
      &[name=address2] {
        min-width: 60%;
      }
    }
    
    .radio-field {
      margin-top: 1.875rem;
      label {
        display: inline;
      }
    }
    .checkbox-field div {
      margin-top: 3.75rem;
    }
    .checkbox-field + .checkbox-field div {
      margin-top: 0;
    }
    .select-field div {
      background: $gray-lighter;
      padding: 0.625rem 3.125rem;
    }
    .form-footer {
      text-align: center;
    }
    .payment-option {
      label {
        text-align: left;
      }
    }
    #conditions-to-approve {
      padding-top: 1rem;
      span, label {
        display: inline-block;
        vertical-align: middle;
      }
      label {
        margin-top: .45rem;
        margin-left: 14px;
        max-width: calc(100% - 42px);
        clear: none;
        text-align: left;
      }
    }
    .payment-options {
      padding-top: 1rem;
      label {
        display: table-cell;
        height: 30px;
        img{
          height: 29px;
        }
      }
      .custom-radio {
        margin-right: $medium-space;
      }
      .payment-option {
        margin-bottom: 0.5rem;
      }
    }
    .step-number {
      display: inline-block;
      padding: 0.625rem;
    }
    .address-selector {
      //display: flex;
      //justify-content: space-between;
      //align-items: flex-start;
      //flex-wrap: wrap;
      @extend .row;
    }
    .address-item {
      @extend .col-xs-12;
      @extend .col-sm-12;
      @extend .col-md-12;
      @extend .col-lg-6;
      .address-container {
        background: $gray-lighter;
        margin-bottom: $small-space;
        border: 1px solid $gray;
      }
      &.selected {
        .address-container {
          background: white;
          border: $custom-color2 1px solid;
        }
      }
    }
    .h4 {
      font-weight: 300;
    }
    .address-alias {
      display: inline-block;
      font-weight: 300;
      margin-bottom: 0.625rem;
      font-family: $font-custom;
    }
    .custom-radio {
      vertical-align: top;
    }
    .address {
      margin-left: 1.4rem;
      font-weight: 300;
      min-height: 120px;
    }
    .radio-block {
      padding: 0.9375rem;
      text-align: left;
      cursor: pointer;
    }
    .custom-radio {
      margin-right: 0;
      input[type="radio"] {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
    .delete-address,
    .edit-address {
      color: $gray;
      display: inline-block;
      margin: 0 0.3125rem;
      .delete,
      .edit {
        font-size: $base-font-size;
      }
    }
    hr {
      margin: 0;
    }
    .address-footer {
      text-align: center;
      padding: 0.625rem;
    }
    //#delivery-addresses,
    //#invoice-addresses {
    //  margin: 1.25rem 0;
    //}
    .add-address {
      margin-top: 1.25rem;
      a {
        color: $gray-darker;
        i {
          font-size: $font-size-lg;
        }
      }
    }
    .delivery-option {
      background: $gray-lighter;
      padding: 0.9375rem 0;
      margin-bottom: 0.9375rem;
      label {
        text-align: inherit;
        margin-bottom: 0;
        .carrier-logo{
          img{
            max-width: 46px;
          }
        }
      }
    }
    .carrier-delay,
    .carrier-name {
      display: inline-block;
      word-break: break-word;
      text-align: left;
    }
    #customer-form,
    #delivery-address,
    #invoice-address,
    #login-form {
      margin: 1.563rem 0;
      .form-control-label {
        text-align: left;
        color: #5f6062;
      }
      .radio-inline {
        padding: 0;
        margin-right: 10px;
      }
    }
    .sign-in {
      font-size: $font-size-sm;
    }
    .forgot-password {
      margin-left: 0;
      text-align: center;
      font: 14px $font-custom;
      margin-bottom: 15px;
    }
    .input-group .input-group-btn > .btn {
      padding: .77rem 1rem;
      margin: 0;
      height: 38px;
    }
  }
  .additional-information {
    font-size: $font-size-sm;
    margin-left: 2.875rem;
    margin-bottom: 1.25rem;
  }
  .condition-label {
    margin-left: 2.5rem;
    margin-top: 0.625rem;
    label {
      text-align: inherit;
    }
  }
  .cancel-address {
    margin: 0.625rem;
    display: block;
    color: $gray;
    text-decoration: underline;
  }
  .modal-content {
    padding: $medium-space;
    background-color: $gray-light;
  }
  #cart-summary-product-list {
    font-size: $font-size-sm;
    img {
      border: $gray-light 1px solid;
      width: 3.125rem;
    }
    .media-body {
      vertical-align: middle;
    }
  }
  #order-summary-content {
    padding-top: 0.9375rem;
    h4.h4 {
      margin-top: 0.625rem;
      margin-bottom: 1.25rem;
      color: $gray-darker;
    }
    h4.black {
      color: #000000;
    }
    h4.addresshead {
      margin-top: 0.1875rem;
    }
    .noshadow {
      box-shadow: none;
    }
    #order-items {
      border-right: 0;
      h3.h3 {
        color: $gray-darker;
        margin-top: 1.25rem;
      }
      table {
        tr {
          &:first-child {
            td {
              border-top: 0;
            }
          }
        }
      }
    }

    .order-confirmation-table {
      padding: 1rem;
      margin-bottom: 2rem;
      background-color: #fff;
      border: 3px solid #e5e5e5;
      border-radius: 0;
    }
    .summary-selected-carrier {
      margin-bottom: 0.75rem;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      border-radius: 0;
      padding: 1rem;
    }
    .step-edit {
      display: inline;
      color: $gray;
    }
    .step-edit:hover {
      cursor: pointer;
    }
    a {
      .step-edit {
        color: $gray;
      }
    }
  }
  #gift_message, #delivery {
    max-width: 100%;
    border-color: $gray-darker;
    textarea {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
}

/**** ORDER CONFIRMATION *****/
#order-details {
  padding-top: $large-space;
  > .card-title {
    margin-bottom: $large-space;
  }
  ul {
    margin-bottom: $medium-space;
    li {
      margin-bottom: $small-space;
    }
  }
}

#order-items {
  hr {
    border-top-color: $gray;
  }
  table {
    width: 100%;
    margin-top: 10px;
    tr {
      height: $large-space;
      @media (max-width: 400px) {
        margin-bottom: 10px;
        display: block;
        height:auto;
      }
      td {
        &:last-child {
          text-align: right;
        }
        @media (max-width: 400px) {
          &:first-child {
            color: $custom-color2;
          }
          display: block;
          text-align: left !important;
        }
      }
    }
  }
  .order-line {
    padding: .5rem 0;
    border-top: 1px solid $gray;
    @media (max-width: 600px){
      > .row-eq-height {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      .qty {
        display: inline-block;
        width: 100%;
        float: none;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-top: 20px;
        position: static;
      }
    }
    &:last-of-type {
      border-bottom: 1px solid $gray;
    }
  }
  .image {
    img {
      max-width: 100%;
      border: 1px solid $gray-lighter;
    }
  }
  .details {
    span {
      font-weight: 300;
    }
    .customizations {
      margin-top: 0.625rem;
    }
  }
}

#order-confirmation {
  #registration-form {
    width: 100%;
    margin: 0 auto 1rem;
  }
}

@include media-breakpoint-down(md) {
  .done {
    margin: 0;
    padding: 0;
  }
  body#checkout section.checkout-step .address-item {
    flex-grow: 1;
  }
  body#checkout section.checkout-step .delivery-option-2 {
    flex-direction: column;
  }
  .delivery-option {
    display: flex;
    margin: auto;
    .custom-radio {
      flex: 0 0 auto;
    }
  }
  .condition-label {
    label[for="conditions_to_approve[terms-and-conditions]"] {
      text-align: left;
    }
  }
  #order-confirmation {
    #registration-form {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  body#checkout section.checkout-step.-reachable.-complete h1 .step-edit {
    float: none;
    margin-top: 0.25rem;
    margin-left: $medium-space;
  }
  body#checkout {
    #header .header-nav {
      max-height: none;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      .logo {
        max-height: 45px;
      }
    }
    section.checkout-step {
      .content {
        padding: 0.9375rem;
      }
    }
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
  #order-items {
    border-right: 0;
    margin-bottom: 2.5rem;
    @include media-breakpoint-down(xs){
      margin-bottom: 0;
    }
    hr {
      border-top-color: $gray-light;
    }
  }
  .bold {
    font-weight: bold;
  }
  #order-details {
    padding-left: 0.9375rem;
    .card-title {
      border-bottom: 1px solid $gray-light;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }

}

@include media-breakpoint-down(xs) {
  body#checkout {
    section.checkout-step {
      .content {
        padding: 0.9375rem 0;
      }
    }
  }
  #payment-confirmation {
    button {
      font-size: 0.875rem;
      &.btn {
        white-space: normal;
      }
    }
  }
}

.cart-empty {
  .cart-summary {
  }
}

.js-payment-binary {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}

#content-hook_order_confirmation {
  margin-top: 20px;
}

.wait-submit {
  display: none;
  position: fixed;
  z-index: 11111111;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  background: rgba(0,0,0,.5);
  .wait-text {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -150px;
    img {
      max-width: 100%;
    }
  }
}

#checkout-guest-form {
  .custom-checkbox label {
    margin-bottom: 0;
    line-height: 23px;
  }
  .input-group .input-group-btn > .btn {
    padding: .77rem 1rem;
  }
}

body#checkout section.checkout-step.-reachable.-complete h1 .done{
  display: inline-block;
}

body#checkout section.checkout-step {
  border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
  border-radius: unset;
}