.contact-rich {
  color: $gray;
  margin-bottom: 2rem;
  word-wrap: break-word;

  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 2rem;
    font-weight: 300;
  }

  .block {
    height: auto;
    overflow: hidden;
    
    .icon {
      float: left;
      width: 3.5rem;
      i {
        font-size: 2rem;
      }
    }

    .data {
      color: $gray-darker;
      font-size: $font-size-sm;
      width: auto;
      overflow: hidden;
      &.email{
        padding-top: 0.375rem;
      }
    }
  }
}

.contact-form {
  background: white;
  padding: 1rem;
  color: $gray;
  width: 100%;
  margin-bottom: 2rem;
  h3 {
    text-transform: uppercase;
    color: $gray-darker;
    font-weight: 300;
  }
}

.page-contact {
  #wrapper {
    margin: 2rem 0 0;
  }
  .input-group .input-group-btn > .btn {
    padding: 1.15em 1rem;
    height: 38px;
    margin: 0;
  }
}
select::-ms-expand {
  display: none;
}