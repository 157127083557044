#product {
  #content {
    position: relative;
    margin-bottom: 0;
    margin-right: 7px;
    .product-flags{
      position: absolute;
      top: 0;
      z-index: 1;
      margin-top: 17px;
      margin-left: 17px;  
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        margin-top: 2px;
      }
    }
    .product-flag{
      margin-bottom: 8px;
      &.out_of_stock{
        display: none;
      }
    }
    .product-flag.discount{
      span{
        background-image: url('../img/discount-ellipse-product.png');
        background-repeat: no-repeat;
        width: 53px;
        // width: 41px;
        height: 54px;  
        color: #fff;
        font: 400 14px $font-family;
      }
    }
    .product-flag.new{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      span{
          background-image: url('../img/new-ellipse-product.png');
          background-repeat: no-repeat;
          width: 53px;
          // width: 41px;
          height: 54px;
          color: #fff;
          text-transform: uppercase;
          font: 400 14px $font-family;
          margin-right: 9px;
      }
    }
    .product-flag.pack{
        display: none;
    }
  }
  .product-manufacturer-logo {
    padding: 10px 45px 10px 45px;
    float: right !important;
    margin: 0 0 20px 20px !important;
    width: unset !important;

    @media (max-width: 442px) {
      float: left !important;
      margin: 0 20px 20px 0px !important;
      // margin: auto 0 0 !important;
      // width: 57% !important;
    }
  }
  .block_newsletter{
    margin-top: 0;
  }
}
.product-fags{
  #custom-flags{
    margin-bottom: 10px;
    span{
      background-color: $brand-primary;
      color: #fff;
      font: 400 13px "Spartan", sans-serif;
      color: #fff;
      border-radius: 4px;
      padding: 4px 7px;
      text-transform: lowercase;
    }
  }
}

article{
  .image-container{
    position: relative;
  }
  .product-fags{
    position: absolute;
    bottom: 0;
    margin-left: 9px;
    #custom-flags{
      margin-bottom: 5px;
      span{
        font: 400 11px "Spartan", sans-serif;
        padding: 3px 3px 2px 3px;
      }
    }
  }
}
.product-price {
  color: #828181;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;

  .regular-price {
    text-decoration: line-through;
    color: #999999;
    font: 500 18px $font-custom2;
    sup {
      top: -14px;
      font-size: 14px;
    }
  }

  &.has-discount {
    .current-price {
      color: $custom-color6;
      font: 600 25px $font-custom2;

      sup {
        top: -9px;
        font-size: 14px;
      }
    }
    > div {
      display: inline-block;
    }
  }
}

.facebook-promo {
  font: 300 14px $font-custom2;
  color: #222222;
  margin-top: 10px;
  a {
    margin-left: 10px;
  }
}

#product-description-short {
  color: $gray-darker;
}
#delivery{
  .product-description-short{
    p{
      font-size: 14px;
    }
    h2, h1{
      font-size: 16px;
    }
  }
}
.image-modal{
  position: relative;
  .principal-arrows .arrow-modal-left, .principal-arrows .arrow-modal-right{
    position: absolute;
    top: 50%;
  }
  .principal-arrows .arrow-modal-left{
    left: 0
  }

  .principal-arrows .arrow-modal-right{
    right: 0;
  }

}

.product-information {
  font: lighter 14px $font-custom2;
  color: $gray-darker;
  display: flex;
  flex-direction: column;

  .manufacturer-logo {
    // height: 35px;
  }
  .product-description img {
    max-width: 100%;
  }
  .stores {
    font: 400 15px $font-family;
    a {
      color: #000;
    }
    .arrow-right {
      &::before {
        content: "";
        background-image: url("../img/sageata.png");
        background-repeat: no-repeat;
        display: inline-block;
        width: 7px;
        height: 9px;
        margin-left: 5px;
      }
    }
  }
  #short_description_block {
    #short_description_content {
      font: 400 14px $font-family;
      color: #000;
      .anchor.read-more {
        i {
          vertical-align: middle;
          margin-bottom: 5px;
          margin-left: 5px;
        }
      }
    }
  }
  .text-price-attributes {
    font: 500 30px $font-custom;
    color: #d21616;
    vertical-align: middle;
  }
  .product-additional-infos {
    .add-remove-favorites,
    .compare-action {
      display: inline-block;
      vertical-align: top; //middle;
      margin-left: 3px;

      span {
        display: none;
        vertical-align: middle;
        font: 300 14px/22px $font-custom;
        color: #595a5c;
        margin-left: 10px;
      }
    }

    .favorite {
      display: inline-block;
      vertical-align: middle;
      width: 70px;
      height: 56px;
      border: 1px solid #969696;
      border-color: $custom-color2;
      position: relative;
      @include border-radius(8px);

      &:after {
        position: absolute;
        left: 50%;
        margin-left: -12px;
        top: 50%;
        margin-top: -8px;
        background-image: url("../img/heart-p.png");
        background-repeat: no-repeat;
        width: 23px;
        height: 19px;
        display: inline-block;
        content: " ";
      }

      &.active {
        color: $custom-color5;
        &:after {
          background-image: url("../img/heart-p-active.png");
        }
      }

      @include media-breakpoint-down(sm) {
        width: 52px;
        height: 42px;
      }
    }

    .compare {
      display: inline-block;
      vertical-align: middle;
      width: 59px;
      height: 50px;
      border: 1px solid #969696;
      border-color: $custom-color2;
      @include border-radius(8px);
      position: relative;

      &:after {
        position: absolute;
        left: 50%;
        margin-left: -7.5px;
        top: 50%;
        margin-top: -6.5px;
        background-image: url("../img/compare-p.png");
        background-repeat: no-repeat;
        width: 15px;
        height: 13px;
        display: inline-block;
        content: " ";
      }
    }

    .add_to_compare.checked {
      .compare {
        &:after {
          background-image: url("../img/compare-p-active.png");
        }
      }
    }
  }
}

.store-informations {
  border-top: 1px solid #595a5c;
  border-bottom: 1px solid #595a5c;
  padding: 10px 0;
  margin-top: 10px;

  @media (max-width: 1200px) {
    text-align: center;
  }
  .open-hours,
  .phone-store,
  .email-store {
    display: inline-block;
    vertical-align: middle;
    color: #595a5c;
    font: 300 14px/22px $font-custom;
    .icon,
    .data {
      display: inline-block;
      vertical-align: middle;
    }
    a {
      display: block;
      color: inherit;
    }
  }

  .open-hours {
    @media (max-width: 1200px) {
      width: 33%;
      span {
        display: block;
      }
    }

    @media (max-width: 961px) {
      width: 100%;
      text-align: center;
      line-height: 25px;
      border-bottom: 1px solid #595a5c;
      padding-bottom: 10px;
      margin-bottom: 10px;
      span {
        height: 24px;
        display: inline-block;
      }
    }
  }

  .phone-store {
    margin-left: 20px;
    @media (max-width: 1220px) {
      margin-left: 10px;
    }
    @media (max-width: 1060px) {
      margin-left: 0px;
    }
    .icon {
      &:before {
        background: url("../img/call-center-operator.png") no-repeat;
        width: 19px;
        height: 21px;
        content: " ";
        display: block;
      }
    }
  }

  .email-store {
    .icon {
      &:before {
        background: url("../img/mail-copy.png") no-repeat;
        width: 23px;
        height: 19px;
        content: " ";
        display: block;
      }
    }
  }

  .email-store {
    float: right;
    @media (max-width: 1200px) {
      float: none;
      margin-left: 30px;
    }
    @media (max-width: 1021px) {
      margin-left: 10px;
    }
    @media (max-width: 961px) {
      margin-left: 0;
    }
  }

  .phone-store,
  .email-store {
    .icon {
      margin-right: 10px;
    }
    @media (max-width: 961px) {
      width: 46%;
      text-align: left;
      .data {
        text-align: left;
      }
    }
  }
}

.product-add-to-cart {
  .add-to-cart {
    display: block;
    width: 100%;
    background: $custom-color6;
    font-weight: 400;

    .shopping-cart {
      content: "";
      display: inline-block;
      vertical-align: middle;
      background: url("../img/shopping-cart-white.png") no-repeat;
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }

    .text {
      display: inline-block;
      vertical-align: top;
      font: light 18px $font-custom;
      margin-left: 5px;
    }
  }
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

.input-container {
  position: relative;
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.input-color,
.input-radio {
  &:checked + span,
  &:hover + span {
    border: 2px solid $gray-darker;
  }
}

.radio-label {
  @include box-shadow;
  background: white;
  display: inline-block;
  padding: 0.125rem $small-space;
  font-weight: 600;
  border: 2px solid white;
}

.product-actions {
  width: 100%;
  display: inline-block;
  vertical-align: bottom;
  .control-label {
    margin-bottom: 0.375rem;
    display: block;
    width: 100%;
  }
}

.product-additional-infos {
  display: flex;
  flex-direction: row;
  @media (max-width: 1352px){
    flex-direction: column;
  }
  @include media-breakpoint-down(md){
    flex-direction: row;
  }
  .fav-box{
    // flex-grow: 1;
    @media (max-width: 1352px){
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(md){
      margin-bottom: 0;
    }
  }
  .line-border{
    flex-grow: 0;
    align-self: center;
    margin: 0 20px;
    @media (max-width: 1477px){
      margin: 0 7px;
    }
    @media (max-width: 1352px){
      display: none;
    }
    @include media-breakpoint-down(md){
      display: block;
    }
    .border-right{
      content: '';
      background-image: url('../img/line-border.png');
      display: block;
      display: block;
      width: 1px;
      height: 16px;
      background-repeat: no-repeat;
    }
  }
  .price-alert{
    // flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    @media (max-width: 1352px){
      justify-content: flex-start;
    }
    @include media-breakpoint-down(md){
      justify-content: flex-end;
    }
  }
}

.product-quantity {
  @media (max-width: 1136px){
    display: flex;
  }
  @media (max-width: 1110px){
    flex-wrap: wrap;
  }
  // @include media-breakpoint-down(md){
  //   justify-content: flex-end;
  // }
  .qty {
    float: left;
    margin-right: 5px;
    .bootstrap-touchspin {
      float: none;
      position: relative;
      box-shadow: none;
      width: 100%;
      .input-group-btn-vertical {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
        .btn {
          position: absolute;
          top: 0;
          width: 25px;
          border: none;
          background: none;
          @include media-breakpoint-down(sm) {
            top: -3px;
            padding: 15px;
            i {
              font-size: 24px;
            }
          }
        }
        .bootstrap-touchspin-down {
          left: 2px;
          i:after {
            content: "\E15B";
          }
        }
        .bootstrap-touchspin-up {
          right: 2px;
          i:after {
            content: "\E145";
          }
        }
      }
    }
  }

  .add {
    float: left;
    .view-combinations {
      padding: 12px 29px !important;
    }
    .add-to-cart {
      margin: 0;
      background: $custom-color6;
      padding: 13px 33px;
      @include border-radius(50px);
      border: none;
      @media (max-width: 1256px){
        padding: 13px 22px;
      }
      @media (max-width: 1168px){
        padding: 13px 20px;
      }
      @media (max-width: 1152px){
        padding: 13px 18px;
      }
      &:hover {
        background: $custom-color5;
      }
      &:focus {
        outline: unset;
      }
      .text {
        display: inline-block;
        vertical-align: top;
        font: 400 18px $font-custom;
        text-transform: uppercase;
        @media (max-width: 387px) {
          font: 300 15px $font-custom;
        }
      }
    }
  }

  #quantity_wanted {
    height: 48px;
    padding: 0.175rem 0;
    width: calc(100% - 22px);
    border: none;
    text-align: center;
    color: #959595;
    background-color: $gray-light;
    font: 300 17px/18px $font-custom;
  }

  .input-group-btn-vertical {
    float: left;
  }
  .input-group-btn-vertical {
    .btn {
      padding: 20px 0.6875rem;
      i {
        font-size: 1rem;
        top: 16px;
        left: 0.1875rem;
        &.touchspin-down {
          top: 18px;
        }
      }
    }
  }
  .btn-touchspin {
    height: auto;
  }
}
.table-add-to-cart {
  // float: right;
  text-align: right;
  .add-to-alert-modal {
    margin-left: 0;
    float: right;
    .modal-body{
      .tabs{
        padding-top: 0;
      }
    }
    .add-to-alert {
      border-radius: 8px;
      background-color: $custom-color5;
      border: none;
      padding: 6px 31px;
      span {
        font-size: 12px;
      }
    }
  }
}
#product {
  .qty {
    .bootstrap-touchspin {
      border-radius: 50px;
      background-color: $gray-light;
      position: relative;
      .input-group-btn {
        width: unset;
        border-radius: 50px;
      }
      .btn-touchspin {
        border-radius: 50px;
        color: #000;
        font: 400 20px $font-custom3;
        padding: 0 6px;
        background-color: $gray-light;
        &:hover {
          background-color: unset;
        }
        @media (max-width: 387px) {
          padding: 0 4px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
  .product-quantity #quantity_wanted {
    height: 49px;
    width: 89px;
    font: 400 20px $font-custom3;
    color: #000;
    border-radius: 50px;
  }
  .product-additional-infos {
    .add-remove-favorites {
      ul{
        margin-bottom: 0;
      }
      li {
        a{
          span{
            font: 400 12px $font-family;
            color: #000;
          }
        }
        i.add-fav {
          vertical-align: middle;
          vertical-align: middle;
          width: 26px;
          height: 26px;
          display: inline-block;
          &::after {
            background-image: url("../img/product-heart-outline.png");
            background-repeat: no-repeat;
            width: 26px;
            height: 26px;
            display: inline-block;
            content: " ";
          }
        }
        i.remove-fav {
          vertical-align: middle;
          vertical-align: middle;
          width: 26px;
          height: 26px;
          display: inline-block;
          &::after {
            background-image: url("../img/product-heart.png");
            background-repeat: no-repeat;
            width: 26px;
            height: 26px;
            display: inline-block;
            content: " ";
          }
        }
      }
    }
  }
}
.product-discounts {
  margin-bottom: 1.5rem;
  > .product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }
  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: 0.3125rem $gray-light solid;
      text-align: center;
    }
    tbody tr {
      background: $gray-lighter;
      &:nth-of-type(even) {
        background: white;
      }
      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}

.product-prices {
  // float: right;
  // display: flex;
  // width: 100%;
  // justify-content: flex-end;
  @include media-breakpoint-down(md){
    margin-top: 20px;
    // float: left;
    // justify-content: flex-start;
  }
  @include media-breakpoint-down(sm){
    // float: right;
    margin-top: 0;
    // justify-content: flex-end;
  }
  @include media-breakpoint-down(xs){
    margin-top: 20px;
    // float: left;
    // width: 100%;
    // justify-content: flex-start;
  }
  div {
    &.product-discount {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-top: 10px;
      .regular-price {
        // text-decoration: line-through;
        color: #999999;
        font: 500 18px $font-custom2;
        sup {
          top: -6px;
          font-size: 11px;
        }
      }

      &:after {
        background: #999999;
        height: 1px;
        display: block;
        position: absolute;
        top: 39%;
        left: 0;
        content: "";
        right: 0;
      }
    }
  }
}

// .product-actions-info{
//   float: right;
//   @include media-breakpoint-down(md){
//     float: left;
//   }
//   @include media-breakpoint-down(sm){
//     float: right;
//   }
//   @include media-breakpoint-down(xs){
//     float: left;
//   }
// }

.product-prices {
  .current-price {
    color: #d21616;
    font: 500 30px $font-custom2;

    sup {
      top: -14px;
      font-size: 14px;
    }
  }
}

.price-alert {
  display: inline-block;
  font: 400 12px $font-family;
  span{
    color: #000;
  }

  .icon-exclamation-triangle {
    background: url("../img/price-alert.png");
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.page-product .has-discount {
  &.product-price,
  p {
    color: #c92626;
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    // margin-left: 20px;
  }
  .discount {
    background: $custom-color6;
    color: #fff;
    font: 500 15px $font-family;
    padding: 4px 5px 2px 5px;
    vertical-align: middle;
    margin: 0 15px;
  }
  .regular-price {
    color: #c1c0c0;
  }
}

.product-unit-price {
  font-size: $font-size-xs;
  margin-bottom: 0;
}
.background-tabs{
  background: $background-index;
}
.tabs {
  padding-top: 2rem;
  #tab-content {
    background: $background-index;
  }
  .tab-pane {
    padding-top: 30px;
    .product-description-long{
      .short, .long{
        color: $custom-color7;
        line-height: 1.7 !important;
        font: 400 14px $font-family;
      }
      .read-more,.read-less{
        color: $custom-color6;
        font: 400 14px $font-family;
        cursor: pointer;
      }
    }
  }
  .tab-pane#description{
    ul{
      list-style: unset;
      li{
        font-size: 15px;
        list-style-position: inside;
      }
    }

  }

  .tabs-to-nav {
    background: $background-index;
  }
  .nav-tabs .nav-item + .nav-item {
    margin-left: 0;
  }

  .nav-tabs {
    border: none;
    border-bottom: none;
    padding: 16px 0 7px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #dddddd;
    @media (max-width: 660px) {
      float: none;
      display: block;
      text-align: center;
    }

    .nav-link {
      border: transparent;
      padding: 5px 30px;
      margin: 0;
      font: 500 20px $font-custom2;
      color: #2c2c2c;
      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }

      @include media-breakpoint-down(xs) {
        //margin: 0;
      }

      &.active {
        color: $custom-color6;
        background: $background-index;
        font: 600 20px $font-custom2;
      }
      &:hover {
        background: $background-index;
        color: $custom-color6;
        font: 600 20px $font-custom2;
      }
    }

    .nav-item {
      float: left;
      @media (max-width: 660px) {
        float: none;
        display: block;
        text-align: center;
      }

      &:first-child {
        .nav-link {
          padding-left: 0;
          @media (max-width: 660px) {
            padding-left: 30px;
          }
        }
      }
      &:last-child {
        .nav-link {
          padding-right: 0;
          @media (max-width: 660px) {
            padding-right: 30px;
          }
        }
      }
    }
  }
  .product-description,
  #product-details,
  .product-attachments {
    p {
      color: #222;
      font: lighter 14px $font-custom2;
    }
    p.h6{
      font-size: 0.9375rem;
      font-weight: 600;
    }
  }
  .product-description {
    p,
    ul {
      line-height: 1.5em;
    }
    ul {
      list-style-type: disc;
      padding-left: 20px;
    }
    &.has-video {
      p {
        &:last-of-type {
          width: calc(100% - 650px);
          float: left;
        }

        &:first-child {
          float: none;
          width: 100%;
        }
      }
    }
  }
  #youtubevideo {
    float: right;
    iframe {
      width: 640px;
      height: 350px;
    }
  }
}

.header-product-name{
  border-bottom: 1px solid $gray-border;
  @include media-breakpoint-down(sm){
    margin-top: 25px;
  }
}

.images-container {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.product-cover {
  position: relative;
  width: 77%;
  @include box-shadow;
  @include media-breakpoint-down(md){
    width: 100%;
  }
  &.single-image {
    max-width: 100%;
    width: 100%;
  }
  img {
    background: white;
  }
  .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    text-align: center;
    cursor: pointer;
    @include transition(opacity 0.7s ease-in-out);

    .zoom-in {
      font-size: 6.25rem;
      color: $custom-color2;
    }

    &:hover {
      opacity: 1;
    }
  }
}
.prod-imgs-slider{
  width: 18%;
  margin-right: 17px;
  @media (max-width: 1600px){
    display: flex;
    flex-shrink: 3;
  }
  @include media-breakpoint-down(md){
    display: none;
  }
  li.thumb-container{
    border: 1px solid $custom-color8 !important;
    @include box-shadow;
  }
  .product-images {
    margin-bottom: 0;
  }
  .slick-slider{
    border: 1px solid transparent;
  }
  .slick-slide{
    margin-bottom: 16px;
    width: 100% !important;
    img.thumb{
      padding: 20px;
      cursor: pointer;
      opacity: 0.3;
      filter: alpha(opacity=30); /* For IE8 and earlier */
      max-width: 134px;
      max-height: 134px;
      @media (max-width: 1457px){
        max-width: 120px;
        max-height: 120px;
      }
      @media (max-width: 1277px){
        max-width: 100px;
        max-height: 100px;
      }
      @media (max-width: 1032px){
        max-width: 80px;
        max-height: 80px;
      }
      &.selected,
      &:hover {
        opacity: 1;
        filter: alpha(opacity=100); /* For IE8 and earlier */
      }
    }
    div{
      max-height: 134px;
      @media (max-width: 1457px){
        max-width: 120px;
      }
      @media (max-width: 1277px){
        max-width: 100px;
      }
      @media (max-width: 1032px){
        max-width: 80px;
      }
    }
  }
  .slick-slide.slick-current{
    img.thumb{
        opacity: 1;
        filter: alpha(opacity=100); /* For IE8 and earlier */
    }
  }
  .slick-prev.slick-arrow{
    left: 44%;
    top: 100%;
    transform: rotate(270deg);
    z-index: 1;
  }
  .slick-next.slick-arrow{
    right: 44%;
    top: -24px;
    transform: rotate(270deg);
  }
  .slick-prev, .slick-next{
    width: 15px;
    height: 20px;
    &:before {
      content: '';
      float: none;
      background: url("../img/left-arrow.png") no-repeat;
      width: 8px;
      height: 19px;
      display: block;
    }
  }
  .slick-next{
    &:before {
      background: url("../img/right-arrow.png") no-repeat;
      
    }
  }
}

#product-modal {
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;
    .modal-body {
      display: flex;
      margin-left: -30%;
      .product-cover-modal {
        background: white;
      }
      .image-caption {
        background: white;
        width: 800px;
        padding: 0.625rem 1.25rem;
        border-top: $gray-light 1px solid;
        p {
          margin-bottom: 0;
        }
      }
      .thumbnails {
        position: relative;
        .close {
          position: absolute;
          top: 0;
          right: -50px;
          background: #000;
          color: #fff;
          @include border-radius(50%);
          opacity: 1;
          padding: 6px;
          font-size: 19px;
        }
      }
      .mask {
        position: relative;
        overflow-y: hidden;
        max-height: 47rem;
        margin-top: 2.188rem;
        z-index: 1;
        &.nomargin {
          margin-top: 0;
        }
      }
      .product-images {
        margin-left: $extra-large-space;
        list-style-type: none;
        display: block;
        float: left;
        height: 100%;
        li.thumb-container {
          margin: 10px;
        }
        img {
          width: 9.25rem;
          cursor: pointer;
          background: white;
          &:hover {
            border: $custom-color2 3px solid;
          }
        }
      }
      .arrows {
        height: 100%;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        color: white;
        right: $large-space;
        z-index: 0;
        .arrow-up {
          position: absolute;
          top: -2rem;
          opacity: 0.2;
        }
        .arrow-down {
          position: absolute;
          bottom: -1rem;
        }
        i {
          font-size: 6.25rem;
          display: inline;
        }
        cursor: pointer;
      }
    }
  }
}

#blockcart-modal {
  color: #666;
  .modal-header {
    background: black;
    .close {
      margin-top: 2px;
      color: #fff;
      opacity: 1;
      margin-left: 15px;
    }
  }
  .modal-body {
    background: #fff;
    padding: 1.875rem;
    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;
    }
    .product-title {
      margin-bottom: 1rem;
      color: #000;
      font: 300 17px $font-custom;
    }
    p {
      margin-bottom: 0.5rem;
      font: 300 14px $font-custom;
      color: #666;
      &.cart-products-count {
        color: #000;
        font-size: 17px;
        margin-bottom: 1rem;
      }
    }
  }
  .modal-dialog {
    max-width: 1140px;
    width: 100%;
  }
  .product-image {
    width: 100%;
  }
  .modal-title {
    font-weight: 400;
    color: white;
    font-size: 1.125rem;
    font-family: $font-custom;
    i.material-icons {
      margin-right: 15px;
    }
  }
  .product-name {
    color: $custom-color2;
    font-size: 1.125rem;
  }
  .cart-products-count {
    font-size: 1.125rem;
  }
  .cart-content {
    padding-left: $extra-large-space;
    text-align: center;

    .cart-content-btn {
      display: inline-flex;
      margin: 20px 0 0;

      button {
        margin-right: 0.3125rem;
      }

      .btn {
        white-space: inherit;
        padding: 0.8rem 2.25rem;
        @include border-radius(8px);
      }
    }
  }
  .divide-right {
    border-right: $gray 1px solid;
  }
}
// #link-stock{
  // scroll-margin-top: 300px;
// }

.product-images {
  > li.thumb-container {
    display: block;
    border: 1px solid $custom-color8;
    // margin-right: 20px;
    margin-bottom: 18px;
    background: #fff;
    width: 135px;
    height: 135px;
    overflow: hidden;
    float: left;

    > .thumb {
      @include box-shadow;
      cursor: pointer;
      opacity: 0.3;
      filter: alpha(opacity=30); /* For IE8 and earlier */
      max-width: 100%;
      width: 100%;
      padding: 20px;
      &.selected,
      &:hover {
        // border: 1px solid $custom-color8;
        opacity: 1;
        filter: alpha(opacity=100); /* For IE8 and earlier */
      }
    }
  }
}

#main {
  .images-container {
    .slick-list{
      max-height: 593px;
      height: 100%;
      .slick-track{
        height: 100%;
        .slick-slide{
          div{
            height: 100%;
          }
        }
      }
    }
    .cover-image{
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      img{
        box-shadow: unset;
      }
    }
 

    .js-qv-mask {
      &.scroll {
        float: left;
        @include media-breakpoint-down(md) {
          //width: calc(100% - 50px);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .slick-list {
        padding: 0 !important;
      }
    }
  }
}

.scroll-box-arrows {
  display: none;

  &.scroll {
    display: block;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  i {
    position: absolute;
    bottom: 1.625rem;
    height: 100px;
    line-height: 100px;
    cursor: pointer;
  }
  .left {
    top: 0;
    left: 7%;
    transform: rotate(90deg);
  }
  .right {
    bottom: 0;
    left: 7%;
    transform: rotate(90deg);
  }
}

//for product
.availability {
  display: inline-block;

  &.p-av {
    height: 20px;
  }

  #product-availability {
    font: 400 13px $font-custom2;
    color: #fff;
    @include border-radius(4px);
    padding: 4px 7px;
    text-transform: lowercase;
    &.product-available {
      /*
      background-color: $brand-success;
      */
      color: #fff;
      background-color: $stock;
    }

    &.product-unavailable {
      background-color: $brand-danger;
      color: #fff;
    }

    &.product-last-items {
      background-color: $brand-warning;
      color: #fff;
    }
  }
}

//for listing
.availability-listing {
  font: 400 11px $font-custom2;
  text-transform: uppercase;
  @media (max-width: 500px){
    right: 10px;
  }

  .product-available {
    color: $brand-success;
  }
  .product-unavailable {
    color: $brand-danger;
  }
  .product-last-items {
    color: $brand-warning;
  }
}

label {
  &.infos {
    /*
    font-size: 16px;
    color: $gray-darker;
    font-weight: 300;
    */
    // font-weight: bold;
    // color: black;
    // margin-bottom: 10px;
  }
}

.product-reference,
.product-manufacturer {
  span {
    font-size: 16px;
    color: $gray-darker;
    font-weight: 300;
    &.sku {
      text-transform: uppercase;
    }
    a {
      color: $gray-darker;
      font-size: 12px;
    }
  }
}
.product-manufacturer{
  display: inline-flex;
  label.infos{
    align-items: center;
    display: flex;
    margin-right: 10px;
  }
  img{
    height: auto;
    max-width: 80px;
  }
}
.social-media-product{
  display: inline-flex;
  margin-bottom: 40px;
  // @include media-breakpoint-down(sm){
    flex-wrap: wrap;
  // }
  label.infos{
    align-items: center;
    display: flex;
    margin-right: 17px;
    font: 400 14px $font-custom2;
    color: #999999;
    margin-bottom: 0;
  }
  a{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.delivery-truck-product{
  display: flex;
  flex-direction: row;
  img{
    height: 100%;
    margin-right: 18px;
  }
  .delivery-infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
      margin-bottom: 0;
    }
    .delivery{
     p{
      font: 600 14px $font-custom2;
      margin-bottom: 2px;
     } 
    }
    .delivery-time{
      p{
        font: 400 14px $font-custom2;
      }
    }
  }
}
.right-col{
  margin-top:  35px;
}

.product-attachments {
  margin-top: $medium-space;
  > dl.data-sheet {
    display: flex;
    //flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    font: 300 14px/16px $font-custom;
    border-top: 1px solid $gray-light;

    &:first-child {
      border-top: none;
    }

    dd.value,
    dt.name {
      flex: 1;
      font-weight: 300;
      padding: $small-space 0;
      word-break: normal;
      margin-bottom: 0;
      a {
        color: #232323;
        &:hover {
          color: $custom-color2;
        }
      }
    }
    dt.name {
      text-transform: capitalize;
    }
  }
}

.product-features {
  // .table-striped tbody tr:nth-of-type(even) {
  //   background-color: #fff;
  // }
  // td {
  //   text-align: center;
  // }
  // margin-top: 0;
  // .data-sheet{
  //   margin-bottom: 1rem;
  //   .name, .value{
  //     padding: 0.75rem
  //   }
  //   .data-sheet-row{
  //         background-color: #fff;
  //       }
  //   }
  // .data-sheet:nth-of-type(odd){
  //   .data-sheet-row{
  //     background-color: #fff;
  //   }
    
  // }
  // .data-sheet:nth-of-type(even){
  //   .data-sheet-row{
  //     background-color: #ECECEC;
  //   }
  // }
}

.product-features {
  margin-left: 0.3125rem;
  >dl.data-sheet {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      font: 400 18px $font-family;
      dd.value,
      dt.name {
          flex: 1 0 40%;
          font-weight: normal;
          background: #fff;
          padding: 5px;
          margin-right: $small-space;
          min-height: 2.5rem;
          word-break: normal;
          font-size: 16px;
          display: flex;
          align-items: center;
          &:nth-of-type(even) {
              background: $gray-lighter;
          }
        //   &:nth-of-type(odd) {
        //     background: #fff;
        // }
      }
      dt.name {
          text-transform: capitalize;
      }
  }
}

.product-attachments {
  dl.data-sheet:nth-of-type(even) {
    background-color: #fff;
  }
  dl.data-sheet:nth-of-type(odd) {
    background-color: $gray-lightest;
  }
  dl.data-sheet dt.name,
  dl.data-sheet dd.value {
    padding: 0.75rem;
    font: 400 16px $font-family;
  }
  dl.data-sheet dd.value {
    a {
      font: 400 16px $font-family;
      margin: 0;
      background: #ed2129;
      color: #fff;
      padding: 3px 20px;
      border-radius: 8px;
      border: none;
    }
  }
}
.background-slider{
  background-color: #f9f9f9;
  .slick-slider{
    // background-color: unset;
    // border: unset;
    .slick-track{
        float: left;
        // margin-left: 0;
        // margin-right: 0;
        // background-color: #fff;
        // border: 1px solid $gray-border;
        // .slick-slide{
        //     padding: 0;
        // }
    }
  }
}
.product-accessories1{
  .slick-track{
    float: left;
   
  }
}
.category-products,
.product-accessories1,
.product-category {
  // .row {
  //   margin-left: -16px;
  //   margin-right: -16px;
  //   article {
  //     padding-left: 16px;
  //     padding-right: 16px;
  //   }
  // }
  // @media (max-width: 430px){
  //   .row {
  //     margin-left: -9px;
  //     margin-right: -9px;
  //     article {
  //       padding-left: 9px;
  //       padding-right: 9px;
  //     }
  //   }
  // }
  // @media (max-width: 390px){
  //   .row {
  //     margin-left: -4px;
  //     margin-right: -4px;
  //     article {
  //       padding-left: 4px;
  //       padding-right:4px;
  //     }
  //   }
  // }
  .products {
    // margin-top: 40px;
  }
  // padding-top: 30px;
  background: #f9f9f9;
  .slick-track {
    // margin-left: 0;
    // margin-right: 0;
    // .slick-slide{
    //   padding: 0;
    // }
  }
  .accesories,
  .category-products {
    margin-bottom: 0;
    // .product-thumbnail {
    //   height: 229px;
    //   @include media-breakpoint-down(sm) {
    //     height: 187px;
    //   }
    // }
  }
  .productsliders-top {
    // border-bottom: 1px solid $custom-color;
    // margin-bottom: 0;
    .title {
      line-height: 0.1em;
      // margin: 10px 0 15px;
      text-align: center;
      font: 600 19px "Spartan", sans-serif;
      color: #000;
      text-transform: none;
      width: 100%;
      padding-top: 49px;
      margin-bottom: 30px;
      span {
        background: #f9f9f9;
        padding: 0 40px;
      }
    }
  }
  .thumbnail-container {
    margin-bottom: 0;
    .product-flags{
      left: 0 !important;
    }
    .product-description{
      .add-to-cart-listing{
        margin-bottom: 1rem;
      }
    }
    .product-thumbnail {
      img {
        margin: 0 auto;
      }
    }
  }
  button {
    &.slick-prev,
    &.slick-next {
      position: absolute;
      top: 47%;
      width: 43px;
      height: 33px;
      z-index: 5;
      cursor: pointer;
      right: -31px;
      background: #ededed;
      border-radius: 50%;
      padding: 30px 30px;
      opacity: 0.7;
      &:before {
        color: #000;
        content: " ";
        text-indent: 0;
        background: url("../img/next-arrow.png") no-repeat;
        height: 28px;
        width: 16px;
    
        display: block;
        margin: auto;

        position: absolute;
        top: 26%;
        left: 21px;
        opacity: 1;
      }
      &.swiper-button-disabled {
        display: none;
      }
    }

    &.slick-prev {
      right: auto;
      left: -26px;
      &:before {
        float: none;
        background: url("../img/prev-arrow.png") no-repeat;
        left: 18px;
      }
    }
  }
}

.product-variants {
  .control-label{
    font: 600 15px $font-family;
    color: #999999;
    // margin-top: 30px;
  }
  > .product-variants-item {
    margin: 18px 0 40px 0;
    select {
      background-color: white;
      width: auto;
      padding-right: 1.875rem;
      @include box-shadow-light;
    }
    ul li {
      // margin-right: $small-space;
      margin-right: 8px;
    }
    .color {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .attribute-image{
    max-width: 53px;
    max-height: 53px;
    border: 1px solid $gray-lighter;
  }
  .attribute-image.checked{
    border: 1px solid #ee1e46;
  }
}

.reduction-amount {
  width: 52px;
  background: $custom-color5;
  color: #fff;
  text-transform: lowercase;
  font: lighter 12px $font-custom2;
  padding: 2px 0;
  text-align: center;
  @include border-radius(5px);
}

#index .reduction-amount {
  // font-size: 15px;
  line-height: 15px;
}

.new-label {
  // position: absolute;
  // top: 12px;
  // left: 20px;
  margin-bottom: 3px;
  width: 52px;
  background: $custom-color4;
  font: 300 12px $font-custom2;
  color: #fff;
  padding: 1px 0;
  text-align: center;
  text-transform: uppercase;
  @include border-radius(5px);
}

.product-action {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 21px;
  left: 20px;
  width: 62px;
  text-align: right;
  @media (max-width: 573px){
    left: 10px;
  }

  .product-flags {
    position: static;
    margin-bottom: 7px;
  }

  &.page-of-product {
    top: 15px;
    z-index: 1;

    .product-flags {
      text-align: right;
    }

    .new-label,
    .reduction-amount {
      margin-bottom: 5px;
      padding: 0;
      font: 300 16px $font-family;
    }

    .reduction-amount {
      margin-bottom: 10px;
      text-align: center;
      width: 70px;
      background: #ed2129;
    }

    .new-label {
      width: 70px;
      text-align: center;
    }
  }

  .compare-action {
    margin: 0;
    max-width: unset !important;
    margin-left: 0 !important;
    i.compare {
      margin: 0;
    }
  }

  .compare-action,
  .add-remove-favorites ul {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 45px;
    margin-left: 17px;

    li {
      a,
      > div {
        position: relative;
        display: block;

        i {
          margin: 0 5px;
          display: block;
          // background: $custom-color2;
          color: #fff;
          font-size: 16px;
          width: calc(100% - 10px);
          text-align: center;
          height: 20px;
          line-height: 20px;
          @include border-radius(10px);

          &.compare {
            padding-top: 3px;

            &::after {
              background-image: url("../img/compare.png");
              background-repeat: no-repeat;
              width: 12px;
              height: 11px;
              display: block;
              content: " ";
              margin: 0 auto;
            }
          }
        }

        span {
          font: 300 9px/18px $font-custom;
          background: $custom-color2;
          color: #fff;
          position: absolute;
          // right: 52px;
          right: -46px;
          top: 2px;
          width: 75px;
          text-align: center;
          display: none;

          &:after {
            content: "";
            display: block;
            position: absolute;
            // right: -9px;
            right: 45px;
            top: 0;
            width: 0;
            height: 0;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            // border-left: 9px solid $custom-color2;
            border-right: 9px solid $custom-color2;
          }
        }
      }
      a.checked {
        i {
          &.compare {
            &::after {
              background-image: url("../img/compare-active.png");
            }
          }
        }
      }
    }
    // &:hover {
    //   li {
    //     a {
    //       span {
    //         display: block;
    //       }
    //     }
    //   }
    // }
  }
  .compare-action {
    margin-top: 5px;
    li {
      a {
        span {
          width: 45px;
        }
      }
    }
  }
}
.product-description {
  .h3.product-title {
    margin: 6px 0 10px;
  }
}
// Add to cart listing
.add-to-cart-listing {
  .compare-action,
  .add-remove-favorites ul {

    li {
      a,
      > div {
        position: relative;
        display: block;
        i {
          opacity: 0.988;
          background-size: 128%;
          color: black;
          &.compare {
            padding-top: 3px;
            &::after {
              background-image: url("../img/compare.png");
              background-repeat: no-repeat;
              width: 12px;
              height: 11px;
              display: block;
              content: " ";
              margin: 0 auto;
            }
          }
        }

        // Add to Favourites rollover effect

        span {
          font: 300 9px/18px $font-custom;
          background: $custom-color2;
          color: #fff;
          position: absolute;
          // right: 52px;
          right: -16px;
          top: 2px;
          width: 80px;
          text-align: center;
          display: none;
        }
      }

      a.checked {
        i {
          &.compare {
            &::after {
              background-image: url("../img/compare-active.png");
            }
          }
        }
      }
    }

    // &:hover {
    //   li {
    //     a {
    //       span {
    //         display: block;
    //       }
    //     }
    //   }
    // }
  }

  .compare-action {
    margin-top: 5px;

    li {
      a {
        span {
          width: 45px;
        }
      }
    }
  }
}
// End Add to cart

.product-customization {
  margin: $extra-large-space 0;
  .product-customization-item {
    margin: $medium-space 0;
  }
  .product-message {
    background: $gray-light;
    border: none;
    width: 100%;
    height: 3.125rem;
    resize: none;
    padding: 0.625rem;
    &:focus {
      background-color: white;
      outline: 0;
    }
  }
  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute;
  }

  .customization-message {
    margin-top: 20px;
  }
  .custom-file {
    position: relative;
    background: $gray-light;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    color: $gray;
    margin-top: $medium-space;
    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  small {
    color: $gray;
  }
}

.product-pack {
  margin-top: $extra-large-space;
  .pack-product-container {
    display: flex;
    justify-content: space-around;
    .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: $gray;
    }
    .pack-product-quantity {
      border-left: $gray-light 2px solid;
      padding-left: $small-space;
    }
    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-information {
  .product-comments,
  .product-reference,
  .product-manufacturer {
    margin: 3px 0 15px 0;
    label,
    span {
      font: 400 14px $font-custom2;
      color: #999999;
    }
    label {
      margin-bottom: 0;
      font: 400 14px $font-custom2;
      color: #999999;
    }
  }
  .product-comments {
    margin-top: 10px;
    .comments_note > div {
      margin-top: 0;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  width: 100%;
  ul {
    margin: 5px 0 15px;
  }
  li {
    @include box-shadow-light;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: 15px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      &:hover {
        color: transparent;
      }
    }
  }
}

.product-comments {
  label {
    margin-bottom: 0;
  }
  label,
  .comments_note {
    display: inline-block;
    vertical-align: middle;
    margin-top: 12px;
    padding-bottom: 12px;
  }
  .anchor {
    cursor: pointer;
    font: 400 16px $font-custom3;
    color: #878787;
  }
}

#new_comment_form,
.product-comments .comments_note {
  div.star {
    width: 18px;
    height: 19px;
    margin-right: 4px;
    background-image: url("../img/star-off-big.png") !important;
    &.star_on,
    &.star_hover {
      background-image: url("../img/star-on-big.png") !important;
    }
  }
}

#productCommentsBlock {
  #new_comment_form {
    margin-top: 0;
    width: 100%;
    padding: 0;
    label {
      text-align: left;
      padding: 10px 25px 0px 0;
      margin: 0;
      color: #5f6062;
      font: 700 16px/18px $font-custom;
    }
    .body-content {
      text-align: left;
      font: 300 14px $font-family;
    }
    input,
    textarea {
      border-color: #acacac;
      font: 300 16px/18px $font-custom;
      color: #828181;
      padding-left: 25px;
    }
    .form-group {
      margin-bottom: 0;
      .inputs {
        border: 1px solid #acacac;
        background-color: #fff;
        input {
          border-width: 0 0 1px 0;
        }
        textarea {
          border: none;
          max-width: 100%;
          height: 80px;
          margin-bottom: 0;
          padding-bottom: 0;
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
      }
    }
    #criterions_list {
      border-bottom: none;
      display: inline-block;
      // margin: 0 30px;
      padding: 0;
      @include media-breakpoint-down(xs) {
        display: block;
        text-align: center;
        label {
          min-width: 0;
        }
      }
      label,
      .star_content {
        display: inline-block;
        vertical-align: middle;
      }
      label {
        margin: 0 10px;
        padding: 0;
      }
      li {
        margin-bottom: 0;
      }
    }
  }
  .new_comment_form_content {
    padding: 0;
    #submitNewMessage {
      background: $custom-color6;
      font: 300 16px/18px $font-custom;
      padding: 10px 32px;
      border-radius: 50px;
      @include media-breakpoint-down(xs) {
        margin: 25px auto 0;
        display: inherit;
      }
      &:hover{
        background: $custom-color5;
      }
    }
  }
  #new_comment_form_footer {
    text-align: right;
    padding: 10px 0;
    .star_content {
      padding-top: 0px !important;
    }
  }
  .comments-container {
    padding: 25px 0;
    .blockquote {
      border-left: none;
      padding: 0.5rem 0;
      margin-bottom: 25px;
      .title_block {
        font: 18px/20px $font-custom;
        margin-top: 10px;
      }
      p {
        font: 300 16px/18px $font-custom;
        color: #666;
      }
      .blockquote-footer {
        font: 300 16px/18px $font-custom;
        color: #383737;
        margin-top: 5px;
      }
    }
  }
}

.reasurance-container {
  margin-top: 44px;

  .reassurance-items-product {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .reassurance-item-product {
      -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      width: 49%;
      margin: 0 8px 8px 0;
      display: flex;
      align-items: center;

      &:nth-child(2) {
        margin-right: 0;
      }

      &:nth-child(4) {
        margin-right: 0;
      }

      .img-container,
      .text-container {
        display: inline-block;
        vertical-align: middle;
      }

      .img-container {
        width: 20%;
        padding: 12px;
        border-right: 2px solid $gray-light;
        /*
        margin-right: 7px;
        width: 25px;

        @media (max-width: 1145px) {
          width: 20px;
        }
        */
      }

      .text-container {
        width: 80%;
        text-align: left;
        text-transform: uppercase;
        margin: auto;
        padding: 12px;
        padding-right: 0;

        span {
          font: 300 12px $font-custom2;
          width: 100%;
          margin: auto;
          display: block;

          &:first-child {
            font-weight: bold;
            color: black;
            padding-bottom: 4px;
          }

          @media (max-width: 1145px) {
            font-size: 12px;
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.blockreassurance_product {
  margin-right: -4px;
  margin-left: -4px;
  margin-top: 44px !important;
  .reassurance_item {
    padding-right: 4px;
    padding-left: 4px;
    .block-reassurance-item {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: none;
      position: relative;
      display: block;
      margin-bottom: 8px;
      background-color: #fff;
    }
    .item-product {
      height: 68px;
      width: 68px;
      margin-right: 0;
      padding-top: 0;
      border-right: 2px solid $gray-light;
      img {
        height: unset;
        width: unset;
        padding: 14px 13px;
      }
    }
    .block-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 11px 0 11px 18px;
      .block-title {
        font: 700 14px $font-custom2;
        color: black;
        text-transform: uppercase;
        padding-bottom: 4px;
      }
      .block-description {
        font: 300 14px $font-custom2;
        // text-transform: uppercase;
        color: black;
        display: block;
      }
    }
  }
}

.combination-table {
  .table-price {
    display: flex;
    font-weight: 500 !important;
    @include media-breakpoint-down(sm) {
      display: flex !important;
      justify-content: center;
    }
    .current-price {
      font: 500 16px $font-family;
      color: #ed1c24;
      sup {
        top: -0.5em;
      }
    }
    .product-discount {
      padding-top: 11px;
      padding-right: 6px;
      color: #818181;
      text-decoration: line-through;
    }
  }
  .table-quantity {
    .input-group.bootstrap-touchspin {
      border: 2px solid #e8e8e8;
      height: 33px;
      .input-group-btn {
        .btn {
          margin-top: 1px;
          font-size: 16px;
          color: #000;
        }
      }
      input.quantity_wanted {
        font-size: 18px;
        color: #000;
        width: 2em;
      }
    }
  }
  .table-stock a{
    color: #000;
    font: 400 13px $font-family;
    margin-left: 3px;
  }
  .table-disponibility a {
    color: #000;
    font: 400 13px $font-family;
    // margin-left: 3px;
  }
  .combination_add_to_cart {
    &:hover {
      background-color: #d21e25;
    }
  }

  .add-alert,
  .add-error,
  .add-error-qty {
    max-width: 138px;
    margin-top: 0 !important;
    margin-right: 0 !important;
    float: unset !important;
  }
  .code-row {
    width: unset;
  }
  .price-row {
    width: unset;
  }
  .qty-row {
    width: unset;
  }
  .add-btn-row {
    width: unset;
  }
}
#combinations-container {
  background-color: #fff;
  padding: 10px 0 20px 0;
  
  .combinations-filters{
    margin-bottom: 5px;
  }
  .attribute-container select {
    
    font: 500 15px $font-family;
    color: #737373;
    border-bottom: 1px solid #c6c6c6;
    padding: 4px 0;

    //for iphone
    border-radius: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('../img/arrow-point-to-down.png') right / 10px no-repeat #fff;
    background-position: calc(100% - 8px) center !important;

    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
    // background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    // background-position: calc(100% - 20px) calc(1em + -3px), calc(100% - 15px) calc(1em + -3px), calc(100% - .5em) .5em;
    // background-size: 5px 5px, 5px 5px, 12.5em 19.5em;
    // background-repeat: no-repeat;
    // &::after{
    //   font-family: FontAwesome;
  	// content: '\f107';
  	// font-size: 28px;
  	// position: absolute;
  	// top: 12px;
  	// right: 20px;
  	// color: #434B67;
  	// pointer-events: none;
    // }
  }
  // arrow-point-to-down
  .attribute-container {
    .modal-body{
      .tabs{
        padding-top: 0;
      }
    }
    .attribute-item {
      .attribute-info {
        color: #000;
        font: 500 14px $font-family;
        .opacity {
          .material-icons {
            font-size: 17px;
          }
        }
        .resistance {
          .material-icons {
            font-size: 14px;
          }
        }
      }
    }
    .row-inject {
      margin-right: 7px;
      margin-left: 7px;
    }
    .inject {
      margin: 10px auto 20px auto;
      border-top: 1px solid #737373;
      border-bottom: 1px solid #737373;
      padding: 40px 7px 40px 7px;
      @include media-breakpoint-down(sm){
        padding: 40px 20px 40px 20px
      }
      .attribute-properties {
        @include media-breakpoint-down(sm){
          padding-left: 0;
        }
        .attribute-title {
          font: 600 18px $font-family;
          margin-bottom: 5px;
        }
        .close {
          opacity: unset;
          text-shadow: unset;
          span {
            font-size: 28px;
          }
        }
        .attribute-specs {
          margin-bottom: 40px;
          div {
            font-size: 16px;
            font-family: $font-family;
            text-transform: lowercase;
            strong {
              text-transform: initial;
            }
          }
          .resistance {
            i {
              font-size: 14px;
            }
          }
          .opacity {
            i {
              font-size: 20px;
            }
            .icon-square-half:after {
              top: -8px;
            }
          }
        }
        .attribute-description {
          font: 400 14px $font-family;
          color: #000;
        }
        .combination-table tr:nth-child(2n) {
          background-color: unset !important;
        }
        .combination-table tr {
          &:hover {
            background-color: #fff !important;
          }
          .table-quantity {
            .input-group {
              .input-group-btn {
                .btn {
                  &:focus {
                    outline: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
    .pointer-arrow {
      background-image: url("../img/top-arrow.png");
      height: 36px;
    }
  }
}

.products-selection {
  padding-bottom: 8px;

  .title {
    color: $gray;
  }

  @include media-breakpoint-down(sm) {
    border: 0;
    padding: 0;
  }
}

#blockcart-modal .cart-content {
  .btn {
    margin-bottom: $small-space;
  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body {
    margin-left: 0;
    img.product-cover-modal {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
  #product-modal .modal-content .modal-body .mask {
    max-height: 22.9999rem;
  }
  #product-modal .modal-content .modal-body .product-images {
    width: 95px;
  }
  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }
    .modal-body {
      padding: 1.875rem;
      @media (max-width: 620px) {
        > .row-eq-height {
          display: block;
        }
      }
      @media (max-width: 550px) {
        .row-eq-height {
          display: block;
          text-align: center;
        }
        .cart-content {
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }
    .modal-body {
      padding: 1rem;
    }
  }
}

@media (max-width: 420px) {
  .store-informations .phone-store,
  .store-informations .email-store,
  .store-informations .open-hours {
    float: none;
    width: 100%;
    text-align: left;
    .icon {
      display: inline-block;
      width: 23px;
    }
    a {
      display: inline-block;
      margin-right: 7px;
    }
  }
  .store-informations .phone-store {
    border-bottom: 1px solid #595a5c;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  // .product-quantity .qty {
  //   width: 72px;
  // }
  // .product-quantity #quantity_wanted {
  //   padding: 0.175rem 0.5rem;
  // }
}

// @media (max-width: 340px) {
//   .product-quantity .add .add-to-cart {
//     padding: 9px 15px 8px;
//   }
// }


#image-product-carousel{
  .carousel-inner{
    .carousel-item{
      background-color: #fff;
    }
    img{
      max-height: unset;
    }
  }
  a{
    .arrow-modal-right, .arrow-modal-left{
      position: absolute;
      color: black;
      top: 50%;
    }
    .arrow-modal-right{
      right: 20px;
    }
    .arrow-modal-left{
      left: 20px;
    }
  }
}