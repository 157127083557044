#category,
#search,
#prices-drop,
#module-landingpagecampaigns-campaign {
    #products .product-thumbnail,
    .featured-products .product-thumbnail,
    .product-miniature .product-thumbnail {
        padding-top: 32px;
        img {
            // height: 100%;
            height: auto;
            width: auto;
            border-radius: 0 !important;
            padding: 7px 27px 0 27px;
            @media (max-width: 1356px) {
                padding: 7px 16px 0 16px;
            }
        }
    }
    .product-description {
        @media (max-width: 1356px) {
            margin: 7px 10px -1px 15px !important;
        }
        @include media-breakpoint-down(sm) {
            margin: 7px 10px -1px 28px !important;
        }
        @media (max-width: 471px) {
            margin: 7px 10px -1px 15px !important;
        }
        @media (max-width: 386px) {
            margin: 7px 10px -1px 10px !important;
        }
        @media (max-width: 376px) {
            margin: 7px 5px -1px 5px !important;
        }
        .add-to-cart-listing {
            margin-bottom: 1rem;
            .toggle-show {
                display: flex;
                flex-direction: row;
            }
            .add-to-cart-listing-btn,
            .choose_variant {
                padding: 9px 0 6px 0;
                max-width: 121px;
                width: 121px;
                min-width: 100px;
            }
        }
        .add-remove-favorites {
            ul {
                margin-bottom: 0;
                li {
                    padding: 4px 11px !important;
                }
            }
        }
    }
}

#search,
#prices-drop {
    .block-categories .category-top-menu h6 {
        border-bottom: unset;
        padding: 12px 0 0px 0;
    }
}

#products,
.featured-products,
.product-miniature {
    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // padding-top: 25px;
    }
    .product-thumbnail {
        display: block;
        img {
            display: block;
            margin: 0 auto;
            @include border-radius(6px 6px 0 0);
        }
    }
    .product-title {
        height: 40px;
        overflow: hidden;
        @include media-breakpoint-down(sm){
            height: 49px;
        }
    }
    .product-title a {
        color: black;
        font: 600 13px $font-custom2;
        text-transform: none;
        align-items: flex-start;
        text-align: left !important;
        outline: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            display: block;
        }
        &:hover {
            color: $custom-color;
        }
    }
    .thumbnail-container {
        position: relative;
        border-right: 1px solid $gray-border;
        overflow: hidden;
        height: auto;
        &:hover,
        &:focus {
            .highlighted-informations {
                bottom: 5.5rem;
                &::after {
                    opacity: 1;
                }
                &.no-variants {
                    bottom: 4.2rem;
                }
            }
        }
        .product-description {
            margin: 7px 10px -1px 28px;
            padding-bottom: 1px;
            text-align: left;
            padding-bottom: 11px;
            @include media-breakpoint-down(sm) {
                margin: 22px 10px;
            }
            .add-remove-favorites {
                padding: 0;
                margin-left: 5px;
                display: inline-block;
                @media (max-width: 490px) {
                    margin-left: 5px;
                }
                .add-fav {
                    &:before {
                        content: '';
                        background-image: url('../img/heart-inactive.png');
                        background-repeat: no-repeat;
                        display: inline-block;
                        height: 16px;
                        width: 18px;
                        vertical-align: middle;
                    }
                }
                .remove-fav {
                    &:before {
                        content: '';
                        background-image: url('../img/heart-active.png');
                        background-repeat: no-repeat;
                        display: inline-block;
                        height: 16px;
                        width: 18px;
                        vertical-align: middle;
                    }
                }
                ul {
                    li {
                        padding: 4px 12px;
                        @include border-radius(50px);
                        background-color: #fff;
                        border: 1px solid #000;
                        @media (max-width: 425px) {
                            padding: 4px 10px;
                        }
                    }
                    .favoriteproducts_block_extra_remove {
                        background-color: $custom-color6;
                        border: 1px solid $custom-color6;
                    }
                    .favoriteproducts_block_extra_add {
                        background-color: #fff;
                        border: 1px solid #000;
                    }
                }
            }
        }
    }
    .products-section-title {
        text-align: center;
        margin-bottom: 1.5rem;
    }
    .product-title {
        text-align: left !important;
        text-transform: capitalize;
    }
    .add-to-cart-listing-btn,
    .choose_variant {
        padding: 9px 21px 6px 21px;
        @include border-radius(50px);
        background: white;
        font: 500 11px $font-custom2;
        color: black;
        border: 1px solid black;
        transition: all 0.2s;
        @media (max-width: 425px) {
            // padding: 9px 11px 6px 11px;
            padding: 8px 6px 7px 9px;
        }
        span {
            color: #000;
            font: 500 11px $font-family;
            @media (max-width: 425px) {
                font: 500 10px $font-family;
            }
        }
        .shopping-cart {
            width: 21px;
            height: 19px;
            display: block;
            margin-right: 5px;
            &:before {
                content: "";
                background: url("../img/shopping-cart-white.png");
                width: 21px;
                height: 19px;
                display: inline-block;
            }
        }
        i.material-icons {
            font-size: 20px;
            height: 19px;
            line-height: 19px;
        }
        span,
        i {
            display: inline-block;
            vertical-align: middle;
        }
        &:hover,
        &:focus {
            border: 1px solid $custom-color6;
            background: $custom-color6;
            span {
                color: #fff !important;
            }
        }
    }
    .miniature_manufacturer_name {
        height: 27px;
    }
    .product_manufacturer_name {
        background-color: #F0F0F0;
        padding: 2px 10px;
        font: lighter 12px $font-custom2;
        color: $custom-color3;
        display: inline-block;
        @include border-radius(6px);
        margin-top: 15px;
    }
    .product-price-and-shipping {
        color: #222222;
        font: 600 17px $font-custom2;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
        text-align: left !important;
        margin: 0 auto 10px;
        .regular-price,
        .price {
            display: inline-block;
            vertical-align: middle;
            sup {
                font-size: 10px;
            }
        }
        .regular-price {
            color: $custom-color9;
            font-size: 15px;
            font-weight: 600;
            vertical-align: bottom;
        }
        .has-discount {
            color: $custom-color6;
            font-size: 15px;
            margin-left: 14px;
        }
        .text-price-attributes {
            margin-right: 5px;
        }
        .text-price-attributes-has-discount {
            color: $custom-color6;
            margin-left: 10px;
            margin-right: -5px;
            @media (max-width: 512px) {
                margin-left: 3px;
            }
            @media (max-width: 580px) {
                font-size: 14px;
            }
            @media (max-width: 526px) {
                font-size: 13px;
            }
            @media (max-width: 490px) {
                font-size: 17px;
                margin-left: 10px
            }
        }
    }
    .variant-links {
        position: relative;
        text-align: center;
        width: 100%;
        top: -0.25em;
        padding-top: 0.1875rem;
        min-height: 2.5rem;
        background: white;
    }
    img {
        max-width: 100%;
    }
    .product-miniature {
        .product-flags .new,
        .online-only,
        .on-sale,
        .discount-percentage {
            // display: block;
            // position: absolute;
            // left: -0.4375rem;
            // top: 0.4375rem;
            // padding: 0.3125rem 0.4375rem;
            // color: white;
            // background: $custom-color2;
            // text-transform: uppercase;
            // min-width: 3.125rem;
            // min-height: 1.875rem;
            // font-size: $base-font-size;
            // font-weight: 600;
            // @include box-shadow-light;
            &.discount-percentage {
                z-index: 2;
                background: $brand-secondary;
            }
            &.on-sale {
                background: $brand-secondary;
                width: 100%;
                text-align: center;
                left: 0;
                top: 0;
            }
            &.online-only {
                font-size: $font-size-xs;
                margin-top: 13rem;
                margin-left: 8.688rem;
                &::before {
                    content: "\E30A";
                    font-family: "Material Icons";
                    vertical-align: middle;
                    margin: 0.3125rem;
                }
            }
        }
    }
    .comments_note {
        text-align: left;
        color: $gray;
        margin-top: 6px;
        span {
            display: none;
        }
    }
    .regular-price {
        color: $gray;
        text-decoration: line-through;
        font-size: $font-size-sm;
    }
    .count {
        color: $gray;
        font-weight: 700;
        position: relative;
        bottom: 0.5rem;
    }
    .all-product-link {
        clear: both;
        color: $gray;
        font-weight: 700;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    &.product_show_list {
        .product_thumbnail_list {
            display: inline-block;
            width: 30%;
            vertical-align: middle;
        }
        .price-reviews,
        .add-product-in-shopping-cart {
            display: inline-block;
            width: 34%;
        }
        .product_thumbnail_list {
            position: relative;
        }
        .product-price-and-shipping {
            height: auto !important;
            margin: 0 !important;
        }
        .add-to-cart {
            margin-bottom: 0;
        }
    }
}

#js-product-list .products {
    article {
        @include media-breakpoint-down(md) {
            .product-action .add-remove-favorites ul li a span {
                display: none;
            }
        }
    }
}

#favoriteproducts_block_account {
    .product-miniature {
        @include media-breakpoint-up(lg) {
            width: 20%;
        }
    }
}

.search-contact-us {
    .h2 {
        font-weight: bold;
        text-transform: none;
    }
    .btn {
        @include border-radius(6px);
        background: $brand-primary;
        padding: 8px 60px;
        margin-bottom: 10px;
        border: none;
    }
    .mail {
        float: left;
        padding: 10px 30px;
        &:after {
            content: " ";
            display: block;
            background: url("../img/mail.png");
            width: 54px;
            height: 47px;
        }
    }
    p {
        margin-bottom: 0;
    }
    .description-banner {
        max-width: 76%;
    }
}

.popular-category {
    .row {
        margin-right: -16px;
        margin-left: -16px;
        .category-item {
            padding-left: 16px;
            padding-right: 16px;
        }
        @media (max-width: 430px) {
            margin-right: -9px;
            margin-left: -9px;
            .category-item {
                padding-left: 9px;
                padding-right: 9px;
            }
        }
        @media (max-width: 390px) {
            margin-right: 0px;
            margin-left: 0px;
            .category-item {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
    .popular-category-slick {
        .promo {
            background-color: #fff;
            @include box-shadow;
            text-align: center;
            border-radius: 5px;
            margin: 10px 3px 8px 2px;
            img {
                width: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            h3 {
                font: 600 20px $font-custom2;
                padding: 10px 10px 30px 10px;
                height: 70px;
                @media (max-width: 410px) {
                    font: 600 14px $font-custom2;
                    height: 56px;
                }
            }
        }
        button {
            &.slick-prev,
            &.slick-next {
                position: absolute;
                top: 47%;
                width: 43px;
                height: 33px;
                z-index: 5;
                cursor: pointer;
                border: 1px solid $gray;
                right: -13px;
                @include border-radius(8px);
                &:before {
                    color: #000;
                    content: " ";
                    text-indent: 0;
                    background: url("../img/next-arrow.png") no-repeat;
                    height: 14px;
                    width: 8px;
                    display: block;
                    margin: auto;
                }
                &.swiper-button-disabled {
                    display: none;
                }
            }
            &.slick-prev {
                right: auto;
                left: -9px;
                @media (max-width: 1300px) {
                    left: -20px;
                }
                &:before {
                    float: none;
                    background: url("../img/prev-arrow.png") no-repeat;
                }
            }
        }
    }
}

#product {
    .viewedproduct_slider {
        .featured-products {
            .recently-viewed-title {
                margin-top: 10px;
                margin-bottom: 4px;
            }
        }
    }
    .category-products {
        margin-bottom: 39px;
    }
}

.viewedproduct_slider {
    .featured-products {
        .recently-viewed-title {
            text-align: center;
            font: 600 19px $font-family;
            color: #000;
            text-transform: none;
            width: 100%;
            text-align: center;
            margin-top: 49px;
            margin-bottom: 4px;
        }
        .products-recently-viewed {
            margin-top: 30px;
            margin-bottom: 56px;
            .slick-track {
                margin: 0;
                box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
                border: 1px solid #e2e2e2;
                ;
            }
            article.product-miniature {
                background: #fff;
                .thumbnail-container {
                    .img-thumbnail-container {
                        a {
                            img {
                                padding: 4px 22px 4px 22px;
                            }
                        }
                    }
                }
            }
        }
        .slick-slider {
            border: unset;
            background: unset;
        }
    }
    .slick-prev,
    .slick-next {
        background-image: none !important;
        position: absolute;
        z-index: 5;
        cursor: pointer;
        right: -29px;
        @include border-radius(50%);
        padding: 30px 30px;
        background: #ededed;
        opacity: 0.7;
        &:before {
            color: #000;
            content: " ";
            text-indent: 0;
            background: url("../img/next-arrow.png") no-repeat;
            height: 29px;
            width: 16px;
            display: block;
            position: absolute;
            top: 27%;
            left: 25px;
        }
        &.swiper-button-disabled {
            display: none;
        }
        &:focus {
            outline: unset;
        }
    }
    .slick-prev {
        right: auto;
        left: -27px;
        &:before {
            float: none;
            background: url("../img/prev-arrow.png") no-repeat;
            position: absolute;
            top: 29%;
            left: 20px;
        }
    }
    .slick-prev:hover,
    .slick-next:hover {
        background: #ededed;
    }
}

#cart {
    .product-discount {
        margin-left: 0;
        .discount {
            display: none;
        }
    }
    .blockreassurance_product {
        text-align: center;
        color: white;
        flex-grow: 4;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0 !important;
        .reassurance_item {
            .block-title {
                display: none;
            }
        }
    }
}