.block_newsletter {
    font-size: $font-size-sm;
    background: url("../img/bg-newsletter.png") no-repeat center center;
    background-size: cover;
    margin-top: 10px;
    .form-container {
        padding-top: 35px;
        padding-bottom: 18px;
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            width: 100%;
        }
        @media (max-width: 575px) {
            padding-top: 52px;
            padding-bottom: 0px;
        }
        .input-form {
            text-align: center;
        }
    }
    .envelope-icon {
        margin-bottom: 17px;
    }
    .newsletter-title {
        font: 700 28px $font-custom2;
        color: #000000;
        font-weight: bold;
        margin-bottom: 17px;
    }
    .newsletter-description {
        font: 400 12px $font-custom2;
        color: #000000;
        margin-bottom: 30px;
    }
    form {
        margin-bottom: 20px;
        position: relative;
        display: inline-block;
        width: 913px;
        @media (max-width: 1135px) {
            width: 600px;
        }
        @media (max-width: 650px) {
            width: 450px;
        }
        @media (max-width: 470px) {
            width: 380px;
        }
        @media (max-width: 400px) {
            width: 330px;
        }
        // @include media-breakpoint-down(md) {
        //   width: 400px;
        // }
        // @include media-breakpoint-down(sm) {
        //   width: 100%;
        // }
        .input-wrapper {
            // position: relative;
            input[type="email"] {
                width: 100%;
                font: 400 14px $font-custom2;
                float: left;
                padding: 17px 29px 13px 29px;
                border: unset;
                background-color: white;
                @include border-radius(50px);
                // @include media-breakpoint-down(sm) {
                //   padding: 5px;
                // }
                // @include media-breakpoint-down(xs) {
                //   width: 100%;
                //   text-align: center;
                // }
            }
            input[type="submit"] {
                font: 300 14px $font-custom2;
                color: #fff;
                background-color: $custom-color6;
                border: none;
                position: absolute;
                margin: 0 auto;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                width: 173px;
                padding: 15px;
                right: 0;
                &:hover {
                    background-color: $custom-color5;
                }
                // float: right;
                // border: none;
                // padding: 14px 0;
                // position: absolute;
                // margin: 0 auto;
                @media (max-width: 470px) {
                    width: 130px;
                }
                @media (max-width: 400px) {
                    width: 85px;
                }
                @media (max-width: 359px) {
                    margin-top: 10px;
                    width: 130px;
                    position: unset;
                    border-radius: 50px;
                }
            }
        }
    }
    .newsletter_tooltip {
        display: block;
        position: absolute;
        top: 51px;
        width: 100%;
        .alert {
            padding: 0;
            text-align: center;
            @include border-radius(20px);
        }
        .wrapped-error {
            background-color: $brand-danger;
        }
        .wrapped-success {
            background-color: $brand-success;
            color: #fff;
        }
    }
}

.block-contact {
    cursor: pointer;
    #footer_sub_menu_contact {
        margin-top: 15px;
        p {
            padding-right: 0;
        }
    }
    a {
        color: #000;
        font: 400 12px $font-family;
    }
    p {
        display: block;
        margin-bottom: 10px;
        font: 400 12px $font-family !important;
        color: #000;
        i {
            color: black;
        }
    }
    .imag {
        display: inline-block;
        margin-right: 8px;
        // @media (max-width: 1264px){
        //   margin-right: 3px;
        // }
        // @media (max-width: 804px){
        //   margin-right: 1px;
        // }
        // @media (max-width: 796px){
        //   margin-right: 0px;
        // }
        i.call-icon {
            &::before {
                background-image: url(../img/call-icon.png);
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: middle;
                width: 19px;
                height: 28px;
                content: " ";
            }
        }
        i.envelope-icon {
            &::before {
                background-image: url(../img/envelope-icon.png);
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: middle;
                width: 19px;
                height: 15px;
                content: " ";
            }
        }
    }
    .indent {
        padding-left: 30px;
        margin-top: 10px;
    }
}

.linklist {
    .blockcms-title a {
        color: $gray-darker;
    }
}

.account-list a {
    color: $gray;
    &:hover {
        color: $custom-color2;
    }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
    font-weight: 700;
    font-size: $base-font-size;
}

.block-social {
    padding-left: 0;
    ul {
        display: flex;
        @media (max-width: 930px) {
            flex-direction: column;
            align-items: center;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: row;
            align-items: end;
            margin-top: 20px;
        }
    }
}

.block-social li {
    height: 2.2rem;
    width: 2.2rem;
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 10px 0 0;
    cursor: pointer;
    @media (max-width: 930px) {
        margin: 0 10px 5px 0;
    }
    a {
        display: block;
        height: 100%;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;
    }
}

.youtube {
    background-image: url(../img/youtube-footer.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 27px;
    height: 20px;
    content: " ";
    &:hover {
        background-image: url(../img/youtube-footer-hover.png);
    }
}

.facebook {
    background-image: url(../img/facebook-footer.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 20px;
    content: " ";
    margin: 0 14px;
    &:hover {
        background-image: url(../img/facebook-footer-hover.png);
    }
}

.instagram {
    background-image: url(../img/instagram-footer.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 21px;
    content: " ";
    &:hover {
        background-image: url(../img/instagram-footer-hover.png);
    }
}

.pinterest {
    background-image: url(../img/pinterest-footer.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 21px;
    content: " ";
    &:hover {
        background-image: url(../img/pinterest-footer-hover.png);
    }
}

.twitter {
    background-image: url(../img/twitter.png);
    background-size: 26px 26px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    content: " ";
    margin-right: 14px;
    &:hover {
        background-color: #32ccfe;
        @include border-radius(50%);
    }
    &.icon-gray {
        background-image: url(../img/twittergray.png);
    }
}

.googleplus {
    background-image: url(../img/g+.png);
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    content: " ";
    &:hover {
        background-color: #dd4e40;
        @include border-radius(50%);
    }
    &.icon-gray {
        background-image: url(../img/g+gray.png);
    }
}

#block_myaccount_infos {
    .myaccount-title a {
        color: $gray-darker;
    }
}

.background-grey {
    background: #f9f9f9;
    margin-top: 30px;
    padding-top: 20px;
    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }
}

.footer-container {
    overflow: hidden;
    /*
  background: #888888;
  */
    h3 {
        font: 500 15px $font-family;
        text-transform: uppercase;
        margin-bottom: 45px;
        @media (max-width: 813px) {
            font: 500 13px $font-family;
        }
    }
    h3.social {
        margin-bottom: 15px;
        @media (max-width: 813px) {
            font: 500 13px $font-family;
        }
    }
    li {
        /*
    &:before {
      content: "";
      border-color: transparent #fff;
      border-style: solid;
      border-width: 0.25em 0 0.25em 0.35em;
      display: block;
      height: 0;
      width: 0;
      left: -0.7em;
      top: 0.94em;
      position: relative;
    }
    */
        a {
            color: #000;
            cursor: pointer;
            font: 500 10px $font-custom2;
            line-height: 22px;
            &:hover {
                color: $custom-color6;
            }
        }
    }
    p {
        font: 500 11px $font-custom2;
        color: #000;
        line-height: 20px;
        // padding-right: 78px;
        // @media (max-width: 1100){
        //   padding-right: 40px;
        // }
        // @include media-breakpoint-down(md){
        //   padding-right: 20px;
        // }
    }
    p.h3 {
        font: 500 15px $font-family;
        text-transform: uppercase;
        margin-bottom: 45px;
        @media (max-width: 813px) {
            font: 500 13px $font-family;
        }
    }
    .links {
        .collapse {
            display: inherit;
        }
    }
}

.row-after-footer {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1020px) {
        flex-wrap: wrap;
    }
    @media (max-width: 650px) {
        display: block;
    }
}

.after-footer {
    border-top: 1px solid #d9d9d9;
    padding-top: 8px;
    padding-bottom: 8px;
    @include media-breakpoint-down(sm) {
        text-align: center;
        border-top: none;
        margin-top: 0;
    }
    .pay-logos {
        padding-top: 12px;
        @include media-breakpoint-down(sm) {
            padding-bottom: 10px;
        }
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
        img {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            @include media-breakpoint-down(md) {
                margin: 0 2px;
            }
            @media (max-width: 782px) {
                margin: 0 1px;
            }
        }
    }
    .anpc-logo {
        display: inline-flex;
        img {
            height: 50px;
        }
        @include media-breakpoint-down(sm) {
            justify-content: center;
            margin-bottom: 15px;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
        @media (max-width: 532px) {
            flex-direction: column;
            align-items: center;
            div {
                padding-left: 0 !important;
                padding-bottom: 5px;
            }
        }
    }
    .copyright {
        font: 400 12px $font-custom2;
        color: #313131;
        padding-top: 12px;
        @include media-breakpoint-down(md) {
            line-height: 15px;
            padding-top: 10px;
        }
        @media (max-width: 910px) {
            padding-bottom: 15px;
        }
    }
    .made-by {
        display: inline-block;
        width: auto;
        text-align: center;
        @include media-breakpoint-down(sm) {
            padding-top: 10px;
        }
        a {
            display: inline-block;
            img {
                height: 25px;
                width: auto;
            }
        }
        @include media-breakpoint-down(md) {
            // float: right;
        }
        @include media-breakpoint-down(xs) {
            float: unset;
            text-align: center;
        }
        .senior-text {
            margin-top: 5px;
            font: 400 7px $font-custom;
            color: #292929;
            margin-bottom: 0;
        }
        p {
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .block_newsletter {
        padding-bottom: 0.625rem;
        border-bottom: 1px solid $gray-lighter;
        background-position-y: center;
        margin: 0 !important;
    }
    .newsletter-title,
    .newsletter-description {
        text-align: center !important;
    }
    .footer-container {
        box-shadow: none;
        margin-top: 20px;
        padding-top: 0;
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .links {
            border-top: 1px solid #666;
            &.wrapper {
                border: none;
            }
            .h3 {
                line-height: 1.5;
                font-size: 1rem;
            }
            .title {
                padding: 0.625rem;
                border-bottom: 1px solid #666;
                cursor: pointer;
                .collapse-icons .remove {
                    display: none;
                }
            }
            .title[aria-expanded="true"] .collapse-icons {
                .add {
                    display: none;
                }
                .remove {
                    display: block;
                }
            }
            .navbar-toggler {
                display: inline-block;
                padding: 0;
            }
            .collapse {
                display: none;
                &.in {
                    display: block;
                }
            }
            ul {
                /*
        background-color: #e6e6e6;
        */
                margin-bottom: 0;
                >li {
                    padding: 0.625rem;
                    border-bottom: 1px solid white;
                    font-weight: bold;
                    &:before {
                        display: none;
                    }
                    a {
                        color: $gray-darker;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .block-social {
        text-align: center;
    }
    .block-contact {
        padding-left: 1rem;
    }
}

#myBtn {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Fixed/sticky position */
    bottom: 20px;
    /* Place the button at the bottom of the page */
    right: 30px;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    outline: none;
    /* Remove outline */
    /*
  background: $gradient; // Set a background color
  */
    background: $custom-color6;
    color: #fff;
    /* Text color */
    cursor: pointer;
    /* Add a mouse pointer on hover */
    padding: 3px;
    /* Some padding */
    @include border-radius(50%);
    width: 50px;
    height: 50px;
    text-align: center;
    &:hover {
        background-color: $custom-color2;
        /* Add a dark-grey background on hover */
    }
    i {
        font-size: 35px;
        line-height: 40px;
    }
}